import { createSlice } from "@reduxjs/toolkit";
import { DatosSegundoPaso } from "./../models/segundoPaso";

export type SegundoPasoState = DatosSegundoPaso;

const initialState: SegundoPasoState = {
  documentBackImage: "",
  documentFrontImage: "",
};

export const SegundoPasoReducer = createSlice({
  name: "SegundoPaso",
  initialState,
  reducers: {
    setSecondStep: (state, action) => {
      return action.payload;
    }
  }
});

export const { setSecondStep } = SegundoPasoReducer.actions;

/* export const getSecondStep = (state: RootState) => state.SegundoPasoState; */

export default SegundoPasoReducer.reducer;
