import { createSlice } from "@reduxjs/toolkit";
import { DatosTercerPaso } from "./../models/tercerPaso";

export const DNIC_FIELDS_INITIAL_STATE = {
  theName: false,
  theSecondName: false,
  theLastName: false,
  theSecondLastName: false,
  theBirthdayDay: false,
  theBirthdayMonth: false,
  theBirthdayYear: false,
  theSex: false,
};

export type DNIC_FIELDS = {
  theName: boolean;
  theSecondName: boolean;
  theLastName: boolean;
  theSecondLastName: boolean;
  theBirthdayDay: boolean;
  theBirthdayMonth: boolean;
  theBirthdayYear: boolean;
  theSex: boolean;
};

export type TercerPasoState = DatosTercerPaso & {
  isComingFromDNIC: DNIC_FIELDS;
};

const initialState: TercerPasoState = {
  name: "",
  secondName: "",
  lastName: "",
  secondLastName: "",
  birthdayDay: "",
  birthdayMonth: "",
  birthdayYear: "",
  birthdayCountryCode: "858",
  residencyCountryCode: "858",
  sex: "",
  phone: "",
  email: "",
  dniDueDate: {
    dueDay: "",
    dueMonth: "",
    dueYear: "",
  },
  // values that are currently coming from DNIC
  isComingFromDNIC: DNIC_FIELDS_INITIAL_STATE,
};

export const TercerPasoReducer = createSlice({
  name: "TercerPaso",
  initialState,
  reducers: {
    setThirdStep: (state, action) => {
      return action.payload;
    }
  }
});

export const { setThirdStep } = TercerPasoReducer.actions;

export default TercerPasoReducer.reducer;
