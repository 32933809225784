export const styles = {
  "span > span": {
    paddingLeft: "0 !important",
  },
  "span > span::before": {
    content: 'none',
  },
  "span > span::after": {
    content: 'none',
  },
  radioGroupWrapper: {
    "& .MuiFormControlLabel-root .Mui-checked": {
      color: "#CC0000",
    },
    "& .MuiSvgIcon-root": {
      height: "32px",
      width: "32px",
    },
    "& .MuiTypography-root": {
      fontFamily: "body1.fontFamily",
      fontWeight: "fontWeightBold",
      fontSize: "body1.fontSize",
      color: "#444444",
    },
  },
  radioButtonWrapper: {
    transform: "scale(1.25)",
  },
}