export const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",

    ".cardContainer": {
      width: "99px",
      height: "65px",
      position: "absolute",
      top: "-45px",
      right: "0",
    },

    ".cardImg": {
      width: "100%",
    },

    ".cardCJ": {
      backgroundColor: "#F2F8FA",
      borderRadius: "16px",
      padding: "12px",
    },

    ".titleCJ": {
      fontFamily: "h4.fontFamily",
      fontStyle: "normal",
      fontWeight: "fontWeightBold",
      fontSize: "h4.fontSize",
      color: "#000000",
      textTransform: "uppercase",
    },

    ".textCJ": {
      borderLeft: "4px solid #ec0000",
      paddingLeft: "10px",
      fontFamily: "Santander Micro Text",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      letterSpacing: "0",
      textAlign: "left",
      gap: 1,
    },

    ".subTextCJ": {
      color: "#000000",
      margin: "0px",
      padding: "15px 0",
      display: "flex",
      flexDirection: "column",
      fontFamily: "Santander Micro Text",
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "20px",
      letterSpacing: "0",
      textAlign: "left",
    },
  },
};