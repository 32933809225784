export const countries = [
  { number: "858", name: "Uruguay", dial_code: "+598", code: "UY", flag: "🇺🇾", document: [{ code: "CI", type: "Número C.I" }] },
  { number: "8", name: "Albania", dial_code: "+355", code: "AL", flag: "🇦🇱", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "12", name: "Algeria", dial_code: "+213", code: "DZ", flag: "🇩🇿", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "20", name: "Andorra", dial_code: "+376", code: "AD", flag: "🇦🇩", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "24", name: "Angola", dial_code: "+244", code: "AO", flag: "🇦🇴", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "31", name: "Azerbaijan", dial_code: "+994", code: "AZ", flag: "🇦🇿", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "32", name: "Argentina", dial_code: "+54", code: "AR", flag: "🇦🇷", document: [{ code: "DNI", type: "DNI" }, { code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "36", name: "Australia", dial_code: "+61", code: "AU", flag: "🇦🇺", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "40", name: "Austria", dial_code: "+43", code: "AT", flag: "🇦🇹", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "44", name: "Bahamas", dial_code: "+1242", code: "BS", flag: "🇧🇸", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "52", name: "Barbados", dial_code: "+1246", code: "BB", flag: "🇧🇧", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "56", name: "Belgium", dial_code: "+32", code: "BE", flag: "🇧🇪", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "60", name: "Bermuda", dial_code: "+1441", code: "BM", flag: "🇧🇲", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "64", name: "Bhutan", dial_code: "+975", code: "BT", flag: "🇧🇹", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "68", name: "Bolivia", dial_code: "+591", code: "BO", flag: "🇧🇴", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "72", name: "Botswana", dial_code: "+267", code: "BW", flag: "🇧🇼", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "76", name: "Brasil", dial_code: "+55", code: "BR", flag: "🇧🇷", document: [{ code: "CI", type: "Número C.I" }, { code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "82", name: "United Kingdom", dial_code: "+44", code: "GB", flag: "🇬🇧", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "84", name: "Belize", dial_code: "+501", code: "BZ", flag: "🇧🇿", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "86", name: "British Indian Ocean Territory", dial_code: "+246", code: "IO", flag: "🇮🇴", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "90", name: "Islas Salomón", dial_code: "+677", code: "IO", flag: "🇮🇴", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "92", name: "Virgin Islands, British", dial_code: "+1284", code: "VG", flag: "🇻🇬", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "96", name: "Brunei Darussalam", dial_code: "+673", code: "BN", flag: "🇧🇳", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "100", name: "Bulgaria", dial_code: "+359", code: "BG", flag: "🇧🇬", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "104", name: "Myanmar", dial_code: "+95", code: "MM", flag: "🇲🇲", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "108", name: "Burundi", dial_code: "+257", code: "BI", flag: "🇧🇮", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "112", name: "Bielorrusia", dial_code: "+375", code: "BY", flag: "🇧🇾", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "120", name: "Cameroon", dial_code: "+237", code: "CM", flag: "🇨🇲", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "124", name: "Canada", dial_code: "+1", code: "CA", flag: "🇨🇦", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "136", name: "Cayman Islands", dial_code: "+ 345", code: "KY", flag: "🇰🇾", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "140", name: "Central African Republic", dial_code: "+236", code: "CF", flag: "🇨🇫", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "144", name: "Sri Lanka", dial_code: "+94", code: "LK", flag: "🇱🇰", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "148", name: "Chad", dial_code: "+235", code: "TD", flag: "🇹🇩", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "152", name: "Chile", dial_code: "+56", code: "CL", flag: "🇨🇱", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "158", name: "Taiwan", dial_code: "+886", code: "TW", flag: "🇹🇼", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "170", name: "Colombia", dial_code: "+57", code: "CO", flag: "🇨🇴", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "174", name: "Comoros", dial_code: "+269", code: "KM", flag: "🇰🇲", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "188", name: "Costa Rica", dial_code: "+506", code: "CR", flag: "🇨🇷", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "191", name: "Croacia", dial_code: "+385", code: "HR", flag: "🇭🇷", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "192", name: "Cuba", dial_code: "+53", code: "CU", flag: "🇨🇺", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "196", name: "Chipre", dial_code: "+357", code: "CY", flag: "🇨🇾", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "200", name: "Republic. Checa", dial_code: "+420", code: "CZ", flag: "🇨🇿", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "204", name: "Benin", dial_code: "+229", code: "BJ", flag: "🇧🇯", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "208", name: "Denmark", dial_code: "+45", code: "DK", flag: "🇩🇰", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "212", name: "Dominica", dial_code: "+1767", code: "DM", flag: "🇩🇲", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "214", name: "Dominican Republic", dial_code: "+1849", code: "DO", flag: "🇨🇩", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "218", name: "Ecuador", dial_code: "+593", code: "EC", flag: "🇪🇨", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "219", name: "Slovakia", dial_code: "+421", code: "SK", flag: "🇸🇰", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "222", name: "El Salvador", dial_code: "+503", code: "SV", flag: "🇸🇻", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "226", name: "Equatorial Guinea", dial_code: "+240", code: "GQ", flag: "🇬🇶", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "230", name: "Ethiopia", dial_code: "+251", code: "ET", flag: "🇪🇹", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "233", name: "Estonia", dial_code: "+372", code: "EE", flag: "🇪🇪", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "233", name: "Faroe Islands", dial_code: "+298", code: "FO", flag: "🇫🇴", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "238", name: "Islas Malvinas", dial_code: "+500", code: "FK", flag: "🇫🇰", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "242", name: "Fiji", dial_code: "+679", code: "FJ", flag: "🇫🇯", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "246", name: "Finland", dial_code: "+358", code: "FI", flag: "🇫🇮", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "250", name: "France", dial_code: "+33", code: "FR", flag: "🇫🇷", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "254", name: "French Guiana", dial_code: "+594", code: "GF", flag: "🇬🇫", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "258", name: "French Polynesia", dial_code: "+689", code: "PF", flag: "🇵🇫", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "262", name: "Djibouti", dial_code: "+253", code: "DJ", flag: "🇩🇯", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "266", name: "Gabon", dial_code: "+241", code: "GA", flag: "🇬🇦", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "268", name: "Georgia", dial_code: "+995", code: "GE", flag: "🇬🇪", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "270", name: "Gambia", dial_code: "+220", code: "GM", flag: "🇬🇲", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "278", name: "Germany", dial_code: "+49", code: "DE", flag: "🇩🇪", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "292", name: "Gibraltar", dial_code: "+350", code: "GI", flag: "🇬🇮", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "296", name: "Kiribati", dial_code: "+686", code: "KI", flag: "🇰🇮", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "300", name: "Grecia", dial_code: "+30", code: "GR", flag: "🇬🇷", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "308", name: "Granada", dial_code: "+1473", code: "GD", flag: "🇬🇩", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "320", name: "Guatemala", dial_code: "+502", code: "GT", flag: "🇬🇹", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "324", name: "Guinea", dial_code: "+224", code: "GN", flag: "🇬🇳", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "328", name: "Guyana", dial_code: "+592", code: "GY", flag: "🇬🇾", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "332", name: "Haiti", dial_code: "+509", code: "HT", flag: "🇭🇹", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "336", name: "Ciudad del Vaticano", dial_code: "+379", code: "VA", flag: "🇻🇦", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "340", name: "Honduras", dial_code: "+504", code: "HN", flag: "🇭🇳", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "344", name: "Hong Kong", dial_code: "+852", code: "HK", flag: "🇭🇰", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "348", name: "Hungary", dial_code: "+36", code: "HU", flag: "🇭🇺", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "350", name: "India", dial_code: "+91", code: "IN", flag: "🇮🇳", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "352", name: "Islandia", dial_code: "+354", code: "IS", flag: "🇮🇸", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "360", name: "Indonesia", dial_code: "+62", code: "ID", flag: "🇮🇩", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "364", name: "Iran", dial_code: "+98", code: "IR", flag: "🇮🇷", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "368", name: "Iraq", dial_code: "+964", code: "IQ", flag: "🇮🇶", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "376", name: "Israel", dial_code: "+972", code: "IL", flag: "🇮🇱", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "380", name: "Italy", dial_code: "+39", code: "IT", flag: "🇮🇹", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "388", name: "Jamaica", dial_code: "+1876", code: "JM", flag: "🇯🇲", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "392", name: "Japon", dial_code: "+81", code: "JP", flag: "🇯🇵", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "398", name: "Kazakhstan", dial_code: "+7", code: "KZ", flag: "🇰🇿", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "400", name: "Jordania", dial_code: "+962", code: "JO", flag: "🇯🇴", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "410", name: "Korea, Republic of South Korea", dial_code: "+82", code: "KR", flag: "🇰🇷", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "417", name: "Kyrgyzstan", dial_code: "+996", code: "KG", flag: "🇰🇬", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "418", name: "Laos", dial_code: "+856", code: "LA", flag: "🇱🇦", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "422", name: "Libano", dial_code: "+961", code: "LB", flag: "🇱🇧", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "426", name: "Lesotho", dial_code: "+266", code: "LS", flag: "🇱🇸", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "428", name: "Letonia", dial_code: "+371", code: "LV", flag: "🇱🇻", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "430", name: "Liberia", dial_code: "+231", code: "LR", flag: "🇱🇷", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "434", name: "Libyan Arab Jamahiriya", dial_code: "+218", code: "LY", flag: "🇱🇾", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "438", name: "Liechtenstein", dial_code: "+423", code: "LI", flag: "🇱🇮", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "440", name: "Lithuania", dial_code: "+370", code: "LT", flag: "🇱🇹", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "442", name: "Luxembourg", dial_code: "+352", code: "LU", flag: "🇱🇺", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "446", name: "Macao", dial_code: "+853", code: "MO", flag: "🇲🇴", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "450", name: "Madagascar", dial_code: "+261", code: "MG", flag: "🇲🇬", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "454", name: "Malawi", dial_code: "+265", code: "MW", flag: "🇲🇼", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "458", name: "Malaysia", dial_code: "+60", code: "MY", flag: "🇲🇾", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "462", name: "Maldives", dial_code: "+960", code: "MV", flag: "🇲🇻", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "466", name: "Mali", dial_code: "+223", code: "ML", flag: "🇲🇱", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "470", name: "Malta", dial_code: "+356", code: "MT", flag: "🇲🇹", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "474", name: "Martinique", dial_code: "+596", code: "MQ", flag: "🇲🇶", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "478", name: "Mauritania", dial_code: "+222", code: "MR", flag: "🇲🇷", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "480", name: "Mauritius", dial_code: "+230", code: "MU", flag: "🇲🇺", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "484", name: "Mexico", dial_code: "+52", code: "MX", flag: "🇲🇽", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "492", name: "Monaco", dial_code: "+377", code: "MC", flag: "🇲🇨", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "496", name: "Mongolia", dial_code: "+976", code: "MN", flag: "🇲🇳", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "499", name: "Montenegro", dial_code: "+382", code: "ME", flag: "🇲🇪", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "504", name: "Morocco", dial_code: "+212", code: "MA", flag: "🇲🇦", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "508", name: "Mozambique", dial_code: "+258", code: "MZ", flag: "🇲🇿", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "512", name: "Oman", dial_code: "+968", code: "OM", flag: "🇴🇲", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "520", name: "Nauru", dial_code: "+674", code: "NR", flag: "🇳🇷", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "524", name: "Nepal", dial_code: "+977", code: "NP", flag: "🇳🇵", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "528", name: "Netherlands", dial_code: "+31", code: "NL", flag: "🇳🇱", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "530", name: "Netherlands Antilles", dial_code: "+599", code: "AN", flag: "🇳🇱", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "533", name: "Aruba", dial_code: "+297", code: "AW", flag: "🇦🇼", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "548", name: "Vanuatu", dial_code: "+678", code: "VU", flag: "🇻🇺", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "554", name: "New Zealand", dial_code: "+64", code: "NZ", flag: "🇳🇿", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "558", name: "Nicaragua", dial_code: "+505", code: "NI", flag: "🇳🇮", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "562", name: "Niger", dial_code: "+227", code: "NE", flag: "🇳🇪", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "566", name: "Nigeria", dial_code: "+234", code: "NG", flag: "🇳🇬", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "578", name: "Norway", dial_code: "+47", code: "NO", flag: "🇳🇴", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "590", name: "Panama", dial_code: "+507", code: "PA", flag: "🇵🇦", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "598", name: "Papua New Guinea", dial_code: "+675", code: "PG", flag: "🇵🇬", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "600", name: "Paraguay", dial_code: "+595", code: "PY", flag: "🇵🇾", document: [{ code: "CIP", type: "Número CIP" }, { code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "604", name: "Peru", dial_code: "+51", code: "PE", flag: "🇵🇪", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "608", name: "Philippines", dial_code: "+63", code: "PH", flag: "🇵🇭", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "612", name: "Pitcairn", dial_code: "+64", code: "PN", flag: "🇵🇳", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "616", name: "Poland", dial_code: "+48", code: "PL", flag: "🇵🇱", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "620", name: "Portugal", dial_code: "+351", code: "PT", flag: "🇵🇹", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "624", name: "Guinea-Bissau", dial_code: "+245", code: "GW", flag: "🇬🇼", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "634", name: "Qatar", dial_code: "+974", code: "QA", flag: "🇶🇦", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "638", name: "Reunion", dial_code: "+262", code: "RE", flag: "🇫🇷", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "642", name: "Romania", dial_code: "+40", code: "RO", flag: "🇷🇴", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "646", name: "Rwanda", dial_code: "+250", code: "RW", flag: "🇷🇼", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "652", name: "Saint Barthelemy", dial_code: "+590", code: "BL", flag: "🇧🇱", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "654", name: "Saint Helena, Ascension", dial_code: "+290", code: "SH", flag: "🇸🇭", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "659", name: "Saint Kitts and Nevis", dial_code: "+1869", code: "KN", flag: "🇰🇳", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "662", name: "Saint Lucia", dial_code: "+1758", code: "LC", flag: "🇱🇨", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "663", name: "Saint Martin", dial_code: "+590", code: "MF", flag: "🏳", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "666", name: "Saint Pierre and Miquelon", dial_code: "+508", code: "PM", flag: "🇵🇲", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "670", name: "Saint Vincent and the Grenadines", dial_code: "+1784", code: "VC", flag: "🇻🇨", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "678", name: "Sao Tome and Principe", dial_code: "+239", code: "ST", flag: "🇸🇹", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "682", name: "Saudi Arabia", dial_code: "+966", code: "SA", flag: "🇸🇦", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "686", name: "Senegal", dial_code: "+221", code: "SN", flag: "🇸🇳", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "688", name: "Serbia", dial_code: "+381", code: "RS", flag: "🇷🇸", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "690", name: "Seychelles", dial_code: "+248", code: "SC", flag: "🇸🇨", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "694", name: "Sierra Leone", dial_code: "+232", code: "SL", flag: "🇸🇱", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "702", name: "Singapore", dial_code: "+65", code: "SG", flag: "🇸🇬", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "704", name: "Vietnam", dial_code: "+84", code: "VN", flag: "🇻🇳", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "705", name: "Slovenia", dial_code: "+386", code: "SI", flag: "🇸🇮", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "706", name: "Somalia", dial_code: "+252", code: "SO", flag: "🇸🇴", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "707", name: "South Africa", dial_code: "+27", code: "ZA", flag: "🇿🇦", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "716", name: "Zimbabwe", dial_code: "+263", code: "ZW", flag: "🇿🇼", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "724", name: "Spain", dial_code: "+34", code: "ES", flag: "🇪🇸", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "736", name: "Sudan", dial_code: "+249", code: "SD", flag: "🇸🇩", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "748", name: "Swaziland", dial_code: "+268", code: "SZ", flag: "🇸🇿", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "752", name: "Sweden", dial_code: "+46", code: "SE", flag: "🇸🇪", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "756", name: "Switzerland", dial_code: "+41", code: "CH", flag: "🇨🇭", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "760", name: "Syrian Arab Republic", dial_code: "+963", code: "SY", flag: "🇸🇾", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "764", name: "Thailand", dial_code: "+66", code: "TH", flag: "🇹🇭", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "768", name: "Togo", dial_code: "+228", code: "TG", flag: "🇹🇬", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "776", name: "Tonga", dial_code: "+676", code: "TO", flag: "🇹🇴", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "780", name: "Trinidad and Tobago", dial_code: "+1868", code: "TT", flag: "🇹🇹", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "792", name: "Turkey", dial_code: "+90", code: "TR", flag: "🇹🇷", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "798", name: "Tuvalu", dial_code: "+688", code: "TV", flag: "🇹🇻", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "800", name: "Uganda", dial_code: "+256", code: "UG", flag: "🇺🇬", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "804", name: "Ukraine", dial_code: "+380", code: "UA", flag: "🇺🇦", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "807", name: "Macedonia", dial_code: "+389", code: "MK", flag: "🇲🇰", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "810", name: "Russia", dial_code: "+7", code: "RU", flag: "🇷🇺", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "818", name: "Egypt", dial_code: "+20", code: "EG", flag: "🇪🇬", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "834", name: "Tanzania", dial_code: "+255", code: "TZ", flag: "🇹🇿", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "840", name: "United States", dial_code: "+1", code: "US", flag: "🇺🇸", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "841", name: "Puerto Rico", dial_code: "+1939", code: "PR", flag: "🇵🇷", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "850", name: "Virgin Islands, U.S.", dial_code: "+1340", code: "VI", flag: "🇻🇮", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "860", name: "Uzbekistan", dial_code: "+998", code: "UZ", flag: "🇺🇿", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "862", name: "Venezuela", dial_code: "+58", code: "VE", flag: "🇻🇪", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "882", name: "Samoa", dial_code: "+685", code: "WS", flag: "🇼🇸", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "890", name: "Serbia", dial_code: "+381", code: "RS", flag: "🇷🇸", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "890", name: "Montenegro", dial_code: "+382", code: "ME", flag: "🇲🇪", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "894", name: "Zambia", dial_code: "+260", code: "ZM", flag: "🇿🇲", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "930", name: "Namibia", dial_code: "+264", code: "NA", flag: "🇳🇦", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "951", name: "Antigua and Barbuda", dial_code: "+1268", code: "AG", flag: "🇦🇬", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
  { number: "952", name: "Armenia", dial_code: "+374", code: "AM", flag: "🇦🇲", document: [{ code: "PASSPORT", type: "Nro Pasaporte" }] },
];
