import { RootState } from "application/store/reducers/rootReducer";
import { dtoIsYes } from "./helpersDto";

export const buildWorkDto = (data: RootState) => {
  const {
    CuartoPasoState: mainDataState,
    SeleccionarProductoState: { isPymes },
    ActivityReducer: activityState,
  } = data;

  const isPaidEmployement = dtoIsYes(mainDataState.work);
  const salary = isPymes
    ? activityState.companyIncome
    : mainDataState.workMoney;

  const { anioIngreso, diaIngreso, mesIngreso } = mainDataState;
  const initialDateEmployment = isPaidEmployement
    ? `${diaIngreso}/${mesIngreso}/${anioIngreso}`
    : "";
  return {
    isPaidEmployement,
    professionType: mainDataState.profesion,
    salary: Number(salary) || 0,
    initialDateEmployment,
  };
};
