import { Box, Modal, styled, Backdrop } from "@mui/material";
import loaderGif from "ui/assets/icons/santander-loader-red.gif";

type Props = { isLoading?: boolean };

const WhiteBackDrop = styled(Backdrop)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Santander Micro Text, Verdana, sans-serif",
  fontSize: "1rem",
  fontWeight: "bold",
  fontStyle: "normal",
  color: "#444344",
  textAlign: "center",
  height: "100vh",
  width: "100vw",
  backgroundColor: "#ffffff",
  zIndex: "-1",
  position: "fixed",
  right: "0",
  bottom: "0",
  top: "0",
  left: "0",
  opacity: "0.9",
}));

const Loader = (props: Props) => {
  return (
    <div>
      <Modal
        open={props.isLoading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={WhiteBackDrop}
      >
        <Box sx={styles.root}>
          <div>
            <img className="loaderImage" src={loaderGif} alt="Santander" />
            <div>Cargando...</div>
            <div className="grayText">
              No cierres ni actualices
              <br />
              el navegador
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Loader;

const styles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "body1.fontFamily",
    fontSize: "body1.fontSize",
    fontWeight: "fontWeightBold",
    fontStyle: "normal",
    color: "#444344",
    textAlign: "center",
    height: "100vh",
    width: "100vw",

    ".loaderImage": {
      width: "110px",
      height: "110px"
    },

    ".grayText": {
      color: "gray",
      fontSize: "body1.fontSize",
      marginTop: "8px",
      fontWeight: "body1.fontWeight",
    },
  },
};
