import logo from '../../../assets/images/footer-logo-red.png'
import {
  Instagram as InstagramIcon,
  Twitter as TwitterIcon
} from '@mui/icons-material';
import { SvgIcon } from '@mui/material';

export const Footer = () => (
  <footer className="bgFooter">
    <div className="container footerDiv mAuto">
      <div className="logoSantF">
        <img src={logo} alt="Santander Logo" />
      </div>
      <div className="sectIconSocialDesk" >
        <div>
          <a href="https://www.instagram.com/santander_uy/">
            <InstagramIcon className="socialIcons" />
          </a>
          <a href="https://www.facebook.com/SantanderUruguay">
            <SvgIcon>
              <path fill="white" d="M17.3862 5.32L15.5037 5.32125C14.0275 5.32125 13.7413 6.0225 13.7413 7.05125V9.32125H17.2625L16.8038 12.8775H13.7413V22H10.07V12.8775H7V9.32125H10.07V6.7C10.07 3.65625 11.9288 2 14.6425 2C15.9425 2 17.06 2.09625 17.3862 2.14V5.32Z" />
            </SvgIcon>
          </a>
          <a href="https://twitter.com/santander_uy?lang=es">
            <TwitterIcon className="socialIcons" />
          </a>
        </div>
        <div style={{
          fontSize: 12,
          color: 'white',
          textAlign: 'left',
          maxWidth: 400,
        }}>Infórmese sobre la Garantía de Depósitos, en su institución de intermediación financiera o en el sitio web www.copab.org.uy, o en el correo electrónico infocopab@copab.org.uy
        </div>
      </div>

    </div>

  </footer>
);

//footer red landin desktop