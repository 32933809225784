import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import App from "./ui/pages/App";
import store, { persistor } from "./application/store/Store";
import "./ui/scss/app.scss";
import { PersistGate } from "redux-persist/integration/react";
import { MixpanelService } from "infrastructure/services/tracker/mixpanel.service";
import { BrowserRouter } from "react-router-dom";

//TODO: Create a separated theme file and start to customize components via theme file instead of recreating and duplicating it.
import { SantanderTheme } from "ui/theme/SantanderTheme";

MixpanelService.initialize();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={SantanderTheme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);
