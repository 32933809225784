export class EitherErrorStatus {
  private hasError: boolean;
  private errorMessage: string;

  constructor(hasError: boolean, errorMessage?: string) {
    this.hasError = hasError;
    this.errorMessage = errorMessage;
  }

  public getHasError(): boolean {
    return this.hasError;
  }

  public setHasError(hasError: boolean): void {
    this.hasError = hasError;
  }

  public getErrorMessage(): string {
    return this.errorMessage;
  }

  public setErrorMessage(errorMessage: string): void {
    this.errorMessage = errorMessage;
  }

  public hasErrorMssg(): boolean {
    return this.errorMessage?.length > 0;
  }

  public goToOCR(): boolean {
    return this.errorMessage === 'OCR';
  }
}