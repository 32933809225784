import { AxiosResponse } from "axios";
import { DatosCedula } from "application/store/models/primerPaso";

import { GO_TO } from "infrastructure/api";
import { Checkpoints } from "ui/components/molecules/CheckpointStatus/CheckpointEvents";

import { RestClient } from "./rest-client";
import { CHANNEL_ID } from "application/utils/constants";
import { MixpanelService } from "./tracker/mixpanel.service";
import { LOG_LEVELS, SendLog } from "./log";
import store from "application/store/Store";
import { setOnBoardingCheckPoint, setOnBoardingIdRedux } from "application/store/reducers/OnboardingReducer";
import { EitherErrorStatus } from "./types/EitherErrorStatus";

// Types and Enums

export enum ProcessType {
  FOR_CEDULA = "CI",
  FOR_DOMICILIO = "INVOICE",
}

export enum ImageType {
  DOCUMENTO_DOMICILIO = "ADDRESS_CERTIFICATE",
  CEDULA_FRENTE = "CI_FRONT",
  CEDULA_DORSO = "CI_BACK",
  FOTO_FRENTE = "SELFIE",
  BPS = "BPS_CERTIFICATE",
  DGI = "DGI_CERTIFICATE",
  RUT = "RUT_CERTIFICATE",
}

export enum FileExtension {
  PDF = "PDF",
  JPG = "JPG",
}

type CreateOnboardingResponseData = {
  onboardingId: string;
  checkpoint: Checkpoints;
};

type CreateProspectResponseData = {
  prospectId: string;
  checkpoint: Checkpoints;
  // TODO: REVIEW THIS INTERFACE WITH BACKEND
  status: Checkpoints;
};

type CreateProspectResponse = AxiosResponse<CreateProspectResponseData>;

export enum ProductTypePayload {
  ACCOUNT = "ACCOUNT",
  CREDIT_CARD = "CREDIT_CARD",
  ACCOUNT_PYME = "ACCOUNT_PYME",
  SALARY_ACCOUNT = "ACCOUNT_INCOME_PF",
}

export interface DatosOcr {
  type?: string;
  onboardingId?: string;
}
export interface DatosCedulaFromOcr extends DatosOcr {
  name?: string;
  lastName?: string;
  birthDate?: string;
  personDataId?: string;
  documentType?: string;
  documentNumber?: string;
  nationality?: string;
  expirationDate?: string;
  placeOfBirth?: string;
  expedition?: string;
  observations?: string;
}

export interface DatosInvoiceFromOcr { }

type DataOcrResponse = AxiosResponse<DatosCedulaFromOcr | DatosInvoiceFromOcr>;

export enum DataOcrResponseStatus {
  EXITO = 200,
  DOCUMENTO_INVALIDO = 409,
  SERVICIO_NO_DISPONIBLE = 404,
  ERROR_PROCESANDO_DOCUMENTO = "ONB_0016",
  OBSERVACIONES = "ONB_0015",
  EXPIRO = "ONB_0014",
  MENOR_DE_EDAD = "ONB_0013",
  ONBOARDING_ID_NO_EXISTE_EN_APPIAN = "ONB_0012",
  DIRECCION_NO_ES_DOMICILIO = "ONB_0011",
  DATA_NO_ES_VALIDA = "ONB_0010",
  ONBOARDING_NO_FUE_ENVIADO = "ONB_0009",
  IMAGEN_NO_FUE_CARGADA = "ONB_0008",
  LA_SOLICITUD_YA_TIENE_IMAGEN_CARGADA = "ONB_0007",
  ONBOARDING_NO_EXISTE = "ONB_0006",
  REQUEST_MAL_FORMADO = "ONB_0004",
  ERROR_INESPERADO = "ONB_0003",
  CEDULA_IDENTIDAD_INVALIDA = "ONB_0002",
}
// End of Types and Enums

// Environment Constants Initialization
export const BACKEND_DIRECTLY = process.env.REACT_APP_BACKEND_URL;
const APIGEE_URL = process.env.REACT_APP_APIGEE_URL;
export const BASE_URL = APIGEE_URL;
export const BASE_URL_AUTHENTICATION = `${BACKEND_DIRECTLY}authentication-services`; //
export const BASE_URL_FOR_CHECKPOINT_ONLY = `${BACKEND_DIRECTLY}onboarding-api/v1`;
export const BASE_URL_ONBOARDING = `${BASE_URL}onboarding-api/v1`;
export const BASE_URL_PROSPECT = `${BASE_URL}onboarding-prospect-api/v1`;
export const BASE_URL_ONBOARDING_OCR = `${BASE_URL}onboarding-ocr-api/v1`;
export const BASE_URL_ONBOARDING_BACKOFFICE = `${BASE_URL}onboarding-backoffice-api/v1`;
export const BASE_URL_ONBOARDING_BACKOFFICE_POINTING_TO_DEV = `${BACKEND_DIRECTLY}onboarding-backoffice-api/v1`;
// End of Environment Constants Initialization

export const startOnboarding = (
  captchaToken: string,
  businessPartner = "1"
) => {
  localStorage.setItem("onboarding", "in-progress");
  localStorage.setItem("captchaToken", captchaToken);
  localStorage.setItem("businessPartner", businessPartner);
};
export const getCaptchaToken = () => localStorage.getItem("captchaToken");

export const getBusinessPartner = () => localStorage.getItem("businessPartner");

export const hasOnboardingStarted = () => localStorage.getItem("onboarding") === "in-progress";
export const finishOnboarding = () => localStorage.removeItem("onboarding");

export const setOnboardingId = (id: string) => localStorage.setItem("onboardingId", id);
export const getOnboardingId = () => localStorage.getItem("onboardingId");
export const removeOnboardingId = () => localStorage.removeItem("onboardingId");

export const setProspectId = (id: string) => localStorage.setItem("prospectId", id);
export const getProspectId = () => localStorage.getItem("prospectId");
export const removeProspectId = () => localStorage.removeItem("prospectId");

export const setToken = (accessToken: string) => sessionStorage.setItem("session-id", accessToken);
export const getToken = () => sessionStorage.getItem("session-id");

export const setSessionId = (existingSessionId: string | null) => {
  if (existingSessionId) {
    localStorage.setItem("session-id", existingSessionId);
  } else {
    const randomInt = Math.floor(Math.random() * 100000);
    const nowTime = Date.now();
    const sessionId = `session-${randomInt}-${nowTime}`;
    localStorage.setItem("session-id", sessionId);
  }
};
export const getSessionId = () => {
  return localStorage.getItem("session-id");
};

export type props = {
  doc: string,
  productType: ProductTypePayload,
  documentType: string,
  documentCountry?: string,
  captchaToken: string,
  businessPartner: string
}

const getStateAndDispatch = () => {
  return {
    state: store.getState(),
    dispatch: store.dispatch,
  };
};

export const postCreateOnboarding = async ({
  doc,
  productType,
  documentType,
  documentCountry,
  captchaToken,
  businessPartner
}: props
) => {
  const replaceRegex = /[^a-zA-Z0-9]/g;
  const url = `${BASE_URL_ONBOARDING}/onboardings`;
  const body = {
    businessPartner,
    documentType,
    documentNumber: doc.replace(replaceRegex, ""),
    documentCountry,
    productType,
    channelId: CHANNEL_ID,
  };

  const { dispatch } = getStateAndDispatch();

  try {

    MixpanelService.track("Onboarding Creation Request", {
      "Document ID": body.documentNumber
    }, true)

    const res = await RestClient.request<CreateOnboardingResponseData>({
      method: "post",
      url,
      data: body,
      excludeHeaders: ["obTraceId"],
      additionalHeaders: {
        "Recaptcha-Answer": captchaToken,
      },
    });

    const {
      data: { onboardingId, checkpoint },
    } = res;
    if (!onboardingId) {
      console.error("No onboarding id returned from service");
      return false;
    }

    if (
      String(checkpoint) === Checkpoints.ONBOARDING_DUPLICATED ||
      String(checkpoint) === Checkpoints.INIT_ONBOARDING_APPIAN_ERROR
    ) {
      MixpanelService.track("Errored", {
        When: "Onboarding Creation Request",
      });
      return false;
    }

    setOnboardingId(onboardingId);
    dispatch(setOnBoardingIdRedux({ onboardingId }));
    dispatch(setOnBoardingCheckPoint({ checkpoint }));

    const getStatusUrl = `${BASE_URL_ONBOARDING}/onboardings/${onboardingId}`;
    await RestClient.get(getStatusUrl);
    MixpanelService.track("Onboarding Created", {
      "Product Type": productType,
      "Business Partner": businessPartner,
      "Onboarding ID": onboardingId,
      "Session ID": getSessionId(),
      "First 3 document numbers": doc.replace(replaceRegex, "").toString().substring(0, 3),
    });

    return true;
  } catch (error) {
    MixpanelService.track("Create Onboarding Errored", {
      error
    })
    console.error("Error al crear onboarding", error);
    await SendLog(LOG_LEVELS.ERROR, "Error al crear onboarding", JSON.stringify(error));
    return false;
  }
};

interface CrearProspectoPayload {
  documentType: string;
  documentNumber: number;
  documentHasChip: boolean;
  securityCode?: string;
  serie?: string;
  folioNumber?: string;
}

export const postCrearProspecto = async (prospect: DatosCedula) => {
  const replaceRegex = /[^a-zA-Z0-9]/g;
  const dni = parseInt(prospect.dni.replace(replaceRegex, ""));
  const url = `${BASE_URL_PROSPECT}/prospects`;
  let body: CrearProspectoPayload = {
    documentType: "CI",
    documentNumber: dni,
    documentHasChip: prospect.conChip,
  };
  
  const codigoNueva = `000${prospect.codigo}`;

  if (prospect.conChip) {
    body = {
      ...body,
      securityCode: codigoNueva,
      serie: prospect.serie,
      folioNumber: "",
    };
  } else {
    body = {
      ...body,
      securityCode: prospect.isNewDNI ? codigoNueva : prospect.serie,
      serie: prospect.serie,
      folioNumber: prospect.folio,
    };
  }
  try {
    const response: CreateProspectResponse = await RestClient.post(url, body);
    const {
      data: { prospectId, status },
    } = response;
    if (!prospectId) {
      window.history.replaceState(null, "", GO_TO.LANDING);
      throw new Error("No hay prospectId definido");
    }
    setProspectId(prospectId);

    MixpanelService.track(
      "Prospect Created",
      {
        "Prospect ID": prospectId,
      },
      true
    );

    switch (status) {
      case Checkpoints.DNIC_IS_AVAILABLE:
        return new EitherErrorStatus(false);

      case Checkpoints.DNIC_ERROR_701:
      case Checkpoints.DNIC_ERROR_1002: {
        return new EitherErrorStatus(true, "OCR");
      }
      case Checkpoints.DNIC_ERROR_702:
      case Checkpoints.DNIC_ERROR_703:
      case Checkpoints.DNIC_ERROR_704:
        return new EitherErrorStatus(
          true,
          "El código no corresponde a tu último documento."
        );
      case Checkpoints.DNIC_ERROR_705:
        return new EitherErrorStatus(
          true,
          "El código no coincide con tu documento."
        );

      case Checkpoints.DNIC_ERROR_1001:
      case Checkpoints.DNIC_ERROR_1003: {
        return new EitherErrorStatus(true);
      }

      default:
        return new EitherErrorStatus(true, "OCR");
    }
  } catch (error) {
    MixpanelService.track(
      "Errored",
      {
        When: "Prospect Creation Request",
      },
      true
    );
    console.error("Error al crear prospecto", error);
    await SendLog(
      LOG_LEVELS.ERROR,
      "Error al crear prospecto",
      JSON.stringify(error)
    );

    return new EitherErrorStatus(
      true,
      `Error al crear prospecto ${JSON.stringify(error)}`
    );
  }
};

export const actualizarProspecto = async (phone, emailAddress, tags) => {
  const mobileNumber = phone.replace(/\s+/g, "");
  try {
    await RestClient.patch(
      `${BASE_URL_PROSPECT}/prospects/${getProspectId()}/contact-info`,
      {
        emailAddress,
        mobileNumber: "598" + mobileNumber,
        tags
      }
    );
    return true;
  } catch (error) {
    console.error("Error when updating prospect", error);
    await SendLog(LOG_LEVELS.ERROR, "Error when updating prospect", JSON.stringify(error));
    return false;
  }
};

export const postCedulaToOCR = async (cedulas: FormData) => {
  try {
    await RestClient.postFormData(
      `${BASE_URL_ONBOARDING_OCR}/onboardings-ocr/files/${getOnboardingId()}/${ProcessType.FOR_CEDULA
      }`,
      cedulas
    );

    return true;
  } catch (error) {
    console.error("Error al cargar la cedula", error);
    await SendLog(LOG_LEVELS.ERROR, "Error al cargar la cedula", JSON.stringify(error));

    return false;
  }
};

export const postDomicilioToOCR = async (factura: FormData, isPDF: boolean) => {
  try {
    await RestClient.postFormData(
      `${BASE_URL_ONBOARDING_OCR}/onboardings-ocr/files/${getOnboardingId()}/${ProcessType.FOR_DOMICILIO
      }`,
      factura
    );
    return true;
  } catch (error) {
    console.error("Error al cargar el documento de domicilio", error);
    await SendLog(LOG_LEVELS.ERROR, "Error al cargar el documento de domicilio", JSON.stringify(error));
    return false;
  }
};

export interface IAddressInvoice {
  city: string;
  department: string;
  streetName: string;
  streetIdentification: string;
  complementaryData: string;
}

export const postAddressAfterInvoiceProcessed = async (
  addressInvoice: IAddressInvoice
) => {
  try {
    const response: DataOcrResponse = await RestClient.post(
      BASE_URL_ONBOARDING_OCR +
      `/onboardings-ocr/files/${getOnboardingId()}/validate/${ProcessType.FOR_DOMICILIO
      }`,
      addressInvoice
    );
    return {
      isSuccess: true,
      data: response.data,
      status: DataOcrResponseStatus.EXITO,
    };
  } catch (e: any) {
    if (e.response) {
      const data = e.response?.data?.errors[0];
      const status = e.response?.data?.errors[0]?.code;
      await SendLog(LOG_LEVELS.TRACE, `TRACE CODE: ${status}`, JSON.stringify(e.response));
      return {
        isSuccess: false,
        data,
        status,
      };

    } else if (e.request) {
      console.error("No hay data del proceso de OCR", e.request);
      await SendLog(LOG_LEVELS.TRACE, "No hay data del proceso de OCR", JSON.stringify(e.request));
    } else {
      console.error("Error al consultar data procesada por el OCR", e.message);
      await SendLog(LOG_LEVELS.TRACE, "Error al consultar data procesada por el OCR", JSON.stringify(e.message));
    }
    await SendLog(LOG_LEVELS.ERROR, "Servicio no disponible");
    return {
      isSuccess: false,
      data: {},
      status: DataOcrResponseStatus.SERVICIO_NO_DISPONIBLE,
    };
  }
};

export const getDataFromOCR = async (processType: ProcessType) => {
  try {
    const response: DataOcrResponse = await RestClient.get(
      BASE_URL_ONBOARDING_OCR +
      `/onboardings-ocr/files/${getOnboardingId()}/${processType}/data`
    );
    return {
      isSuccess: true,
      data: response.data,
      status: DataOcrResponseStatus.EXITO,
    };
  } catch (e: any) {
    if (e.response) {
      const data = e.response?.data?.errors[0];
      const status = e.response?.data?.errors[0]?.code;
      await SendLog(LOG_LEVELS.TRACE, `TRACE CODE: ${status}`, JSON.stringify(e.response));
      return {
        isSuccess: false,
        data,
        status,
      };
    } else if (e.request) {
      console.error("No hay data del proceso de OCR", e.request);
      await SendLog(LOG_LEVELS.TRACE, "No hay data del proceso de OCR", JSON.stringify(e.request));
    } else {
      console.error("Error al consultar data procesada por el OCR", e.message);
      await SendLog(LOG_LEVELS.TRACE, "Error al consultar data procesada por el OCR", JSON.stringify(e.message));
    }
    await SendLog(LOG_LEVELS.ERROR, "Servicio no disponible");
    return {
      isSuccess: false,
      data: {},
      status: DataOcrResponseStatus.SERVICIO_NO_DISPONIBLE,
    };
  }
};

const sendSixthStep = async (img: string, fileExtension: string) => {
  const onboardingId = getOnboardingId();
  if (!onboardingId) return false;

  const body = {
    base64: img.split(",").pop(),
    attachmentType: ImageType.FOTO_FRENTE,
    fileExtension,
  };

  try {
    const data = await RestClient.put(
      `${BASE_URL_ONBOARDING_OCR}/onboardings-ocr/files/${onboardingId}/images`,
      body
    );

    return data;
  } catch (error) {
    console.error("Error al enviar los datos del sexto paso", error);
    await SendLog(LOG_LEVELS.ERROR, "Error al enviar los datos del sexto paso", JSON.stringify(error));
  }
  return false;
};



const sendNps = async (reason, score) => {
  const prospectId = getProspectId();
  const url = `${BASE_URL_PROSPECT}/prospects/${prospectId}/answers`;
  try {
    await RestClient.patch(url, {
      reason,
      score: Number(score),
    });
    return true;
  } catch (error) {
    await SendLog(LOG_LEVELS.ERROR, "Error al enviar NPS", JSON.stringify(error));
    return false;
  }
};

export const postCedulaToOCR2 = async (
  cedulaFront: string,
  cedulaBack: string
) => {
  const payload = {
    files: [
      {
        base64: cedulaFront,
        attachmentType: ImageType.CEDULA_FRENTE,
        fileExtension: FileExtension.JPG,
      },
      {
        base64: cedulaBack,
        attachmentType: ImageType.CEDULA_DORSO,
        fileExtension: FileExtension.JPG,
      },
    ],
  };

  try {
    await RestClient.post(
      `${BASE_URL_ONBOARDING_OCR}/onboardings-ocr/files/${getOnboardingId()}/${ProcessType.FOR_CEDULA
      }`,
      payload
    );
    return true;
  } catch (error) {
    console.error("Error al cargar la cedula", error);
    await SendLog(LOG_LEVELS.ERROR, "Error al cargar la cedula", JSON.stringify(error));
    return false;
  }
};

export const postDomicilioToOCR2 = async (
  documento: string,
  isPDF: boolean
) => {
  const payload = {
    files: [
      {
        base64: documento,
        attachmentType: ImageType.DOCUMENTO_DOMICILIO,
        fileExtension: isPDF ? FileExtension.PDF : FileExtension.JPG,
      },
    ],
  };

  try {
    await RestClient.post(
      `${BASE_URL_ONBOARDING_OCR}/onboardings-ocr/files/${getOnboardingId()}/${ProcessType.FOR_DOMICILIO
      }`,
      payload
    );
    return true;
  } catch (error) {
    console.error("Error al cargar documento", error);
    await SendLog(LOG_LEVELS.ERROR, "Error al cargar documento", JSON.stringify(error));
    return false;
  }
};

export enum TypeToken {
  BEARER = "Bearer",
}

export interface IAuthProxy {
  accessToken: string;
  typeToken: TypeToken.BEARER;
  hasToken: boolean;
}

export class AuthProxyResponse implements IAuthProxy {
  readonly accessToken: string;
  readonly typeToken: TypeToken.BEARER;
  readonly hasToken: boolean;

  constructor(accessToken, typeToken, hasToken) {
    this.accessToken = accessToken;
    this.typeToken = typeToken;
    this.hasToken = hasToken;
  }
}

type AuthenticationProxyResponse = AxiosResponse<IAuthProxy>;

export const connectToAuthProxy = async () => {
  const { REACT_APP_CLIENT_ID: CLIENT_ID } = process.env;
  try {
    const {
      data: { accessToken, typeToken },
    }: AuthenticationProxyResponse = await RestClient.request<any>({
      method: "get",
      url: `${BASE_URL_AUTHENTICATION}/auth-proxy/token`,
      overrideHeaders: { clientId: CLIENT_ID },
    });
    return new AuthProxyResponse(accessToken, typeToken, true);
  } catch (error) {
    console.error(error);
    await SendLog(LOG_LEVELS.ERROR, "Error al intentar obtener Token", JSON.stringify(error));
    return new AuthProxyResponse("", "", false);
  }
};

export const getAffinityById = async (id: string) => {
  try {
    const response = await RestClient.get<any>(
      `${BASE_URL_ONBOARDING_BACKOFFICE}/affinity-programs/${id}`
    );
    return response.data.response;
  } catch (error) {
    console.error("Error al intentar obtener afinidad por ID", error);
    await SendLog(LOG_LEVELS.ERROR, "Error al intentar obtener afinidad por ID", JSON.stringify(error));
    return false;
  }
};

interface GetAfinitiesRequest {
  salary: number;
  age: number;
}

export const getAfinities = async (request: GetAfinitiesRequest) => {
  try {
    const response = await RestClient.get<any>(
      `${BASE_URL_ONBOARDING_BACKOFFICE}/affinity-programs/available-ones`,
      request
    );
    // const response = await rest.get<Afinity[]>("/mocks/afinities.json", {
    //   params: request,
    // });
    // return response.data;
    return response.data.response;
  } catch (error) {
    console.error("Error al intentar obtener afinidades", error);
    await SendLog(LOG_LEVELS.ERROR, "Error al intentar obtener afinidades", JSON.stringify(error));
    return false;
  }
};

interface GetPackOfferRequest {
  affinityProgramId: number;
  age: number;
  salary: number;
}

export const getPackOffers = async (request: GetPackOfferRequest) => {
  try {
    const response = await RestClient.get<any>(
      `${BASE_URL_ONBOARDING_BACKOFFICE}/pack-offer/available-ones`,
      request
    );

    return response.data.response;
  } catch (error) {
    console.error("Error al intentar obtener Pack Offers", error);
    await SendLog(LOG_LEVELS.ERROR, "Error al intentar obtener Pack Offers", JSON.stringify(error));
    return false;
  }
};

export const getStatusCheckpoint = async () => {
  try {
    const getStatusUrl = `${BASE_URL_ONBOARDING}/onboardings/checkpoints`;
    const response = await RestClient.get<any>(getStatusUrl);
    return response.data;
  } catch (error) {
    console.error("Error al intentar obtener el ultimo estado del checkpoint", error);
    await SendLog(LOG_LEVELS.ERROR, "Error al intentar obtener el ultimo estado del checkpoint", JSON.stringify(error));
    return false;
  }
};

export const uploadDocumentByType = async (
  img: string,
  fileExtension: string,
  documentType: ImageType
) => {
  const onboardingId = getOnboardingId();
  if (!onboardingId) return false;

  const isPDF = fileExtension === "PDF";
  const body = {
    base64: img.split(",").pop(),
    attachmentType: documentType,
    fileExtension: isPDF ? FileExtension.PDF : FileExtension.JPG,
  };

  try {
    const data = await RestClient.put(
      `${BASE_URL_ONBOARDING_OCR}/onboardings-ocr/files/${onboardingId}/images`,
      body
    );

    return data;
  } catch (error) {
    console.error(`Error al cargar el documento: ${documentType}`, error);
    await SendLog(LOG_LEVELS.ERROR, `Error al cargar el documento: ${documentType}`, JSON.stringify(error));
  }
  return false;
};

export { sendSixthStep, sendNps };