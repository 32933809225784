import { ExperimentType } from "./ExperimentsConfig";

const LOCAL_STORAGE_KEY = "experimentsData";

class ExperimentsHandler {
  private assignedVariants: Record<string, string> = {};

  constructor() {
    const experimentData = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (experimentData) {
      this.assignedVariants = JSON.parse(experimentData);
    }
  }

  private assignVariant(experimentConfig: ExperimentType) {
    const experimentName = experimentConfig.name;

    const variant = this.getRandomVariant(experimentConfig);

    this.assignedVariants[experimentName] = variant;
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify(this.assignedVariants)
    );
    return variant;
  }

  private getRandomVariant(experimentConfig: ExperimentType) {
    const randomIndex = Math.floor(
      Math.random() * experimentConfig.variants.length
    );
    return experimentConfig.variants[randomIndex];
  }

  public initializeExperiment(experimentConfig: ExperimentType) {
    try {
      const experimentName = experimentConfig.name;

      const experimentData = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (experimentData) {
        this.assignedVariants = JSON.parse(experimentData);
        if (this.assignedVariants[experimentName]) {
          return;
        }
      }

      this.assignVariant(experimentConfig);
    } catch {
      console.error("Error while initializing experiment");
      this.assignedVariants = {};
    }
  }

  public getCurrentVariant(experimentConfig: ExperimentType) {
    try {
      const experimentName = experimentConfig.name;
      return this.assignedVariants[experimentName];
    } catch {
      console.error("Error while getting current variant");
      return "NOT_FOUND";
    }
  }

  public getActiveExperiments() {
    return this.assignedVariants;
  }

  public renderVariant<TResult>(
    experimentConfig: ExperimentType,
    params: Record<string, TResult>
  ): TResult {
    try {
      const variant = this.getCurrentVariant(experimentConfig);
      const objectToRender = params[variant];
      if (!variant || !objectToRender) {
        console.error("Render Variant: No variant found");
        return null;
      }
      return params[variant];
    } catch {
      console.error("Error while rendering variant");
      return null;
    }
  }
}

export default new ExperimentsHandler();
