import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import iconArrowDown from "ui/assets/icons/icon-arrow-down-selector.svg";
import classNames from "classnames";
import Flags from "country-flag-icons/react/1x1";
import { Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { styles } from "./styles";

type Props = {
  id?: string,
  label: string,
  labelId: string,
  wrapperStyles: any,
  values: any,
  props?: any,
  defaultValue?: string,
  itemStyles?: string,
  withDivider?: Boolean,
  country?: any,
};

const CustomSelectorCountry: React.FC<Props> = ({
  id = "",
  label,
  labelId,
  wrapperStyles,
  values,
  props,
  defaultValue = "",
  itemStyles = "",
  withDivider = false,
  country,
}) => {
  const [open, setOpen] = useState(false);
  const Flag = Flags[country.code.toUpperCase() ? country.code.toUpperCase() : 'UY'];
  return (
    <Box position={"relative"} id={id} sx={wrapperStyles}>
      <Box sx={{ pointerEvents: "none" }}>
        <img
          src={iconArrowDown}
          alt={"icon Arrow Down"}
          onClick={() => {
            setOpen(!open);
          }}
          style={{
            position: 'absolute',
            zIndex: 1,
            right: '16px',
            top: '12px'
          }}
        />
      </Box>
      <FormControl sx={styles.root}>
        <InputLabel id={`${labelId}-label`} className="input-label" >
          {label}
        </InputLabel>
        <Select
          labelId={`${labelId}-label`}
          label={label}
          id={`${labelId}-select`}
          variant="standard"
          IconComponent={() => <Box className="icon-component">
            <Flag />
          </Box>}
          className={classNames(itemStyles, "select-styles")}
          open={open}
          defaultValue={defaultValue}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          {...props}
        >
          {values.map((val, index) => (
            <MenuItem sx={styles.menuItemCss}
              className={classNames({
                menuItemWithDivider: withDivider,
                menuItem: !itemStyles,
                [itemStyles]: itemStyles.length,
              })}
              key={index}
              value={val.name}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    val.name.charAt(0).toUpperCase() +
                    val.name.slice(1).toLowerCase(),
                }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default CustomSelectorCountry;