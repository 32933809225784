import { createSlice } from "@reduxjs/toolkit";
import { DatosActivity } from "../models/activityType";

export type ActivityReducer = DatosActivity;

const initialState: ActivityReducer = {
  activity: "",
  activityName: "",
  civilState: "",
  partnerDocument: "",
  partnerName: "",
  partnerLastName: "",
  rutNumber: "",
  student: "",
  studentCenter: "",
  studentCenterName: "",
  companyIncome: "",
  startDay: "",
  startMonth: "",
  startYear: "",
};

export const ActivityReducerState = createSlice({
  name: "Activity",
  initialState,
  reducers: {
    setActivityRedux: (state, action) => {
      return action.payload;
    }
  }
});

export const { setActivityRedux } = ActivityReducerState.actions;

export default ActivityReducerState.reducer;
