import { createSlice } from "@reduxjs/toolkit";
import { DatosSeptimoPaso } from "./../models/septimoPaso";
export type SeptimoPasoState = DatosSeptimoPaso;

const initialState: SeptimoPasoState = {
  ciudad: "",
  calle: "",
  numero: "",
  departamento: "",
  datosComplementarios: "",
  constanciaAMiNombre: "",
  vivoEnDomicilio: false,
  numeroDeApartamento: "",
};

export const SeptimoPasoReducer = createSlice({
  name: "SeptimoPaso",
  initialState,
  reducers: {
    setSeventhStep: (state, action) => {
      return {...state, ...action.payload}
    }
  }
});

export const { setSeventhStep } = SeptimoPasoReducer.actions;

export default SeptimoPasoReducer.reducer;
