import { Box } from "@mui/material";
import { getIsPymeSelector } from "application/store/reducers/SeleccionarProductoReducer";
import React from "react";
import { useSelector } from "react-redux";

type Props = {};

const modalInfoParagraphs = [
  "Tomo conocimiento que Banco Santander S.A. verificará mis datos personales y crediticios, a los efectos de esta solicitud.",
  "Declaro que no manejaré fondos de terceros a través de las cuentas y que soy el Beneficiario Final de los mismos.",
  "Me comprometo a no realizar transacciones superiores a U$S 5.000 (o su equivalente en otras monedas) mensuales, ni operaciones con criptomonedas de ningún tipo.",
  "En caso de verificarse algún incumplimiento a lo declarado y comprometido, acepto que se me aplique la normativa y  las condiciones generales vigentes con el Banco",
];

const modalInfoParagraphsPyme = [
  "Tomo conocimiento que Banco Santander S.A. verificará mis datos personales y crediticios, a los efectos de esta solicitud.",
  "Declaro que no manejaré fondos de terceros a través de las cuentas y que soy el Beneficiario Final de los mismos.",
  "Me comprometo a no realizar transacciones superiores a U$S 10.000 (o su equivalente en otras monedas) mensuales, ni operaciones con criptomonedas de ningún tipo.",
  "En caso de verificarse algún incumplimiento a lo declarado y comprometido, acepto que se me aplique la normativa y  las condiciones generales vigentes con el Banco",
];

const TerminosYCondiciones: React.FC<Props> = () => {
  const terms = useSelector(getIsPymeSelector)
    ? modalInfoParagraphsPyme
    : modalInfoParagraphs;
  return (
    !!modalInfoParagraphs?.length && (
      <Box sx={styles.root} onPointerMove={(e) => e.preventDefault()}>
        {terms.map((par, index) => (
          <p className="text" key={index}>{par}</p>
        ))}
      </Box>
    )
  );
};

export default TerminosYCondiciones;

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",

    ".text": {
      fontFamily: "body2.fontFamily",
      fontSize: "body2.fontSize",
      fontWeight: "body2.fontWeight",
      fontStyle: "normal",
      color: "#444444",
      paddingRight: "0.5rem",
      paddingLeft: "0.5rem",
      marginBottom: "0.5rem",
      marginTop: "0.5rem",
      alignSelf: "flex-start",
    },
  },
};
