import { createTheme } from '@mui/material';
/* import SantanderHeadline from '../assets/fonts/Santander/SantanderHeadline-Regular.ttf'; */
import { color, font/* , spacing  */ } from './tokens';

export const SantanderTheme = createTheme({
  palette: {
    primary: {
      main: color.primary['01'],
      light: '#EC000011',
    },
    secondary: {
      main: color.primary['06'],
      dark: color.primary['05'],
    },
  },
  typography: {
    fontFamily: 'Santander Micro Text',
    fontWeightBold: 'bold',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    body1: {
      fontFamily: 'Santander Micro Text',
      fontSize: font['03'],
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: 1.5,
    },
    body2: {
      fontFamily: 'Santander Micro Text',
      fontSize: font['02'],
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: 1.43,
    },
    h1: {
      fontFamily: 'Santander Headline',
      fontSize: font['10'],
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: 1.167
    },
    h2: {
      fontFamily: 'Santander Headline',
      fontSize: font['09'],
      fontStyle: 'normal',
      fontWeight: 900,
      lineHeight: 1.2
    },
    h3: {
      fontFamily: 'Santander Headline',
      fontSize: font['08'],
      fontStyle: 'normal',
      fontWeight: 800,
      lineHeight: 1.167
    },
    h4: {
      fontFamily: 'Santander Headline',
      fontSize: font['07'],
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 1.235
    },
    h5: {
      fontFamily: "Santander Headline",
      fontSize: font['06'],
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 1.334
    },
    h6: {
      fontFamily: "Santander Micro Text",
      fontSize: font['05'],
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 1.6
    },
    subtitle1: {
      fontFamily: "Santander Micro Text",
      fontSize: font['04'],
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.75
    },
    subtitle2: {
      fontFamily: "Santander Micro Text",
      fontSize: font['03'],
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: 1.57
    },
    caption: {
      fontFamily: "Santander Micro Text",
      fontSize: font['01'],
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.57
    },
    button: {
      fontFamily: 'Santander Micro Text',
    },

  },
});