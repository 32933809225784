import { DatosEnvioRetiro } from "application/store/types/types";
import { RootState } from "./rootReducer";
import { createSlice } from "@reduxjs/toolkit";

export type QuintoPasoState = DatosEnvioRetiro;

const initialState: QuintoPasoState = {
  ciudad: "",
  calle: "",
  numero: "",
  departamento: "",
  datosComplementarios: "",
  guardarDomicilio: false,
  sucursal: "",
  retiroSucursal: false,
  envioDomicilio: false,
  totem: false,
  horario: "",
  numeroDeApartamento: "",
};

export const QuintoPasoReducer = createSlice({
  name: "QuintoPaso",
  initialState,
  reducers: {
    setFifthStep: (state, action) => {
      return action.payload
    }
  }
});

export const { setFifthStep } = QuintoPasoReducer.actions;

export const getIsAddressStoredSelector = (state: RootState): boolean => {
  return (
    state.QuintoPasoState.guardarDomicilio &&
    state.QuintoPasoState.ciudad &&
    state.QuintoPasoState.ciudad.length > 0
  );
};

export default QuintoPasoReducer.reducer;
