import { ReactElement, useState } from "react";
import InputMask from "react-input-mask";
import cx from "classnames";
import { Box } from "@mui/material";
import { styles } from "./styles";

interface InputProps {
  id?: string;
  primary?: boolean;
  mask: string;
  size?: "small" | "medium" | "large";
  placeholder: string;
  maxlenght: number;
  helpmessage: string;
  count?: string;
  onChange?: (e: Event | any) => void;
  className?: string;
  hasError: boolean;
  errorComponent?: ReactElement<any, any>;
  style?: object;
  value?: string;
}

export const Input = ({
  id,
  primary = false,
  mask = "",
  size = "medium",
  placeholder = "",
  maxlenght = 12,
  helpmessage = "",
  count = "",
  onChange,
  className = "",
  hasError = false,
  errorComponent = null,
  value = "",
  ...props
}: InputProps) => {
  const mode = primary
    ? "onboarding-input--primary"
    : "onboarding-input--secondary";
  const [isFocus, setIsFocus] = useState(false);
  
  return (
    <Box sx={styles.root}>
      <InputMask
        mask={mask}
        placeholder={placeholder}
        maskChar={""}
        className={cx(
          "onboarding-input",
          `onboarding-input--${size}`,
          mode,
          className
        )}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onChange={onChange}
        value={value}
        id={id}
        {...props}
      />
      {hasError && errorComponent}
      {!hasError && isFocus && (
        <label>
          <span className="lblContador">
            <span className="contLabelHM">{helpmessage}</span>
            <span className="contNumHM">{count}</span>
          </span>
        </label>
      )}
    </Box>
  );
};
