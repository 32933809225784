import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FC } from "react";
import caras from "../../../assets/images/caritas.png";

type NPSFormProps = {
  productTitle: string;
  onChange: (value: string) => void;
  value: string;
  reason: string;
  onChangeReason: (value: string) => void;
  onSend: () => void;
};

const NPSForm: FC<NPSFormProps> = (props) => {
  const { productTitle, onChange, value, onChangeReason, reason, onSend } =
    props;
  const isValid = value && value.length > 0;

  return (
    <Grid
      id="paso-final-encuesta-satisfaccion"
      mt={2.5}
      width="100%"
      sx={styles.root}
      mb={2.5}
    >
      <Grid className="textQuest" mb={"24px"}>
        <Box>
          ¿Recomendarías la contratación digital de tu {productTitle} a
          familiares y/o amigos?
        </Box>
      </Grid>
      <Grid display="flex" flexDirection="column" alignItems="center">
        <Grid width={"100%"}>
          <Grid
            mb={"10px"}
            container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Grid className="textDesc left">Poco probable</Grid>
            <Grid className="textDesc right">Muy probable</Grid>
          </Grid>
          <img src={caras} alt="" width="100%" />
        </Grid>
        <Grid display="flex" flexDirection="row" width={"100%"}>
          <FormControl
            component="fieldset"
            style={{ width: "100%", margin: "5px 0" }}
          >
            <RadioGroup
              row
              aria-label="gender"
              name="row-radio-buttons-group"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].map(
                (value) => (
                  <FormControlLabel
                    style={{ margin: "0" }}
                    value={value}
                    onClick={() => onChange(value)}
                    control={<Radio color="default" style={{ padding: "0" }} />}
                    label=""
                  />
                )
              )}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid
          container
          display="flex"
          flexDirection="column"
          textAlign="center"
          alignItems="center"
          mt={"31px"}
        >
          <Grid xs={12} className="textQuest" mb={"13px"}>
            ¿Por qué motivo asignaste dicha puntuación?
          </Grid>
          <Grid xs={12} mb={2}>
            <Box
              component="textarea"
              id="motivo"
              placeholder="Escribe entre 5 y 150 caracteres"
              value={reason}
              onChange={(e) => onChangeReason(e.target.value)}
              rows={4}
              cols={42}
              maxLength={150}
              sx={{
                mb: "2rem",
                pt: "16px",
                width: "100%",
                maxWidth: 335,
                "&::placeholder": {
                  fontStyle: "italic",
                },
              }}
            ></Box>
          </Grid>
        </Grid>
        <button
          className="npsButton"
          id="septimo-paso-enviar-opinion-button"
          onClick={onSend}
          disabled={!isValid}
        >
          Enviar opinión
        </button>
      </Grid>
    </Grid>
  );
};

export default NPSForm;

const styles = {
  root: {
    "& .npsText": {
      marginBottom: "2rem",
      paddingTop: "16px",
      width: "100%",
      border: "1px solid #DEEDF2 !important",
      borderRadius: "4px 4px 0px 0px",
      background: "#FFFFFF",
      maxWidth: 325,
      "&::placeholder": {
        fontStyle: "italic",
      },
      "&:focus-visible": {
        outlineWidth: "0px",
      },
    },

    "& .npsButton": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "12px 20px",
      height: 40,
      background: "#ec0000",
      border: "1px solid #ec0000",
      boxSizing: "border-box",
      boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.12)",
      borderRadius: 40,
      width: "100%",
      maxWidth: 335,
      fontFamily: "Santander Micro Text, Verdana, sans-serif",

      fontWeight: "bold",
      fontSize: "1rem",
      textAlign: "center",
      color: "#ffffff",
      "&:active": {
        opacity: "50%",
      },
      "&:disabled": {
        background: "gray",
        border: "1px solid gray",
      },
    },
    "& .containerDiv": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "2rem",
    },
    ".textQuest": {
      fontSize: "16px",
      fontWeight: "400",
      fontFamily: "Santander Micro Text",
      lineHeight: "24px",
      letterSpacing: "0em",
      textAlign: "left",
      textColor: "#444444",
    },
    ".textDesc": {
      fontFamily: "Santander Micro Text",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      letterSpacing: "0em",
      textColor: "#444444",
    },
    ".right": {
      textAlign: "right",
    },
    ".left": {
      textAlign: "left",
    },
  },
};
