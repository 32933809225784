import { RootState } from "application/store/reducers/rootReducer";
import { SubmissionPayload } from "../types/SubmissionPayload";

export const buildCreditCardDto = (
  data: RootState
): Record<"creditCard", SubmissionPayload["creditCard"]> => {
  const {
    PackOfferState: { packOffer, selectedDeadline },
  } = data;
  return {
    creditCard: {
      deadlineDay: selectedDeadline?.dayOfMonth,
      packCodeinAppian: String(packOffer?.packCodeInAppian),
    },
  };
};
