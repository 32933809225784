import { Grid } from "@mui/material";
import { countriesOrder } from "ui/components/molecules/TercerPaso/helpers";
import CustomSelectCountry from '../CustomSelectCountry';
import { countries } from './countries';

const CustomFlagCountry = ({ country, setCountry }) => {

  return (
    <Grid key={"countriesGroups"} item xs={12}>
      <CustomSelectCountry
        label={"País emisor del documento"}
        labelId={"OriginCountry"}
        values={countriesOrder({countries})}
        wrapperStyles={{ marginBottom: "20px" }}
        country={country}
        withDivider
        props={{
          value: country.name,
          onChange: (e) => {
            setCountry(countries.filter(value => value.name === e.target.value)[0]);
          },
        }}
      />
    </Grid>
  )
}

export default CustomFlagCountry