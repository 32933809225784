import { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FooterMobile } from "../footer/FooterMobile";
import { HeaderMobile } from "../header/HeaderMobile";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import { sendNps } from "infrastructure/services";
import { GO_TO } from "infrastructure/api";
import { setLoadingState } from "application/store/reducers/GlobalLoadingStateReducer";
import { useGetFinalStepData } from "../PasoFinal/hooks";
import { TrackerService } from "infrastructure/services/tracker/tracker.service";
import TitleWithLogo from "./../TitleWithLogo";
import NPSForm from "../form-pieces/NPSForm";
import {
  setStartOnBoarding,
  setUrlParams,
} from "application/store/reducers/OnboardingReducer";
import { styles } from "../PasoFinal/styles";
import { getProductSelector } from "application/store/reducers/SeleccionarProductoReducer";
import { goToHomePage } from "../PasoFinal/helpers";
import { goToSoySantander } from "../PasoFinal/helpers";

const NPS = () => {
  const [motivo, setMotivo] = useState("");
  const [puntuacion, setPuntuacion] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  window.isFinalState = searchParams.get("finished") === "true";

  const isCredit = useSelector(getProductSelector).isCredito;

  // Call hook that will get all relevant data
  const finalStepData = useGetFinalStepData();

  const submitNps = useCallback(async () => {
    dispatch(setLoadingState(true));
    const successNps = await sendNps(motivo, puntuacion);
    dispatch(setLoadingState(false));

    if (successNps) navigate(GO_TO.NPS_EXITOSO, { replace: true });
  }, [motivo, puntuacion]);

  useEffect(() => {
    dispatch(setStartOnBoarding({ startOnboarding: false }));
    dispatch(setUrlParams({ urlParams: "" }));
  }, []);

  useEffect(() => {
    if (submitting) submitNps();
  }, [submitting, submitNps, motivo, puntuacion]);

  const onSendNps = () => {
    setSubmitting(true);
  };

  return (
    <Box sx={styles.root}>
      <ScrollToTopOnMount />
      <HeaderMobile title={TitleWithLogo} />
      <Grid
        p={2.5}
        display="flex"
        flexDirection="column"
        className="gridContainer"
      >
        <NPSForm
          onSend={onSendNps}
          value={puntuacion}
          onChange={setPuntuacion}
          onChangeReason={setMotivo}
          productTitle={finalStepData.productTitle}
          reason={motivo}
        />
      </Grid>
      <Grid
        id="septimo-paso-conocer-mas-button"
        key={"knowMore"}
        item
        xs={12}
        mb={2.5}
        display="flex"
        justifyContent="center"
        className="titleHI"
      >
        <div
          id="btn-final-pyme"
          onClick={isCredit ? goToHomePage : goToSoySantander}
          className="knowMoreButton"
        >
          <p className="knowMoreButtonText">
            {isCredit
              ? "Conocé más sobre Santander"
              : "Conocé más sobre Soy Santander"}
          </p>
        </div>
      </Grid>
      <FooterMobile />
    </Box>
  );
};

export default NPS;
