import React, { FC, useEffect, useState } from "react";
import { getAffinityById } from "infrastructure/services";
import { ProductConfig } from "../Landing/landing-products-config";
import loaderGif from "ui/assets/icons/santander-loader-red.gif";
import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { getAccessTokenSelector } from "application/store/reducers/TokenStateReducer";

interface CardImageProps {
  productType: string;
  affinity: string;
  background?: boolean;
}

export const CardImage: FC<CardImageProps> = ({
  productType,
  affinity,
  background,
}) => {
  const [cardImageUrl, setCardImageUrl] = useState(ProductConfig[productType].image);
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = useSelector(getAccessTokenSelector);

  const isDefaultImage = cardImageUrl === ProductConfig[productType].image;


  const fetchAffinityData = async () => {
    setIsLoading(true);
    const affinityProgram = await getAffinityById(affinity);
    setCardImageUrl(affinityProgram.image);
    setIsLoading(false);
  };

  useEffect(() => {
    if (affinity && accessToken && isDefaultImage) {
      fetchAffinityData();
    }
  }, [affinity, accessToken, isDefaultImage]);

  return (
    <Box sx={styles.root}>
      {
        isLoading &&
        <Stack className={background ? "bg-white" : ""} justifyContent="center" alignItems="center" p={2}>
          <img src={loaderGif} alt="Santander" className="loaderImage" />
        </Stack>
      }
      {
        cardImageUrl && !isLoading &&
        <img
          width={201}
          height={154}
          alt="Tu Tarjeta Santander"
          className="materialboxed card-hero"
          src={cardImageUrl}
        />
      }
    </Box>
  );
};

const styles = {
  root: {

    ".materialboxed": {
      display: "block",
      cursor: "zoom-in",
      position: "relative",
      transition: "opacity .4s",
    },

    ".card-hero": {
      margin: "auto",
      paddingTop: "20px",
    },

    ".loaderImage": {
      width: "90px",
      height: "90px",
    },
    ".loaderContainer": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      position: "relative",
      top: "10px",

      "&.bg-white": {
        background: "white",
      }
    },
  }
};
