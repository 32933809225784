import axios, { AxiosError, AxiosResponse } from "axios";
import { GO_TO, navigateRef } from "infrastructure/api";
import { getSessionId } from ".";

type HeaderNames =
  | "Authorization"
  | "Content-Type"
  | "X-Santander-Client-Id"
  | "obTraceId"
  | "Recaptcha-Answer"
  | "obSessionId";

interface RestClientRequestParams {
  method: "get" | "patch" | "post" | "put" | "delete";
  url: string;
  data?: any;
  params?: any; // Data for GET requests
  ignoreErrors?: boolean;
  excludeHeaders?: HeaderNames[];
  overrideHeaders?: any;
  additionalHeaders?: Record<string, string>;
}

export class RestClient {
  static ERRORS_TO_REDIRECT = [404, 500, 503];
  static getHeaders = (excludeHeaders: HeaderNames[] = []) => {
    const allHeaders = {
      obTraceId: localStorage.getItem("onboardingId"),
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("session-id"),
      "X-Santander-Client-Id": process.env.REACT_APP_CLIENT_ID,
      obSessionId: getSessionId(),
    };

    excludeHeaders.forEach((h) => (allHeaders[h] = undefined));
    return allHeaders;
  };

  static redirect = () => {
    try {
      navigateRef.current(GO_TO.REDIRECT_ERROR, { replace: true });
    } catch (err) {
      console.log("Error during redirection", err);
    }
  };

  static handleError = (error: AxiosError) => {
    if (!error || !error.response) {
      return;
    }
    const isErrorToRedirect = RestClient.ERRORS_TO_REDIRECT.includes(
      error.response.status
    );
    if (isErrorToRedirect) {
      RestClient.redirect();
    }
  };

  static request = async <T>(
    params: RestClientRequestParams
  ): Promise<AxiosResponse<T>> => {
    let headers = RestClient.getHeaders(params.excludeHeaders);

    if (params.overrideHeaders) {
      headers = params.overrideHeaders;
    } else if (params.additionalHeaders) {
      headers = { ...headers, ...params.additionalHeaders };
    }

    const axiosParams = {
      method: params.method,
      url: params.url,
      headers,
      data: params.data,
      params: params.params,
    };
    try {
      const response = await axios(axiosParams);
      return response;
    } catch (err) {
      if (!params.ignoreErrors) {
        RestClient.handleError(err as AxiosError);
      }
      throw err;
    }
  };

  // Shorthands - if there is need to custom headers, use `request`  instead
  static get = async <T>(
    url: string,
    params: any = {}
  ): Promise<AxiosResponse<T>> => {
    return RestClient.request({
      method: "get",
      params,
      url,
    });
  };
  static post = async <T>(
    url: string,
    data: any
  ): Promise<AxiosResponse<T>> => {
    return RestClient.request({
      method: "post",
      url,
      data,
    });
  };
  static put = async <T>(url: string, data: any): Promise<AxiosResponse<T>> => {
    return RestClient.request({
      method: "put",
      url,
      data,
    });
  };
  static patch = async <T>(
    url: string,
    data: any
  ): Promise<AxiosResponse<T>> => {
    console.log('RestClient.request url: ', url)
    console.log('RestClient.request data: ', data)
    return RestClient.request({
      method: "patch",
      url,
      data,
    });
  };
  static delete = async <T>(url: string): Promise<AxiosResponse<T>> => {
    return RestClient.request({
      method: "delete",
      url,
    });
  };
  static postFormData = async <T>(
    url: string,
    form: FormData
  ): Promise<AxiosResponse<T>> => {
    const headers = RestClient.getHeaders();
    headers["Content-Type"] = "multipart/form-data";
    return RestClient.request({
      method: "post",
      url,
      data: form,
      overrideHeaders: headers,
    });
  };
}
