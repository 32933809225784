import { RootState } from "application/store/reducers/rootReducer";
import { dtoIsYes } from "./helpersDto";

export const buildKycDto = (data: RootState) => {
  const { KycPasoState: kycState } = data;
  const result = {
    foreignTaxesCountryCode: dtoIsYes(kycState.ciudadanoEstadounidense),
    isAccountHolder: dtoIsYes(kycState.titularDeLaCuenta),
    wouldHaveMajorMovements: dtoIsYes(kycState.movimientosMensualesMayores5000),
    isFinalBeneficiaryOfFonds: dtoIsYes(kycState.beneficiarioFinalDeLosFondos),
    isPublicExposedPerson: dtoIsYes(kycState.personaPublicamenteExpuesta),
    isUSCitizen: dtoIsYes(kycState.ciudadanoEstadounidense),
    isForeignNonResidentInUruguay: dtoIsYes(
      kycState.extranjeroNoResidenteEnUruguay
    ),
    isHandlerThirdPartyFonds: dtoIsYes(kycState.fondosDeTerceros),
  };

  if (!result.foreignTaxesCountryCode) delete result.foreignTaxesCountryCode;
  return result;
};
