export const styles = {
  root: {
    display: "inline-flex",
    flexDirection: "column",
    position: "relative",
    minWidth: "0px",
    padding: "0",
    margin: "0",
    borderWidth: "1px 1px 0px",
    borderBottomStyle: "initial",
    borderBottomColor: "initial",
    borderImage: "initial",
    verticalAlign: "top",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
    borderTopStyle: "solid",
    borderTopColor: "rgb(222, 237, 242)",
    borderLeftStyle: "solid",
    borderLeftColor: "rgb(222, 237, 242)",
    borderRightStyle: "solid",
    borderRightColor: "rgb(222, 237, 242)",
    width: "100%",
    "& label.Mui-focused": {
      color: "#257fa4",
      top: "10px"
    },
    "& label.MuiFormLabel-filled": {
      top: "10px"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#257fa4"
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#257fa4"
      }
    },
    "& .Mui-focused": {
      background: "white"
    },
    "& .MuiSelect-select": {
      paddingLeft: "16px",
      paddingTop: "4px",
      border: "none",
      background: "transparent",

      "& >div": {
        letterSpacing: "0.5px",
        fontWeight: "600"
      }
    },
    "& .MuiSvgIcon-root": {
      display: "none"
    },

    ".input-label": {
      fontFamily: "body2.fontFamily",
      fontSize: `12.25px`,
      fontWeight: "body2.fontWeight",
      top: "13px !important",
      letterSpacing: "0.5px"
    },

    ".icon-component": {
      width: "16px",
      position: "absolute",
      left: "14px",
      borderRadius: "50%",
      overflow: "hidden",
      height: "16px",
      marginBottom: "4.2px",

    },

    ".select-styles": {
      width: "100%",
      background: "white",
      fontFamily: "body2.fontFamily",
      fontSize: "body2.fontSize",
      position: "relative",
      textAlign: "left",
      paddingLeft: "20px",
    }
  },
  menuItemCss: {
    "&.menuItem": {
      fontFamily: "body2.fontFamily",
      fontSize: "body2.fontSize",
      textTransform: "capitalize",
      listStyleType: "none",
    },
    "&.menuItemWithDivider": {
      "&:first-of-type": {
        borderBottom: "1px solid #bbb",
        paddingBottom: "10px",
      }
    },
    "@media (hover: none)": {
      "&.Mui-selected:hover": {
        backgroundColor: "rgba(25, 118, 210, 0.08)"
      },
    },
    "&.Mui-selected": {
      backgroundColor: "rgba(25, 118, 210, 0.08)"
    },
    "&.Mui-selected:focus": {
      backgroundColor: "rgba(25, 118, 210, 0.2)"
    },
    "&.MuiInput-underline:before": {
      border: `${(props) => (props.value ? "2px solid #257FA4" : undefined)}`,
      height: "45px"
    }
  }
};