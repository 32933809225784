import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createOnboardingState, DocumentType } from "../types/types";

export enum onboardingTypes {
   GET_DOCUMENT_NUMBER = "GET_DOCUMENT_NUMBER",
   GET_DOCUMENT_TYPE = "GET_DOCUMENT_TYPE",
   GET_COUNTRY_CODE = "GET_COUNTRY_CODE",
   GET_COUNTRY_CODE_TELEF = "GET_COUNTRY_CODE_TELEF",
   GET_START_ONBOARDING = "GET_START_ONBOARDING",
   SET_START_ONBOARDING = "SET_START_ONBOARDING",
   SAVE_USER_DATA = "SAVE_USER_DATA"
}

const initialState: createOnboardingState = {
  documentType: DocumentType.CI,
  documentNumber: "",
  documentCountry: "",
  countryCodeTelef: "",
  startOnboarding: false,
  checkpoint: "",
  onboardingId: "",
  error: null,
  pending: true,
  urlParams: "",
  initialUrl: "",
  noCredit: false,
} as any;

export const onBoardingReducer = createSlice({
  name: "OnBoarding",
  initialState,
  reducers: {
    setDocumentNumber: (
      state,
      action: PayloadAction<createOnboardingState | any>
    ) => {
      return { ...state, documentNumber: action.payload.documentNumber };
    },
    setDocumentType: (
      state,
      action: PayloadAction<createOnboardingState | any>
    ) => {
      return { ...state, documentType: action.payload.documentType };
    },
    setDocumentCountry: (
      state,
      action: PayloadAction<createOnboardingState | any>
    ) => {
      return { ...state, documentCountry: action.payload.documentCountry };
    },
    setCountryCodeTelef: (
      state,
      action: PayloadAction<createOnboardingState | any>
    ) => {
      return { ...state, countryCodeTelef: action.payload.countryCodeTelef };
    },
    setStartOnBoarding: (
      state,
      action: PayloadAction<createOnboardingState | any>
    ) => {
      return { ...state, startOnboarding: action.payload.startOnboarding };
    },
    setOnBoardingIdRedux: (
      state,
      action: PayloadAction<createOnboardingState | any>
    ) => {
      return { ...state, onboardingId: action.payload.onboardingId };
    },
    setOnBoardingCheckPoint: (
      state,
      action: PayloadAction<createOnboardingState | any>
    ) => {
      return { ...state, checkpoint: action.payload.checkpoint };
    },
    setUrlParams: (
      state,
      action: PayloadAction<createOnboardingState | any>
    ) => {
      return { ...state, urlParams: action.payload.urlParams };
    },
    setInitialUrl: (
      state,
      action: PayloadAction<createOnboardingState | any>
    ) => {
      return { ...state, initialUrl: action.payload.initialUrl };
    },
    setNoCredit: (
      state,
      action: PayloadAction<createOnboardingState | any>
    ) => ({ ...state, noCredit: action.payload.noCredit }),
  },
});

export const {
  setDocumentNumber,
  setDocumentType,
  setDocumentCountry,
  setOnBoardingCheckPoint,
  setUrlParams,
  setOnBoardingIdRedux,
  setCountryCodeTelef,
  setStartOnBoarding,
  setInitialUrl,
  setNoCredit,
} = onBoardingReducer.actions;

export default onBoardingReducer.reducer;
