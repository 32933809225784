import { createSlice } from "@reduxjs/toolkit";
import { DatosCedula } from "../models/primerPaso";

export interface PrimerPasoState extends DatosCedula {
  isNewDNI?: boolean;
  isOldDNI?: boolean;
}

const initialState: PrimerPasoState = {
  codigo: "",
  serie: "",
  folio: "",
  sinChip: false,
  conChip: false,
  isNewDNI: null,
  isOldDNI: null,
};

export const PrimerPasoReducer = createSlice({
  name: "PrimerPaso",
  initialState,
  reducers: {
    setFirstStep: (state, action) => {
      return action.payload;
    }
  }
});

export const { setFirstStep } = PrimerPasoReducer.actions;

export default PrimerPasoReducer.reducer;
