import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum Modals {
  TU_PRODUCTO = "TU_PRODUCTO",
  PYMES_COMO_LO_DESCARGO = "PYMES_COMO_LO_DESCARGO",
  TERMINOS_Y_CONDICIONES = "TERMINOS_Y_CONDICIONES",
  INFORMACION_DE_PROGRAMA_AFINIDAD = "INFORMACION_DE_PROGRAMA_AFINIDAD",
  TOTEMS_LIST = "TOTEMS_LIST",
  BENEFITS = "BENEFICIOS",
  INJECT_DOCUMENTOS_IDENTIDAD = "INJECT_DOCUMENTOS_IDENTIDAD",
  NONE = "NONE",
}

export type ModalState = {
  modal: Modals;
  datos?: any;
};

const initialState: ModalState = {
  modal: Modals.NONE,
  datos: null,
};

export const ModalReducer = createSlice({
  name: "Modal",
  initialState,
  reducers: {
    OpenModal: (state, action: PayloadAction<ModalState>) => {
      return action.payload;
    },
    CloseModal: (state, action: PayloadAction<ModalState>) => {
      return initialState;
    }
  }
});

export const { OpenModal, CloseModal } = ModalReducer.actions;

export default ModalReducer.reducer;
