import * as React from "react";
import Modal from "@mui/material/Modal";
import iconCancel from "ui/assets/icons/icon-cancel.svg";
import { Box } from "@mui/material";
import { styles } from "./styles";


type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  buttonText: string;
  children: React.ReactNode;
  isBenefits?: boolean; 
};

const InfoModal: React.FC<Props> = ({
  open,
  onClose,
  title,
  buttonText,
  children,
  isBenefits=false
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.root}>
        <div>
        <div className="row">
          <p className={isBenefits ? "titleBenefits" :"title"}>{title}</p>
          {
            !isBenefits && <img className="closeIcon" onClick={onClose} src={iconCancel} alt="Cerrar" />
          }
        </div>
        {children}
        {buttonText ? (
          <div className="buttonContainer">
            <button className={isBenefits ? "buttonBenefits" : "button"} onClick={onClose}>
              <p className="buttonText">{buttonText}</p>
            </button>
          </div>
        ) : null}
        </div>
      </Box>
    </Modal>
  );
};

export default InfoModal;
