import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductType } from "../models/seleccionarProductoPaso";
import { RootState } from "./rootReducer";

export interface SeleccionarProductoState extends ProductType { }

const initialState: SeleccionarProductoState = {
  isAhorro: false,
  isCredito: false,
  isPymes: false,
  isSalaryAccount: false,
};

export const SelectProductReducer = createSlice({
  name: "SelectProduct",
  initialState,
  reducers: {
    setSelectProduct: (state, action: PayloadAction<SeleccionarProductoState>) => {
      return action.payload
    }
  }
});

export const { setSelectProduct } = SelectProductReducer.actions;

export const getProductSelector = (state: RootState) => {
  return state.SeleccionarProductoState;
};

export const getIsPymeSelector = (state: RootState) => {
  return state.SeleccionarProductoState.isPymes;
};

export const getIsSalarySelector = (state: RootState) => {
  return state.SeleccionarProductoState.isSalaryAccount;
};

export default SelectProductReducer.reducer;
