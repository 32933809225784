import { Box } from "@mui/material";
import { RootState } from "application/store/reducers/rootReducer";
import React from "react";
import { useSelector } from "react-redux";
import {
  CuentaCostos,
  CuentaHeaderInfo,
  CuentaMovimientos,
} from "ui/components/atoms/CuentaComponents";
import { PlusYoungCard } from "../CuentaJoven/PlusYoungCard";
import { PlusCard } from "../CuentaPlus/PlusCard";
import { PymeCard } from "../CuentaPyme/PymeCard";
import { BasicSalaryCard, PlusSalaryCard } from "../CuentaSueldo/SalaryCard";

type Props = {
  isCuentaJoven: Boolean;
  isSalary: Boolean;
  isPymes: Boolean;
  isPlus: Boolean;
  isAhorro: Boolean;
  productTitle: string;
};

const subTitle =
  "Únete a Santander, el banco que te ayuda a hacer realidad tus sueños.";

const TuProducto: React.FC<Props> = ({ isPymes, isCuentaJoven, isSalary, isPlus, productTitle, isAhorro }) => {
  const { documentCountry } = useSelector((state: RootState) => ({
    documentCountry: state.onboardingState.documentCountry,
  }));
  const cuentaTitle = productTitle;
  // TODO: refactor, this is not mantainable/escalalbe code, component should be agnostic of this logic
  const costos = isSalary
    ? isPlus
      ? isCuentaJoven
        ? PlusYoungCard.coste
        : PlusSalaryCard.coste
      : BasicSalaryCard.coste
    : isCuentaJoven
    ? PlusYoungCard.coste
    : isPymes
    ? PymeCard.coste
    : PlusCard.coste;

  return (
    <Box sx={styles.root}>
      <p className="titleText">{cuentaTitle}</p>
      <p className="subtitleText">{subTitle}</p>
      {isCuentaJoven && isPlus && isSalary && (
        <p className="subtitleText" style={{ fontWeight: "bold" }}>
          ¡Gratis por 3 años!
        </p>
      )}
      {isCuentaJoven && isAhorro && (
        <p className="subtitleText" style={{ fontWeight: "bold" }}>
          ¡Gratis por 3 años!
        </p>
      )}
      {!isCuentaJoven && (isAhorro || isPlus) && (
        <p className="subtitleText" style={{ fontWeight: "bold" }}>
          ¡Gratis por 1 año!
        </p>
      )}
      <CuentaHeaderInfo
        className="textCJBlack"
        texts={
          isSalary
            ? isPlus
              ? PlusSalaryCard.info
              : BasicSalaryCard.info
            : isPymes
            ? PymeCard.info
            : PlusCard.info
        }
      />
      <CuentaMovimientos
        className="textCJBlack"
        title={"Movimientos"}
        texts={
          isSalary
            ? PlusSalaryCard.movement
            : isPymes
            ? PymeCard.movement
            : PlusCard.movement
        }
      />
      <CuentaCostos className="textCJBlack" texts={costos} />
      {!isPymes && documentCountry === "858" && (
        <CuentaMovimientos
          className="textCJBlack"
          title={"Beneficios:"}
          texts={
            isSalary
              ? isPlus
                ? PlusSalaryCard.benefit
                : BasicSalaryCard.benefit
              : PlusCard.benefit
          }
          sx={{
            "span:nth-child(3)": {
              fontWeight: "bold",
              mt: "-10px",
              mb: "10px",
            },
          }}
        />
      )}
    </Box>
  );
};

export default TuProducto;

const styles = {
  root: {
    ".textCJBlack": {
      borderLeft: "4px solid black",
      paddingLeft: "10px",
      lineHeight: "20px",
      marginBottom: "10px",
      fontFamily: "body2.fontFamily",
      fontSize: "body2.fontSize",
      fontWeight: "bold",
      fontStyle: "normal",
      color: "#000000",
      alignItems: "flex-start",
      gap: 1,
    },

    ".titleText": {
      fontFamily: "h4.fontFamily",
      fontSize: "h4.fontSize",
      fontWeight: "fontWeightBold",
      fontStyle: "normal",
      textTransform: "uppercase",
      color: "#000000",
      margin: "0px",
      marginTop: "1rem",
    },

    ".subtitleText": {
      fontFamily: "body2.fontFamily",
      fontSize: "body2.fontSize",
      fontWeight: "fontWeightRegular",
      fontStyle: "normal",
      color: "#000000",
      marginBottom: "1rem",
      marginTop: "1rem",
    },
  },
};
