export const size = {
  "10": "2.625rem",
  "11": "3rem",
  "12": "3.375rem",
  "13": "3.75rem",
  "01": "0.75rem",
  "02": "0.875rem",
  "03": "1rem",
  "04": "1.125rem",
  "05": "1.25rem",
  "06": "1.5rem",
  "07": "1.75rem",
  "08": "2rem",
  "09": "2.25rem"
};