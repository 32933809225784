import { RootState } from "application/store/reducers/rootReducer";
import { BASE_URL_ONBOARDING, getOnboardingId } from ".";
import { buildSubmissionDto } from "./dtos/submissionDto";
import { RestClient } from "./rest-client";
import { MixpanelService } from "./tracker/mixpanel.service";

export const postSubmission = async (data: RootState) => {
  const onboardingId = getOnboardingId();
  if (!onboardingId) return false;

  const payload = buildSubmissionDto(data);
  const url = `${BASE_URL_ONBOARDING}/onboardings/${onboardingId}/submission`;
  try {
    MixpanelService.track("Sent submission");
    await RestClient.patch(url, payload);
    return true;
  } catch (error) {
    MixpanelService.track("Errored", {
      When: "Sending submission",
      Why: error,
    });
    return false;
  }
};
