
import { createSlice } from "@reduxjs/toolkit";
import { DatosKYC } from "../models/kycPaso";

export interface KycPasoState extends DatosKYC { }

const initialState: KycPasoState = {
  titularDeLaCuenta: null,
  beneficiarioFinalDeLosFondos: null,
  fondosDeTerceros: null,
  movimientosMensualesMayores5000: null,
  personaPublicamenteExpuesta: null,
  ciudadanoEstadounidense: null,
  extranjeroNoResidenteEnUruguay: null
};

export const KycPasoReducer = createSlice({
  name: "KYC",
  initialState,
  reducers: {
    setKYCRedux: (state, action) => {
      return action.payload;
    }
  }
});

export const { setKYCRedux } = KycPasoReducer.actions;

export default KycPasoReducer.reducer;
