import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

export const ShowErrorReducer = createSlice({
  name: "ShowError",
  initialState,
  reducers: {
    setShowErrorRedux: (state, action: PayloadAction<boolean>) => {
      return action.payload
    }
  }
});

export const { setShowErrorRedux } = ShowErrorReducer.actions;

export default ShowErrorReducer.reducer;
