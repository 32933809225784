import { Box, Grid, SxProps, Theme, Typography } from "@mui/material";
import { RootState } from "application/store/reducers/rootReducer";
import { useSelector } from "react-redux";
import ACCOUNT_DEFAULT_CARD_IMAGE from "ui/assets/images/hero/tarjeta-ca.png";
import ToolModalButton from "../toolModalButton";
import { styles } from "./styles";

type CardProps = {
  id?: string;
  title: string;
  image: string;
  children: React.ReactNode;
};

const CuentaCardContainer: React.FC<CardProps> = ({
  id,
  children,
  title,
  image,
}) => {
  return (
    <Box sx={styles.root}>
      <Grid container id={id} className="cardCJ" mt={2} mb={2}>
        <Grid container display="flex" flexDirection="row">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            mb="1rem"
          >
            <Box className="titleCJ">{title}</Box>
            <Box position="relative" width="100px">
              <div className="cardContainer">
                <img src={image} alt="Tarjeta" className="cardImg" />
              </div>
            </Box>
          </Box>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

type HeaderProps = {
  texts: string[];
  className?: string;
};

const CuentaHeaderInfo: React.FC<HeaderProps> = ({ texts, className = "" }) => {
  const classNames = className.length ? `${className} textCJ"` : "textCJ";
  return (
    <Grid item xs={12} display="flex" flexDirection="column" sx={styles.root}>
      <Box display={"flex"} flexDirection={"column"} className={classNames}>
        {texts.map((t) => (
          <span key={t}>{t}</span>
        ))}
      </Box>
    </Grid>
  );
};

type MovimientosProps = {
  texts?: string[];
  className?: string;
  title?: string;
  showToolKit?: boolean;
  sx?: SxProps<Theme>;
};

const CuentaMovimientos: React.FC<MovimientosProps> = ({
  texts = [],
  className = "",
  title = "",
  showToolKit = false,
  sx,
}) => {
  const classNames = className.length ? `${className} textCJ"` : "textCJ";

  if (!texts.length) {
    return null;
  }

  return (
    <>
      <Box sx={styles.root}>
        <Grid
          padding={"20px 0"}
          item
          xs={12}
          mb={"12px"}
          className={`subTextCJ`}
        >
          {title}
        </Grid>
        {showToolKit && (
          <Box>
            <ToolModalButton />
          </Box>
        )}
      </Box>
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="column"
        className={classNames}
        sx={sx}
      >
        {typeof texts !== "undefined" &&
          texts.map((t) => <span key={t}>{t}</span>)}
      </Grid>
    </>
  );
};

type CostosProps = {
  texts?: string[];
  className?: string;
};

const CuentaCostos: React.FC<CostosProps> = ({
  texts = [],
  className = "",
}) => {
  const classNames = className.length ? `${className} textCJ"` : "textCJ";

  if (!texts.length) {
    return null;
  }

  return (
    <>
      <Box sx={styles.root}>
        <Grid
          padding={"20px 0"}
          item
          xs={12}
          mb={"12px"}
          className={`subTextCJ`}
        >
          Costos:
        </Grid>
      </Box>
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="column"
        className={classNames}
      >
        {texts.map((t) => (
          <span key={t}>{t}</span>
        ))}
      </Grid>
    </>
  );
};

type CuentaCompleteProps = {
  id?: string;
  title: string;
  headerTexts: string[];
  movimientosTexts?: string[];
  costosTexts?: string[];
  benefitTexts?: string[];
  image?: string;
  introHeader?: string;
  children?: React.ReactNode;
};

const CuentaComplete: React.FC<CuentaCompleteProps> = ({
  id,
  title,
  headerTexts,
  movimientosTexts = [],
  costosTexts = [],
  benefitTexts,
  image = ACCOUNT_DEFAULT_CARD_IMAGE,
  introHeader,
  children,
}) => {
  const { documentCountry } = useSelector((state: RootState) => ({
    documentCountry: state.onboardingState.documentCountry,
  }));

  const { isPymes } = useSelector((state: RootState) => ({
    isPymes: state.SeleccionarProductoState.isPymes,
  }));

  const isUruguayian = documentCountry === "858";
  const isForeign = !isUruguayian;

  return (
    <CuentaCardContainer id={id} title={title} image={image}>
      {introHeader && (
        <Typography mb={2} variant="body1" sx={{ fontWeight: "bold" }}>
          {introHeader}
        </Typography>
      )}
      <CuentaHeaderInfo texts={headerTexts} />
      <CuentaMovimientos texts={movimientosTexts} title={"Movimientos"} />
      <CuentaCostos texts={costosTexts} />
      {isUruguayian && !isPymes && !!benefitTexts && (
        <CuentaMovimientos
          texts={benefitTexts}
          title={"Beneficios:"}
          showToolKit={true}
          sx={{
            "span:nth-child(3)": {
              fontWeight: "bold",
              mt: "-10px",
              mb: "10px",
            },
          }}
        />
      )}
      {isForeign && !isPymes && (
        <CuentaMovimientos
          texts={benefitTexts}
          title={"Beneficios:"}
          showToolKit={true}
          sx={{
            "span:nth-child(3)": {
              fontWeight: "bold",
              mt: "-10px",
              mb: "10px",
            },
          }}
        />
      )}

      {children}
    </CuentaCardContainer>
  );
};
export {
  CuentaHeaderInfo,
  CuentaMovimientos,
  CuentaCostos,
  CuentaComplete,
  CuentaCardContainer,
};
