import { Afinity } from "application/store/reducers/AfinidadReducer";
import { getSelectedAfinitySelector } from "application/store/reducers/AfinidadReducer";
import {
  getSelectedDeadlineSelector,
  getSelectedPackOfferSelector,
} from "application/store/reducers/packOfferReducer";
import { QuintoPasoState } from "application/store/reducers/QuintoPasoReducer";
import { RootState } from "application/store/reducers/rootReducer";
import {
  getIsPymeSelector,
  getIsSalarySelector,
  getProductSelector,
} from "application/store/reducers/SeleccionarProductoReducer";
import { useSelector } from "react-redux";
import { isMajorThan } from "../TercerPaso/helpers";
import ACCOUNT_DEFAULT_CARD_IMAGE from "ui/assets/images/hero/tarjeta-ca.png";
import PYMES_CARD_IMAGE from "ui/assets/images/TD_debito_visa_electron_basica.svg";
import { MAX_YOUNGSTER_AGE } from "application/utils/constants";
import CARD_IMAGE_BASICA from "ui/assets/images/hero/image-card-salaryaccount.svg";
import CARD_IMAGE_PLUS from "ui/assets/images/hero/tarjeta-ca.png";
import { getSalaryAccountRedux } from "application/store/reducers/salaryAccountReducer";
import { SalaryAccountValue } from "application/store/models/SalaryAccount.model";
import { PymeCard } from "../CuentaPyme/PymeCard";
import { PlusCard } from "../CuentaPlus/PlusCard";
import { BasicSalaryCard, PlusSalaryCard } from "../CuentaSueldo/SalaryCard";

export interface UseGetFinalStepDataResult {
  product: "CREDIT" | "ACCOUNT" | "PYMES" | "SALARY";
  productTitle:
  | "Cuenta Joven"
  | "Cuenta Plus"
  | "Cuenta PYME Unipersonal"
  | "Tarjeta de Crédito"
  | "Cuenta Plus Sueldo"
  | "Cuenta Joven Sueldo"
  | "Cuenta Básica Sueldo";
  productInfo: string[];
  isCuentaJoven: boolean;
  addressInfo: {
    envioDomicilio: boolean;
    sucursal: string;
    quintoPaso: QuintoPasoState;
    totem?: boolean;
  };
  selectedAfinity?: Afinity;
  cardImage: string;
}

export const useGetFinalStepData = () => {
  const isCredit = useSelector(getProductSelector).isCredito;
  const isPymes = useSelector(getIsPymeSelector);
  const isSalary = useSelector(getIsSalarySelector);
  const salary = useSelector(getSalaryAccountRedux)
  const packOffer = useSelector(getSelectedPackOfferSelector);
  const deadline = useSelector(getSelectedDeadlineSelector);
  const selectedAfinity = useSelector(getSelectedAfinitySelector);
  const isPlus = salary === SalaryAccountValue.PLUS;

  const productAccountInfo =
    isSalary ?
      isPlus ?
        PlusSalaryCard.info
        :
        BasicSalaryCard.info
      : isPymes ?
        PymeCard.info
        :
        PlusCard.info
    ;
  const {
    envioDomicilio,
    sucursal,
    totem,
    quintoPaso,
    birthdayDay,
    birthdayMonth,
    birthdayYear,
  } = useSelector((state: RootState) => ({
    envioDomicilio: state.QuintoPasoState.envioDomicilio,
    sucursal: state.QuintoPasoState.sucursal,
    quintoPaso: state.QuintoPasoState,
    birthdayDay: state.TercerPasoState.birthdayDay,
    birthdayMonth: state.TercerPasoState.birthdayMonth,
    birthdayYear: state.TercerPasoState.birthdayYear,
    totem: state.QuintoPasoState.totem,
  }));

  const result: Partial<UseGetFinalStepDataResult> = {};

  result.product = isCredit ? "CREDIT" : isPymes ? "PYMES" : "ACCOUNT";

  if (result.product === "CREDIT") {
    result.selectedAfinity = selectedAfinity;
    result.productTitle = "Tarjeta de Crédito";
    result.productInfo = [
      packOffer.affinityProgram.name,
      `${packOffer.brand.name} ${packOffer.product.name}`,
      `Fecha de cierre: ${deadline?.dayOfMonth ?? "No definido"}`,
    ];
    result.cardImage = selectedAfinity.image;
  } else {
    const isAdult = isMajorThan(
      MAX_YOUNGSTER_AGE,
      Number(birthdayYear),
      Number(birthdayMonth),
      Number(birthdayDay)
    );
    result.isCuentaJoven = !isAdult;
    result.productTitle = isPymes
      ? "Cuenta PYME Unipersonal"
      : isAdult
        ? isSalary && isPlus ? "Cuenta Plus Sueldo" : isSalary && !isPlus ? "Cuenta Básica Sueldo" : "Cuenta Plus"
        : isSalary && isPlus ? "Cuenta Joven Sueldo" : isSalary && !isPlus ? "Cuenta Básica Sueldo" : "Cuenta Joven";
    result.productInfo = productAccountInfo;
    result.cardImage = isPymes ? PYMES_CARD_IMAGE : isSalary && isPlus ? CARD_IMAGE_PLUS : isSalary && !isPlus ? CARD_IMAGE_BASICA : ACCOUNT_DEFAULT_CARD_IMAGE;
  }

  result.addressInfo = {
    envioDomicilio,
    quintoPaso,
    sucursal,
    totem,
  };

  return result;
};
