interface IconProps {
  style?: object;
  onClick?: any;
  label: string;
  iconName: string;
  icon: string;
  linkText?: string;
}

const Icon = ({
  iconName,
  label,
  icon,
  style,
  linkText,
  onClick,
  ...props
}: IconProps) => {
  return (
    <>
      <img src={icon} alt="Icono" style={style} />
      <div className="iconText">
        <strong>{iconName}</strong>
        <p>{label}</p>
        <p>
          <span
            title="Abrir modal con información relativa"
            className="linkModal"
            onClick={onClick}
          >
            {linkText ? linkText : ""}
          </span>
        </p>
      </div>
    </>
  );
};

export default Icon;
