import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";

type EmployerType = "RUT" | "CI" | "UNKNOWN";

export enum EmployerTypeValue {
  RUT = 'RUT',
  CI = 'CI',
  UNKNOWN = 'UNKNOWN',
};

export type EmployerStepReducerState = {
  // Common
  dontKnowEmployer: boolean;
  employerType?: EmployerType;
  address: string;
  departament: string;
  city: string;
  apartmentNumber: string;
  doorNumber: string;

  // Company only
  companyName: string;
  companyRut: string;

  // Person only
  personName: string;
  personSecondName: string;
  personLastName: string;
  personDocument: string;
};

const initialState: EmployerStepReducerState = {
  employerType: "UNKNOWN",
  address: "",
  companyName: "",
  companyRut: "",
  personName: "",
  personSecondName: "",
  personLastName: "",
  personDocument: "",
  departament: "",
  city: "",
  apartmentNumber: "",
  doorNumber: "",
  dontKnowEmployer: false,
};

export const employerStepSlice = createSlice({
  name: "employerStep",
  initialState,
  reducers: {
    setEmployerStepAction: (
      state,
      action: PayloadAction<EmployerStepReducerState>
    ) => {
      state.employerType = action.payload.employerType;
      if (action.payload.employerType === "RUT") {
        state.companyName = action.payload.companyName.replace(/[^a-zA-Z ]+/g, '');
        state.companyRut = action.payload.companyRut.replace(/[^0-9]+/g, '');
      } else {
        state.personName = action.payload.personName.replace(/[^a-zA-Z ]+/g, '');
        state.personSecondName = action.payload.personSecondName.replace(/[^a-zA-Z ]+/g, '');
        state.personLastName = action.payload.personLastName.replace(/[^a-zA-Z ]+/g, '');
        state.personDocument = action.payload.personDocument;
      }
      state.address = action.payload.address;
      state.departament = action.payload.departament.toUpperCase();
      state.city = action.payload.city.toUpperCase();
      state.apartmentNumber = action.payload.apartmentNumber.replace(/[^a-zA-Z 0-9]+/g, '');
      state.doorNumber = action.payload.doorNumber.replace(/[^a-zA-Z 0-9]+/g, '');
      state.dontKnowEmployer = action.payload.dontKnowEmployer;
    },
  },
});

export const { setEmployerStepAction } = employerStepSlice.actions;

export const getEmployerStepSelector = (state: RootState) =>
  state.EmployerStepState;

export default employerStepSlice.reducer;