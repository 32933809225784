import { validate_ci } from "application/utils/dnivalidation";
// ValidationDNI.ci_uy(DNI)
export function validateCedula(DNI) {
  if (validate_ci(DNI) && DNI.length > 10) {
    return true;
  }
}

export function validateSerie(serie) {
  if (serie?.length > 0) {
    return true;
  }
}

export function validateFolio(folio) {
  if (folio?.length === 6) {
    return true;
  }
}

export function validateCodigo(codigo) {
  if (codigo?.length === 6) {
    return true;
  }
}
