export type SalaryAccountType = "PLUS" | "STANDARD" | "UNKNOWN";
export enum SalaryAccountValue {
  PLUS = "PLUS",
  BASIC = "STANDARD",
}

export interface SalaryAccount {
  id: number;
  name: string;
  description: string;
  orderOfDisplay: number;
  image?: string;
  favorite: boolean;
}

export interface SalaryAccountState {
  salary: SalaryAccountType;
}
