import { RootState } from "application/store/reducers/rootReducer";
import { dtoIsYes } from "./helpersDto";

export const buildEducationDto = (data: RootState) => {
  const { CuartoPasoState: mainDataState, ActivityReducer: activityState } =
    data;
  const state = data.SeleccionarProductoState.isPymes
    ? activityState
    : mainDataState;

  return {
    isStudent: dtoIsYes(state.student),
    education: {
      universityId: state.studentCenter,
    },
  };
};
