/**
 * Create Onboarding Types
 */
export enum DocumentType {
  CI = "CI",
  CIP = "CIP",
  DNI = "DNI",
  PASSPORT = "PASSPORT",
}

export interface createOnboardingState {
  documentType: string;
  documentNumber: string;
  documentCountry: string;
  countryCodeTelef: string;
  startOnboarding: boolean;
  checkpoint: string;
  onboardingId: string;
  error: string | null;
  pending: boolean;
  urlParams: string;
  initialUrl: string;
  noCredit: boolean;
}

export type DatosEnvioRetiro = {
  ciudad?: string;
  calle?: string;
  numero?: string;
  departamento?: string;
  datosComplementarios?: string;
  guardarDomicilio?: boolean;
  sucursal?: string;
  retiroSucursal?: boolean;
  envioDomicilio?: boolean;
  totem?: boolean;
  horario?: string;
  numeroDeApartamento?: string;
};

export type DatosSelfie = {
  imgSrc?: string;
  imgCropSrc?: string;
};

export type SucursalProducto = {
  sucursal?: string;
  codigo?: string;
};

