import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUtmTags, tagsToString } from "./utm-tags.service";
import { getTagsSelector, setTagsAction } from "application/store/reducers/tagsReducer";
import { IS_TESTEABLE } from "application/utils/constants";

export const useSetUtmTags = () => {
  const dispatch = useDispatch();


  useEffect(() => {
    const tags = getUtmTags();
    if (tags.length > 0) {
      dispatch(setTagsAction(tags));
    }

    if (IS_TESTEABLE) {
      console.info('Utm Tags Set: ', tags);
    }

  }, [dispatch]);

  return [];
}

export const useGetUtmTags = () => {
  const tags = useSelector(getTagsSelector);

  if (IS_TESTEABLE) {
    console.info('Utm Tags Get: ', tags);
  }

  return tags;
}

export const useGetUtmTagsAsString = () => {
  const tags = useSelector(getTagsSelector);
  const tagsAsString = tagsToString(tags);

  if (IS_TESTEABLE) {
    console.info('Utm Tags Get: ', tags);
  }

  return tagsAsString;
}

