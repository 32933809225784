import store from "application/store/Store";
import {
  GoogleAnalyticsCategories,
  GoogleAnalyticsEvents,
  GoogleAnalyticsLabels,
} from "./ga.types";

type PossibleProducts = "tdc" | "ca" | "py" | "cs" | undefined;
type PossibleAccountTypes = "plus" | "standard" | undefined;
type PossibleNationalities = "uruguay" | "foreign" | undefined;

const { REACT_APP_ONBOARDING_ANALYTICS_ID: GA_ID } = process.env;

const GA_ENABLED = (window.location.href.includes("localhost") || window.location.href.includes("misolicitud.santander")) ? true : false;

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export class TrackerService {

  static product: PossibleProducts;
  static accountType: PossibleAccountTypes;
  static userNationality?: PossibleNationalities;
  static initialized = false;
  static debugGa = window.location.href.includes('localhost') || window.location.href.includes('debugGA');

  static setUserId(userId: string) {
    try {
      if (GA_ENABLED) {
        ga("set", "userId", userId);

      }
    } catch { }
  }

  static trackLandingDevice(params: {
    device: "desktop" | "mobile";
    fromQR: boolean;
    affinity?: string;
  }) {
    const { device, fromQR, affinity } = params;
    const deviceName =
      device === "desktop" ? "DESKTOP" : fromQR ? "MOBILE_QR" : "MOBILE";
    const eventName = `Landing_${deviceName}` as GoogleAnalyticsCategories;
    TrackerService.trackGA(eventName, affinity);
  }

  static initialize(isRetry = false) {
    setTimeout(() => {
      if (GA_ENABLED) {
        if (TrackerService.gaLoaded()) {
          ga("create", GA_ID, "auto");
          console.info("Google Analytics loaded properly");
        } else {
          TrackerService.initialized = false;

          if (!isRetry) {
            console.info("Google Analytics did not load properly");
          }
        }
      } else {
        console.log("Google Analytics not enabled because env is DEV");
      }
    }, 1000);
  }
  static gaLoaded() {
    return typeof ga === "function";
  }

  static setIsForeign(isForeign: boolean) {
    TrackerService.userNationality = isForeign ? "foreign" : "uruguay";
  }

  static getSalaryProduct() {
    let result = TrackerService.product;
    if (TrackerService.accountType) {
      result += `_${TrackerService.accountType}`;
    }
    if (TrackerService.userNationality) {
      result += `_${TrackerService.userNationality}`;
    }
    return result;
  }


  static getProduct() {
    if (TrackerService.product) {
      if (TrackerService.product === "cs") {
        return TrackerService.getSalaryProduct();
      }
      return TrackerService.product;
    }


    const { isAhorro, isCredito, isPymes, isSalaryAccount } =
      store.getState().SeleccionarProductoState;

    return isAhorro ? "ca" : isCredito ? "tdc" : isPymes ? "py" : isSalaryAccount ? "cs" : undefined;
  }

  static getHasChip() {
    return store.getState().PrimerPasoState.conChip;
  }

  static getFormEventName() {
    const productType = TrackerService.getProduct();
    if (!productType) {
      return "form";
    }

    return `form_${productType}`;
  }

  static getFinalEventName() {
    const productType = TrackerService.getProduct();
    if (productType === undefined) {
      return `Paso17_FinalOnboarding_Unknown`;
    }

    if (productType === "ca" || productType === "py") {
      const accountInfo = store.getState().AccountInformationState?.accountType;
      return `Paso17_FinalOnboarding_${accountInfo}`;
    }
    if (productType === "tdc") {
      const affinity =
        store.getState().PackOfferState?.packOffer?.affinityProgram?.name;
      return `Paso17_FinalOnboarding_${affinity}`;
    }
    if (productType === "cs") {
      const SalaryAccount =
        store.getState().PackOfferState?.packOffer?.SalaryAccountProgram?.name;
      return `Paso17_FinalOnboarding_${SalaryAccount}`;
    }
  }

  static setProduct(product: "tdc" | "ca" | "py" | "cs", accountTypeQueryParam?: "PLUS" | "STANDARD" | undefined) {
    TrackerService.product = product;
    if (product === "cs" && accountTypeQueryParam) {
      TrackerService.accountType = accountTypeQueryParam === "PLUS" ? "plus" : accountTypeQueryParam === "STANDARD" ? "standard" : undefined;
    }
  }

  static trackFinal(affinity?: string) {
    const product = TrackerService.getProduct();
    if (!product) {
      return;
    }

    const eventName = TrackerService.getFinalEventName();
    TrackerService.trackGA(eventName, `Final_${product.toUpperCase()}`);
  }

  static trackDelivery(deliveryMethod: string, guardarDomicilio: boolean) {
    const eventName = `Paso13_CI_${TrackerService.getHasChip() ? "ConChip" : "SinChip"
      }_EnvioProducto` as GoogleAnalyticsCategories;
    TrackerService.trackGA(eventName, deliveryMethod);
    if (guardarDomicilio) {
      TrackerService.trackGA("DomicilioGuardado");
    }
  }

  static trackGA(
    c?: GoogleAnalyticsCategories,
    l?: GoogleAnalyticsLabels,
    v?: string
  ) {
    try {

      const productEvent =
        TrackerService.getFormEventName() as GoogleAnalyticsEvents;



      if (!productEvent) {
        return;
      }
      TrackerService.rawEvent(productEvent, c, l, v);
      TrackerService.debugEvent(productEvent, c, l, v);
    } catch (err) {
      console.info("Error tracking GA event", err);
    }

  }

  static rawEvent(event: string, category: string, label: string, value: string) {
    if (typeof ga === "function") {
      ga("send", "event", event, category, label, value);
    } else {
      if (this.debugEvent) {
        console.log("GA not loaded");
      }
    }
    if (dataLayer) {
      dataLayer.push({
        event,
        event_category: category,
        event_label: label,
        custom_value: value,
      });

    }
  }


  static debugEvent(e: string, c?: string, l?: string, v?: string) {
    this.debugGa &&
      console.log(
        `%ce: ${e}`,
        "color: #4CAF50; font-weight: bold",
        `Category: ${c}`,
        `Label: ${l}`,
        `Value: ${v}`


      );

  }
}
