import { IS_TESTEABLE, MIXPANEL_TOKEN } from "application/utils/constants";
import ExperimentsHandler from "experiments/ExperimentsHandler";
import mixpanel from "mixpanel-browser";

export class MixpanelService {
  static initialize() {
    if (!MIXPANEL_TOKEN) {
      console.info("Mixpanel not initialized");
      return;
    }

    mixpanel.init(MIXPANEL_TOKEN, {
      debug: IS_TESTEABLE,
      ignore_dnt: true,
    });
  }

  static identify(id: string, properties?: any) {
    if (!MIXPANEL_TOKEN) {
      return;
    }

    mixpanel.identify(id);
    properties && mixpanel.people.set(properties);
  }

  static track(event: string, properties?: any, includeAsUserProps?: boolean) {
    if (!MIXPANEL_TOKEN) {
      return;
    }

    // merge experiment data in properties
    try {
      const experiments = ExperimentsHandler.getActiveExperiments();
      if (experiments) {
        properties = {
          ...properties,
          ...Object.entries(experiments).reduce(
            (acc, [experimentName, variant]) => ({
              ...acc,
              [`Experiment_${experimentName}`]: variant,
            }),
            {}
          ),
        };
      }
    } catch {}

    mixpanel.track(event, properties ?? {});
    properties && includeAsUserProps && mixpanel.people.set(properties);
  }

  static trackUser(properties = {}) {
    if (!MIXPANEL_TOKEN) {
      return;
    }

    mixpanel.people.set(properties);
  }
}
