import { RootState } from "application/store/reducers/rootReducer";
import { SubmissionPayload } from "../types/SubmissionPayload";
import { buildDeliveryDto } from "./deliveryDto";
import { buildEducationDto } from "./educationDto";
import { buildKycDto } from "./kycDto";
import { buildPersonalDataDto } from "./personalDataDto";
import { buildWorkDto } from "./workDto";

export const buildProspectDto = (
  data: RootState
): SubmissionPayload["prospect"] => {
  const personalData = buildPersonalDataDto(data);
  const kycData = buildKycDto(data);
  const deliveryData = buildDeliveryDto(data);
  const educationData = buildEducationDto(data);
  const workData = buildWorkDto(data);
  const buildDto = {
    prospectDocument: buildProspectDocument(data),
    delivery: { ...deliveryData },
    ...educationData,
    ...personalData,
    ...kycData,
    ...workData,
  };
  return buildDto;
};

const buildProspectDocument = (data: RootState) => {
  const {
    onboardingState: { documentNumber, documentCountry, documentType },
    PrimerPasoState: documentState,
  } = data;

  const vals = {
    documentTypeCode: documentType,
    documentNumber: documentNumber.replace(/\D/g, ""),
    countryCode: documentCountry,
    securityCode: documentState.codigo,
    serie: documentState.serie,
    folioNumber: documentState.folio,
    documentHasChip: documentState.conChip,
  };
  for (let k in vals) {
    if (vals.hasOwnProperty(k) && !vals[k] && k !== "documentHasChip") {
      delete vals[k];
    }
  }
  return vals;
};
