export const PlusYoungCard = {
  info: [
    "Caja de Ahorro Pesos",
    "Caja de Ahorro Dólares",
    "Tarjeta de Débito Visa Soy Santander",
  ],
  movement: [
    "Extracciones mensuales gratuitas en cajeros Banred (8)",
    "Depósitos por buzoneras sin costo",
    "Transferencias entre cuentas Santander sin costo",
  ],
  coste: [
    "Sin costo por 3 años",
    "A partir del 4to año el costo es de 60 UI por mes",
  ],
  benefit: [
    // "Programa Soy Santander",
    // "Bono bienvenida: 4.000 puntos",
    // "Bono por consumo: 2.000 puntos",
    // "15% de descuento en socios comerciales",
    "Descuentos y beneficios en todo el país",
    "Canje de Puntos Soy Santander",
    "1 punto = 1 peso",
    "Puntos de bienvenida: 2.000",
  ],
};