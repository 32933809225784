
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";

export interface Afinity {
  id: number;
  name: string;
  description: string;
  orderOfDisplay: number;
  image?: string;
  favorite: boolean;
}

export enum AfinidadTypes {
  SET_AFINIDAD = 'SET_AFINIDAD',
  SET_ALL_AFINITIES = 'SET_ALL_AFINITIES'
}

export interface AfinidadState {
  afinidad: number;
  afinities: Afinity[];
}

const initialState: AfinidadState = {
  afinidad: null,
  afinities: [],
};

export const AffinityReducer = createSlice({
  name: "Affinity",
  initialState,
  reducers: {
    setAffinity: (state, action: PayloadAction<AfinidadState | any>) => {
      return {...state, afinidad: action.payload.afinidad}
    },
    setAllAffinity: (state, action: PayloadAction<AfinidadState | any>) => {
      return { ...state, afinities: action.payload.afinities };
    }
  }
});

export const { setAffinity, setAllAffinity } = AffinityReducer.actions;

export const getAfinitiesSelector = (state: RootState) => {
  return state.AfinidadState.afinities;
}

export const getSelectedAfinitySelector = (state: RootState) => {
  if (!state.AfinidadState.afinidad || !state.AfinidadState.afinities.length) {
    return null;
  }
  return state.AfinidadState.afinities.find(afinity => afinity.id === state.AfinidadState.afinidad);
}

export default AffinityReducer.reducer;
