import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthProxyResponse } from "infrastructure/services";
import { RootState } from "./rootReducer";

export type TokenState = {
  hasToken: boolean;
  enableCheckpoint: AuthProxyResponse | null;
};

const initialState: TokenState = {
  hasToken: false,
  enableCheckpoint: null,
};

export const TokenStateReducer = createSlice({
  name: "Token",
  initialState,
  reducers: {
    setAllToken: (state, action: PayloadAction<TokenState>) => {
      return action.payload;
    }
  }
});

export const { setAllToken } = TokenStateReducer.actions;

export default TokenStateReducer.reducer;

export const getAccessTokenSelector = (state: RootState) => state.TokenState?.enableCheckpoint?.accessToken;