import { DatosSegundoPaso } from "application/store/models/segundoPaso";
import { getIsAddressStoredSelector } from "application/store/reducers/QuintoPasoReducer";
import { setShowErrorRedux } from "application/store/reducers/ShowErrorReducer";
import { setLoadingState } from 'application/store/reducers/GlobalLoadingStateReducer';
import { setImageState } from "application/store/reducers/uploadDocumentsReducer";
import store from "application/store/Store";
import { GO_TO } from "infrastructure/api";
import {
  DataOcrResponseStatus,
  getDataFromOCR,
  postAddressAfterInvoiceProcessed,
  ProcessType,
} from "infrastructure/services";
import { postSubmission } from "infrastructure/services/submissionService";
import { MixpanelService } from "infrastructure/services/tracker/mixpanel.service";
import { TrackerService } from "infrastructure/services/tracker/tracker.service";
import { useNavigate } from "react-router-dom";
import { Checkpoints } from "./CheckpointEvents";
import { setSeventhStep } from "application/store/reducers/SeptimoPasoReducer";
import {
  DNIC_FIELDS_INITIAL_STATE,
  setThirdStep,
  TercerPasoState,
} from "application/store/reducers/TercerPasoReducer";
import { setSecondStep } from "application/store/reducers/SegundoPasoReducer";

type CheckpointHandlerParams = {
  checkpoint: Checkpoints;
  navigate: ReturnType<typeof useNavigate>;
  CALLING_DNIC?: boolean;
};

const getStateAndDispatch = () => {
  return {
    state: store.getState(),
    dispatch: store.dispatch,
  };
};

const isEveryZeroes = (date: string): string =>
  date.split("").every((v) => v === "0") ? "" : date;

export const onCedulaProcessingDone = async ({
  navigate,
  CALLING_DNIC = false,
}: CheckpointHandlerParams) => {
  const { dispatch } = getStateAndDispatch();
  const { isSuccess, data, status } = await getDataFromOCR(
    ProcessType.FOR_CEDULA
  );

  if (isSuccess) {
    try {
      const nameArray = data?.name?.split(" ");
      const lastNameArray = data?.lastName?.split(" ");

      const restName = nameArray.slice(1).join(" ");
      const restLastNameArray = lastNameArray.slice(1).join(" ");

      let isComingFromDNIC = DNIC_FIELDS_INITIAL_STATE;

      const birthDate = data?.birthDate?.split("/");

      if (CALLING_DNIC) {
        isComingFromDNIC = {
          theName: !!nameArray[0],
          theSecondName: !!restName,
          theLastName: !!lastNameArray[0],
          theSecondLastName: !!restLastNameArray,
          theBirthdayDay: !!isEveryZeroes(birthDate[0]),
          theBirthdayMonth: !!isEveryZeroes(birthDate[1]),
          theBirthdayYear: !!isEveryZeroes(birthDate[2]),
          theSex: !!data?.sex,
        };
      }

      const datosTercerPaso: TercerPasoState = {
        name: nameArray[0] || "",
        secondName: restName || "",
        lastName: lastNameArray[0] || "",
        secondLastName: restLastNameArray || "",
        birthdayDay: isEveryZeroes(birthDate[0]),
        birthdayMonth: isEveryZeroes(birthDate[1]),
        birthdayYear: isEveryZeroes(birthDate[2]),
        birthdayCountryCode: "",
        residencyCountryCode: "",
        sex: data?.sex,
        phone: "",
        email: "",
        dniDueDate: {
          dueDay: "",
          dueMonth: "",
          dueYear: "",
        },
        isComingFromDNIC,
      };
      dispatch(setThirdStep(datosTercerPaso));
      navigate(GO_TO.TERCER_PASO);
      TrackerService.trackGA("Paso6.1.1_VerificaciónIdentidad");
      MixpanelService.track("Completed ID Verification");
      if (!CALLING_DNIC) {
        dispatch(setLoadingState(false));
      }
    } catch (e) {
      MixpanelService.track("Errored", {
        When: "ID Procedding",
      });
      console.error(e);
    }
  } else {
    switch (status) {
      case DataOcrResponseStatus.DATA_NO_ES_VALIDA:
        dispatch(setShowErrorRedux(true));
        // reset segundo paso
        const datos: DatosSegundoPaso = {
          documentBackImage: "",
          documentFrontImage: "",
        };
        dispatch(setSecondStep(datos));
        MixpanelService.track("Errored", {
          When: "ID Processing",
          Why: "Invalid Data",
        });
        break;
      case DataOcrResponseStatus.ONBOARDING_NO_FUE_ENVIADO:
      case DataOcrResponseStatus.IMAGEN_NO_FUE_CARGADA:
      case DataOcrResponseStatus.OBSERVACIONES:
      case DataOcrResponseStatus.EXPIRO:
        MixpanelService.track("Errored", {
          When: "ID Processing",
          Why: "OCR rejected it",
        });
        navigate({ pathname: GO_TO.SUCURSALES, search: `?finished=${true}` }, { replace: true });
        break;
      case DataOcrResponseStatus.MENOR_DE_EDAD:
        navigate({ pathname: GO_TO.SUCURSALES, search: `?finished=${true}` }, { state: { isMinor: true }, replace: true });
        MixpanelService.track("Errored", {
          When: "ID Processing",
          Why: "Not adult",
        });
        break;
      case DataOcrResponseStatus.REQUEST_MAL_FORMADO:
      case DataOcrResponseStatus.ERROR_INESPERADO:
      case DataOcrResponseStatus.ERROR_PROCESANDO_DOCUMENTO:
        navigate(GO_TO.REDIRECT_ERROR, { replace: true });
        break;
      default:
        navigate({ pathname: GO_TO.SUCURSALES, search: `?finished=${true}` }, { replace: true });
        break;
    }
    dispatch(setLoadingState(false));
  }
};

const onInvoiceProcessingDone = async ({
  navigate,
}: CheckpointHandlerParams) => {
  const { state, dispatch } = getStateAndDispatch();
  const paso7 = state.SeptimoPasoState;
  const isPymes = state.SeleccionarProductoState.isPymes;

  const { isSuccess: isValidDomicilio, status } =
    await postAddressAfterInvoiceProcessed({
      city: paso7.ciudad,
      department: paso7.departamento,
      streetName: paso7.calle,
      streetIdentification: paso7.numero,
      complementaryData: paso7.datosComplementarios,
    });

  if (isValidDomicilio) {
    TrackerService.trackGA("Paso16_VerificaciónDeDirección");
    MixpanelService.track("Completed Address Verification");
    if (isPymes) {
      navigate(GO_TO.MULTIPLE_DOCUMENTS_UPLOAD);
    } else {
      const successFinal = await postSubmission(state);
      if (successFinal) {
        // Does nothing (but leaves this way for readability).
        // Redirection will be done in CheckpointR.
      } else {
        console.error("esta fallando el /submission");
        navigate({ pathname: GO_TO.SUCURSALES, search: `?finished=${true}` }, { replace: true });
      }
    }

    dispatch(setLoadingState(false));
  } else {
    switch (status) {
      case DataOcrResponseStatus.DIRECCION_NO_ES_DOMICILIO:
        dispatch(setShowErrorRedux(true));
        // reset septimo paso
        dispatch(
          setSeventhStep({
            constanciaAMiNombre: "",
            vivoEnDomicilio: false,
          })
        );
        break;
      case DataOcrResponseStatus.ONBOARDING_NO_FUE_ENVIADO:
      case DataOcrResponseStatus.IMAGEN_NO_FUE_CARGADA:
        navigate({ pathname: GO_TO.SUCURSALES, search: `?finished=${true}` }, { replace: true });
        break;
      case DataOcrResponseStatus.REQUEST_MAL_FORMADO:
      case DataOcrResponseStatus.ERROR_INESPERADO:
      case DataOcrResponseStatus.ERROR_PROCESANDO_DOCUMENTO:
        navigate(GO_TO.REDIRECT_ERROR, { replace: true });
        break;
      default:
        if (!window.isFinalState) {
          navigate({ pathname: GO_TO.SUCURSALES, search: `?finished=${true}` }, { replace: true });
        }
        break;
    }
    dispatch(setLoadingState(false));
  }
};

const onSelfieProcessingDone = async ({ navigate }: CheckpointHandlerParams) => {
  const { state, dispatch } = getStateAndDispatch();
  const { isPymes } = state.SeleccionarProductoState;
  const isAddressStored = getIsAddressStoredSelector(state);
  const isLastPage = !isPymes && isAddressStored;

  if (isLastPage) {
    dispatch(setSeventhStep({}));
  }

  TrackerService.trackGA("Paso15_SelfiePruebadeVida");
  MixpanelService.track("Completed Selfie Verification");
  if (isPymes) {
    dispatch(setLoadingState(false));
    // SE ELIMINA LA NAVEGACION A REFERENCIAS Y SE PASA DIRECTAMENTE AL PASO SIETE
    // navigate(GO_TO.REFERRALS);
    navigate(GO_TO.SEPTIMO_PASO);
  } else {
    if (!isLastPage) {
      navigate(GO_TO.SEPTIMO_PASO);
      dispatch(setLoadingState(false));
    } else {
      const successFinal = await postSubmission(state);
      if (successFinal) {
        // Does nothing (but leaves this way for readability).
        // Redirection will be done in CheckpointR.
      } else {
        console.error("esta fallando el /submission");
        dispatch(setLoadingState(false));
        navigate({ pathname: GO_TO.SUCURSALES, search: `?finished=${true}` }, { replace: true });
      }
    }
  }
};

const onPymeDocumentProcessingDone = async ({
  navigate,
  checkpoint,
}: CheckpointHandlerParams) => {
  const { state, dispatch } = getStateAndDispatch();
  const documentStates = { ...state.UploadDocumentState };
  const fieldState: keyof typeof documentStates =
    checkpoint === Checkpoints.FILE_PROCESSING_RUT_DONE
      ? "RUT"
      : checkpoint === Checkpoints.FILE_PROCESSING_DGI_DONE
        ? "DGI"
        : "BPS";

  dispatch(setImageState({ field: fieldState, newState: "success" }));

  documentStates[fieldState] = "success";
  if (Object.values(documentStates).every((state) => state === "success")) {
    const successFinal = await postSubmission(state);
    if (successFinal) {
      // Does nothing (but leaves this way for readability).
      // Redirection will be done in CheckpointR.
    } else {
      console.error("esta fallando el /submission");
      navigate({ pathname: GO_TO.SUCURSALES, search: `?finished=${true}` }, { replace: true });
    }
  }
};

const onPymeImageError = ({ navigate, checkpoint }: CheckpointHandlerParams) => {
  const { state, dispatch } = getStateAndDispatch();
  const { UploadDocumentState: documentStates } = state;
  const fieldState: keyof typeof documentStates =
    checkpoint === Checkpoints.FILE_PROCESSING_RUT_DONE
      ? "RUT"
      : checkpoint === Checkpoints.FILE_PROCESSING_DGI_DONE
        ? "DGI"
        : "BPS";
  dispatch(setImageState({ field: fieldState, newState: "error" }));
  dispatch(setLoadingState(false));
};

const onSubmitOnboardingDone = async ({ navigate }: CheckpointHandlerParams) => {
  const { dispatch } = getStateAndDispatch();
  dispatch(setLoadingState(false));
  MixpanelService.track("Onboarding Completed");
  navigate({ pathname: GO_TO.FINAL, search: `?finished=${true}` });
  window.localStorage.setItem('finished', 'true');
};

export const CheckpointHandlers = {
  [Checkpoints.CEDULA_PROCESSING_DONE]: onCedulaProcessingDone,
  [Checkpoints.INVOICE_PROCESSING_DONE]: onInvoiceProcessingDone,
  [Checkpoints.SUBMIT_ONBOARDING_DONE]: onSubmitOnboardingDone,
  [Checkpoints.UPLOAD_SELFIE_IMAGE_DONE]: onSelfieProcessingDone,
  [Checkpoints.FILE_PROCESSING_RUT_DONE]: onPymeDocumentProcessingDone,
  [Checkpoints.FILE_PROCESSING_DGI_DONE]: onPymeDocumentProcessingDone,
  [Checkpoints.FILE_PROCESSING_BPS_DONE]: onPymeDocumentProcessingDone,
  [Checkpoints.FILE_PROCESSING_RUT_ERROR]: onPymeImageError,
  [Checkpoints.FILE_PROCESSING_DGI_ERROR]: onPymeImageError,
  [Checkpoints.FILE_PROCESSING_BPS_ERROR]: onPymeImageError,
} as const;

export const handleCheckpoint = async (params: CheckpointHandlerParams) => {
  if (CheckpointHandlers[params.checkpoint]) {
    CheckpointHandlers[params.checkpoint](params);
  }
};