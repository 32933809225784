import { Suspense } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "application/store/reducers/rootReducer";
import Loader from "ui/components/atoms/loaderModal/Loader";
import LoaderModal from "ui/components/atoms/loaderModal";
import GlobalModalContainer from "ui/components/molecules/GlobalModalContainer";
import CheckpointStatusR from "ui/components/molecules/CheckpointStatus/CheckpointStatusR";
import { TrackerService } from "infrastructure/services/tracker/tracker.service";
import { navigateRef } from "infrastructure/api";
import Routes from "./routes/IndexRouter";
import { CheckpointInjector } from "infrastructure/services/checkpoint/checkpoint.injector";
import { useSetUtmTags } from "infrastructure/services/tracker/utm-tag-hook";

if (window.location.href.indexOf('DEBUGMODE=true') > -1) {
  window.debugMode = true;
}

TrackerService.initialize();

const CheckpointComponent = CheckpointStatusR;

export default function App() {

  useSetUtmTags();

  const { hasToken } = useSelector((state: RootState) => ({
    hasToken: state.TokenState,
  }));

  const navigate = useNavigate();
  navigateRef.current = navigate;


  return (
    <>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
      </Helmet>

      <LoaderModal />
      <GlobalModalContainer />
      {hasToken && <CheckpointComponent />}
      {hasToken && <CheckpointInjector />}
      <Suspense fallback={<Loader isLoading />}>
        <Routes />
      </Suspense>
    </>
  );
}