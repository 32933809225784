import { Box } from '@mui/material';

const benefits = [
  {
    title: "Programa Soy",
    items: [
      "Acumulá puntos por: cobrar tu sueldo, transferencias, pagos digitales, dinero en tu cuenta y más. Cada punto vale $U 1 para canjear por cualquier producto o destino.",
      "No necesitás el 100% de los puntos. Pagá parte en puntos y parte con tarjeta y en cuotas.",
    ],
  },
  {
    title: "Bono de bienvenida:",
    items: ["2000 puntos por apertura de cuenta"],
  },
  {
    title: "Bono consumo:",
    items: [
      "2.000 puntos al alcanzar los primeros",
      "$U 100.000 de consumo acumulado con tu tarjeta de débito. Tendrás un plazo máximo de 12 meses para completar el consumo mínimo acumulado requerido para recibir el bono.",
    ],
  },
];

function BeneficSalary() {
  return (
    <Box sx={styles.root}>
        {
          benefits.map(benefit => {
            return (
              <div className='container' key={benefit.title}>
               <span className='titleBenfit'>{benefit.title}</span>
               {
                benefit.items.map((item, index) => (
                  <p className='text' key={index}>{item}</p>
                ))
               }
              </div>
            )
          })
        }
    </Box>
  )
}

export default BeneficSalary;

const styles = {
  root: {
    padding: "0 20px",

    ".titleBenfit": {
      fontFamily: "body2.fontFamily",
      fontSize: "body2.fontSize",
      fontWeight: "fontWeightBold",
      fontStyle: "normal",
      lineHeight: "20px",
      color: "#444444"
    },

    ".text": {
      fontFamily: "fontFamily",
      fontSize: "12px",
      fontWeight: "500",
      fontStyle: "normal",
      lineHeight: "16px",
      color: "#444444",
      margin: "0",
      padding: "0",
    },

    ".container": {
      marginBottom: "15px",
    },
  },
};