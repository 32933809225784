import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "application/store/reducers/rootReducer";
import Loader from "./Loader";

type Props = {};

const LoaderWrapper = (props: Props) => {
  const { globalLoadingState } = useSelector((state: RootState) => ({
    globalLoadingState: state.GlobalLoadingState,
  }));

  return <Loader isLoading={globalLoadingState} />;
};

export default LoaderWrapper;
