import { FC } from "react";
import { Box } from "@mui/material";
import { ProductTypeQueryParam } from "application/store/models/seleccionarProductoPaso";
import { LandingProps } from "../MobileLanding";
import Accordion from "./../../Accordion/Accordion";
import Typography from "@mui/material/Typography";
import { SantanderTheme } from "ui/theme/SantanderTheme";

const faqData = [
  {
    summary: "¿Tiene costo mi cuenta Pyme Unipersonal?",
    details:
      "¡Nuestra cuenta online es sin costo el primer año! Luego del período de exoneración, tiene un costo fijo mensual.",
  },
  {
    summary:
      "¿Quién puede acceder a esta cuenta? ¿Qué pasa si tengo otro tipo de sociedad?",
    details:
      "Cualquier Unipersonal puede abrir su cuenta online con nosotros. Por el momento, otros tipos societarios pueden concurrir a nuestra red de sucursales para su apertura de cuenta.",
  },
  {
    summary: "¿Qué necesito para abrir mi cuenta?",
    details: (
      <>
        Vas a necesitar:
        <ul>
          <li>- Cédula de identidad</li>
          <li>- Tarjeta de RUT</li>
          <li>- Comprobante de domicilio</li>
        </ul>
      </>
    ),
  },
];

const LandingFAQ: FC<LandingProps> = ({ productType }) => {
  if (productType !== ProductTypeQueryParam.PymesParam) {
    // This area is for pymes only
    return null;
  }

  return (
    <Box sx={styles.root}>
      <Typography className="title">
        ¿Tenés dudas? Despejalas
      </Typography>
      {faqData.map((accordion, index) => (
        <Accordion
          summary={accordion.summary}
          details={accordion.details}
          key={index}
        />
      ))}
    </Box>
  );
};

export default LandingFAQ;

const styles = {
  root: {
    bgcolor: "#F5F9FB",
    background:
      "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAXcAAAAoCAYAAAAFb7poAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPrSURBVHgB7d3tcdNAEMbxFQ3gDiIqIFSAqQCogFABUAGhg6QCnA5CB0cHpoNzB04F4Za7Gx8ev8nWy530/83sSHIMXzR5vFnJuur5+XkuIj9d1QIAKN3a1Y8qHrmQv3Gb70LIA0CprKt3VVXZF/EVd7Bw9crtfnRlBABQkntXbzTY9aDa964wrrlx9UkAALnSMcxnF+qP6YvVsX/lQr52m1tXb4WRDQDkxIgPdrv9g6Phngpzee3k54I+2T37p6jDdhYKQPn8RdOqutv3hkbhHoVufu7qi6trQVNW/MlZunoK+3Zru3Ynbi0dCOdvllQ8fp0c1wIgR0b2dOups8I9FYLig/iOnqDfsOLDexW2/8L82AnJSTi3Wtdhe5UcA+jX0W49dXG4p5KO/n3YTmEMYOX/EDclBfi53LmOgT8X3/HrMWMfoBtGTujWU62G+7Zwx42WXowdwy+/fnIaV7/Fh7rpanRSohD4WnPZBD6A8zXq1lOdhvu2EPYxAHL/5Y9BPqmOvE3ufOuHuZ5jHdvp+Z4LgFM9uvp2bu70Gu67JH/ex8CPgdBHl7+WzYXNGOJW/GycjrxlIezn4sOeW2uB3az4EYyRCwwe7ockF/TSuzok7L9M3hp/bnf8N6uwtclWa02ADyt8sM9lc40GmDLNI/2W6V0b2ZR1uGM6kovxfI8CU2Sk4QXTYwh3ZIegx4RYaWEEswvhjqwl36PQL8zVAozD2XfBnIpwRzF4mB1GoNW5+iGEO4qTjG1YfwAl0VC/7etGDsIdRaObRwEW4kcwVnpEuGMUkkdTE/LIhREf6kYGQLhjVELI34gP+VqA/hkZMNQjwh2jxFweA1i4unehvpQMEO4YPRZ/R4d6u/ulKcIdk0HIo0XZhnpEuGNyCHlcwLh6cPWY+7OpCHdMFmsC40Tx8d/3Q18kbYJwx+RxGyX2sOK79GxHL4cQ7kDAHTYQ36XrIhkPJXXpuxDuwA5883VyjKtfrhZjWeeBcAcOSLp5fSola8KOixG/HnKRY5djCHfgRGHlqK/CEoEls+Ln6Kb0scsxhDtwhmRsQ9Dnz4jv0BdTWuSecAcuFII+LvrN6GZ4cdH7Uc3QmyLcgRaxROBgNMy1O9c7XZYsfk+4A51KuvrXQti3yYrvzDXUs/+26BAId6AnLuhn4sc2hH0zcczyR/z83BDmxxHuwIBCZ6+BHy/MTn1mr6FtJQnzXB6hWxrCHchMEvi1+A5f92cyPlZ8iK/C1kzpbpauEe5AAZKRTtxeiQ//WDmKXbjWKtlfEuLdI9yBEQjhX4sP/1o2gX8VXoud/779fdahto9jPckmxJV24GvCe3h/AWVRYCMO5bQgAAAAAElFTkSuQmCC) #F5F9FB no-repeat top center",
    marginTop: "-10px",
    padding: "40px 16px 24px",

    ".title": {
      margin: "15px 0",
      color: "#222222",
      fontFamily: "h4.fontFamily",
      fontWeight: "fontWeightBold",
      fontSize: "h4.fontSize",
      lineHeight: "32px",
      textAlign: "center",
    },
  }
};
