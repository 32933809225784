import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type ImageState = "success" | "pending" | "error";

export interface UploadDocumentsReducerState {
  RUT: ImageState;
  DGI: ImageState;
  BPS: ImageState;
}

const initialState: UploadDocumentsReducerState = {
  RUT: "pending",
  DGI: "success",
  BPS: "success",
};

export const uploadDocumentsSlice = createSlice({
  name: "uploadDocument",
  initialState,
  reducers: {
    resetAllImageState: (state) => {
      state.RUT = "pending";
      state.DGI = "success";
      state.BPS = "success";
    },
    setImageState: (
      state,
      action: PayloadAction<{
        field: keyof UploadDocumentsReducerState;
        newState: ImageState;
      }>
    ) => {
      state[action.payload.field] = action.payload.newState;
    },
  },
});

export const { setImageState, resetAllImageState } =
  uploadDocumentsSlice.actions;

export default uploadDocumentsSlice.reducer;
