import { createRef } from "react";
import { NavigateFunction } from "react-router-dom";

export enum GO_TO {
  LANDING = "/",
  STEPS = "/steps",
  ERROR_PAGE = "*",
  NPS_EXITOSO = "/steps/success",
  NPS = "/steps/nps",
  REDIRECT_ERROR = "/steps/redirect-error",
  SUCURSALES = "/steps/error",
  FINAL = "/steps/final",
  SEPTIMO_PASO = "/steps/seventh",
  SEXTO_PASO = "/steps/sixth",
  QUINTO_PASO = "/steps/fifth",
  CUENTA_PLUS_PASO = "/steps/cuentaplus",
  CUENTA_JOVEN_PASO = "/steps/cuentajoven",
  CUENTA_PYME_PASO = "/steps/cuentapyme",
  CUENTA_SUELDO_PASO = "/steps/cuentasueldo",
  ACTIVITY_TYPE = "/steps/actividad",
  KYC_PASO = "/steps/kyc",
  KYC_PYME_PASO = "/steps/kyc-pyme",
  CUARTO_PASO = "/steps/four",
  TERCER_PASO = "/steps/third",
  SEGUNDO_PASO = "/steps/second",
  PRIMER_PASO = "/steps/first",
  PYMES_DOCUMENTATION = "/steps/pyme-documentation",
  SELECT_SALARY_ACCOUNT = "/steps/select-salary-account",
  SELECT_PRODUCT_PASO = "/steps/select-product",
  PROGRAMA_AFINIDAD = "/steps/programa-afinidad",
  SELECT_SELLO_SEGMENTO = "/steps/select-sello-segmento",
  REFERRALS = "/steps/referentes",
  MULTIPLE_DOCUMENTS_UPLOAD = "/steps/carga-documentos",
  EMPLOYER_STEP = "/steps/datos-empleador",
}

export const navigateRef = (createRef as any)() as { current: NavigateFunction};
