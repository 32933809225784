export const PlusSalaryCard = {
  info: [
    "Caja de Ahorro Pesos",
    "Caja de Ahorro Dólares",
    "Tarjeta de Débito Visa Soy Santander",
  ],
  movement: [
    "8 extracciones mensuales sin costo en cajeros Banred",
    "Depósitos por buzoneras sin costo",
    "Transferencias entre cuentas Santander sin costo",
    "8 transferencias en plaza mensuales sin costo",
  ],
  coste: ["Costo mensual 60 UI"],
  benefit: [
    // "Programa Soy Santander",
    // "Bono bienvenida: 4.000 puntos",
    // "Bono por consumo: 2.000 puntos",
    // "15% de descuento en socios comerciales",

    "Descuentos y beneficios en todo el país",
    "Canje de Puntos Soy Santander",
    "1 punto = 1 peso",
    "Puntos de bienvenida: 2.000",
  ],
};

export const BasicSalaryCard = {
  info:[
          "Caja de Ahorro Pesos",
          "Caja de Ahorro Dólares",
          "Tarjeta de Débito Visa Santander",
        ],
  movement:  [
              "8 extracciones mensuales sin costo en cajeros Banred",
              "Depósitos por buzoneras sin costo",
              "Transferencias entre cuentas Santander sin costo",
              "8 transferencias en plaza mensuales sin costo"
            ],
  coste: ["Sin costo de mantenimiento mensual"],
  benefit: [
  //   "Programa Soy Santander",
  //   "Bono bienvenida: 4.000 puntos",
  //   "Bono por consumo: 2.000 puntos",
  //   "15% de descuento en socios comerciales",
  ],
};