export const documentationList = [
  // {
  //   title: "Certificado único de DGI",
  //   content: [
  //     `· Ingresá a <a href="https://servicios.dgi.gub.uy/serviciosenlinea" target="_blank" title="Abrir servicios en línea de la web de DGI">servicios.dgi.gub.uy/serviciosenlinea</a> con tu usuario DGI o Identidad digital.`,
  //     `· Dirigite a "Servicios en línea / Certificado Único - Solicitud"`,
  //     `· Ingresá en el botón "Iniciar trámite en línea`,
  //     `· Completá los datos requeridos en el formulario web y descargá el archivo.`,
  //   ],
  // },
  // {
  //   title: "Certificado único de BPS",
  //   content: [
  //     `· Ingresá al <a href="https://serviciosenlinea.bps.gub.uy/" target="_blank" title="Abrir servicios en línea de la web de BPS">Portal de servicios en línea</a>
  //     con tu usuario y contraseña.`,
  //     `· Dirigite a  “Solicitar y descargar certificados comunes o especiales”.`,
  //     `· Descargá el archivo en formato PDF.`,
  //   ],
  // },
  {
    title: "Tarjeta RUT",
    content: [
      `· Ingresá a <a href="https://servicios.dgi.gub.uy/serviciosenlinea" target="_blank" title="Abrir servicios en línea de la web de DGI">servicios.dgi.gub.uy/serviciosenlinea</a> con tu usuario DGI o Identidad digital.`,
      `· Dirigite a “Servicios en línea”.`,
      `· Ingresá a “Registro Único Tributario, Constancia de Inscripción-Impresión” y descargá el archivo PDF.`,
    ],
  },
];
