export const TotemData = [
  {
    state: "Montevideo",
    addreses: [
      {
        address: "18 de Julio",
        details:
          "(Av. 18 de Julio 1321) - Autoservicio todos los dias de 6 a 22 hs",
      },

      {
        address: "Ciudad Vieja - 25 de mayo",
        details: "(25 de mayo 501) - Autoservicio todos los dias de 6 a 22 hs",
      },

      {
        address: "Casa Central",
        details:
          "(Av. 18 de Julio 999) - Autoservicio todos los dias de 6 a 22 hs",
      },

      {
        address: "Paso Molino",
        details:
          "(Av. Agraciada 4228) - Autoservicio lunes a viernes de 9 a 19 hs",
      },

      {
        address: "Pablo de Maria",
        details:
          "(Av. 18 de Julio 2002) - Autoservicio todos los dias de 6 a 22 hs",
      },

      {
        address: "UCUDAL",
        details:
          "(Av. 8 de Octubre 2738) - Autoservicio lunes a viernes de 7 a 23 hs, sábados de 7 a 17 hs",
      },

      {
        address: "Ombú",
        details:
          "(Br. España 2640) - Autoservicio lunes a viernes de 13 a 17 hs",
      },

      {
        address: "Puerto del Buceo",
        details:
          "(26 de Marzo 3540) - Autoservicio lunes a viernes de 13 a 17 hs",
      },

      {
        address: "Géant Nuevocentro",
        details: "- Autoservicio todos los dias de 8 a 22 hs",
      },

      {
        address: "Carrasco",
        details: "(Arocena 1577) - Autoservicio lunes a viernes de 13 a 17 hs",
      },
    ],
  },

  {
    state: "Canelones",
    addreses: [
      {
        address: "Car One",
        details:
          "(Interbalnearia esq. Cno. de los Horneros) - Autoservicio lunes a sábados de 8 a 21 hs ",
      },

      {
        address: "Géant Roosevelt",
        details: "- Autoservicio todos los dias de 8 a 22 hs",
      },
    ],
  },

  {
    state: "Maldonado",
    addreses: [
      {
        address: "Roosevelt",
        details:
          "(Av. Roosevelt y Parada 11, Punta del Este) - Autoservicio  lunes a viernes de 13 a 17 hs",
      },

      {
        address: "Península",
        details:
          "(Av. Gorlero y 25, Punta del Este) - Autoservicio todos los dias de 6 a 22 hs",
      },
    ],
  },
];
