export const PymeCard = {
  info: [
          "Cuenta Corriente Pesos",
          "Cuenta Corriente Dólares",
          "Tarjeta de Débito Visa Santander",
        ],
  movement: [
              "8 extracciones mensuales sin costo en cajeros Banred",
              "Depósitos por buzoneras sin costo",
              "Transferencias entre cuentas Santander sin costo",
            ],
  coste: [
          "Plazo de exoneración 1 año",
          "Costo mensual luego de exoneración 135 UI",
        ]
};