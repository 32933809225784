import { RestClient } from "./rest-client";

const APIGEE_URL = process.env.REACT_APP_APIGEE_URL;
const BASE_URL = APIGEE_URL;
const BASE_URL_LOG = `${BASE_URL}f1rst-log-service/v1`;

export enum LOG_LEVELS {
  INFO = "INFO",
  WARNING = "WARNING",
  ERROR = "ERROR",
  DEBUG = "DEBUG",
  TRACE = "TRACE",
}

export const SendLog = async (
  level: LOG_LEVELS,
  ...messages: string[]
): Promise<boolean> => {
  try {
    await RestClient.request<any>({
      method: "post",
      url: `${BASE_URL_LOG}/logs`,
      data: {
        level,
        message: messages.join(" "),
      },
      excludeHeaders: ["obTraceId"],
      ignoreErrors: true,
    });
    return true;
  } catch (error) {
    console.warn("Error trying to create a log entry", error);
    return false;
  }
};
