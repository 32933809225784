import { ProductTypePayload } from "infrastructure/services";

export const productTypeParamName = "productType";
export const affinityParamName = "affinity";
export const accountParamName = "accountType";
export const productDefault = "default";

export interface ProductType {
  isAhorro?: boolean;
  isCredito?: boolean;
  isPymes?: boolean;
  isSalaryAccount?: boolean;
}

export enum ProductTypeQueryParam {
  CreditParam = "OBT", // Credit Card
  AccountParam = "OBD", // Personal account
  PymesParam = "OBP", // Pymes Account
  SalaryAccount = "OBC", // Salary Account
  default = "default",
}

export const productTypeParamToPayload = (
  productTypeParam: ProductTypeQueryParam
): ProductTypePayload => {
  if (productTypeParam === ProductTypeQueryParam.CreditParam) {
    return ProductTypePayload.CREDIT_CARD;
  }
  if (productTypeParam === ProductTypeQueryParam.PymesParam) {
    //TODO: RETURN CORRECT PAYLOAD AFTER BACKEND GETS DEPLOYED
    return ProductTypePayload.ACCOUNT_PYME;
  }
  if (productTypeParam === ProductTypeQueryParam.SalaryAccount) {
    //TODO: RETURN CORRECT PAYLOAD AFTER BACKEND GETS DEPLOYED
    return ProductTypePayload.SALARY_ACCOUNT;
  }
  return ProductTypePayload.ACCOUNT;
};
