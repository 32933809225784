import { FC } from "react";
import { LandingProps } from "../MobileLanding";
import { Icon } from "ui/components/atoms/icon/Icon";
import cuentaAhorrosIcon from "ui/assets/icons/icon-savings.svg";
import tarjetaDebitoIcon from "ui/assets/icons/icon-card.svg";
import pagoDeNominaIcon from "ui/assets/icons/icon-payroll.svg";
import creditoPymeIcon from "ui/assets/icons/icon-payroll.svg";
import resumenOnlineIcon from "ui/assets/icons/icon-newsletter.svg";
import bancoOnlineIconMobile from "ui/assets/icons/icon-app-banking.svg";

import pagoServiciosIcon from "ui/assets/icons/icon-hand-moneybag.svg";
import { Box } from "@mui/material";

const LandingFeatures: FC<LandingProps> = ({ productType }) => {
  const featureList = {
    default: [
      {
        icon: tarjetaDebitoIcon,
        iconName: "Tarjeta de Débito",
        label:
          "Disfrutá de 15% de descuento todos los días en comercios adheridos.",
      },
      {
        icon: cuentaAhorrosIcon,
        iconName: "Caja de ahorros",
        label: "Cuenta bimonetaria en pesos y dólares.",
      },
      {
        icon: resumenOnlineIcon,
        iconName: "Resumen Online",
        label:
          "Consultá gratis y a cualquier hora los movimientos de tu cuenta.",
      },
      {
        icon: pagoServiciosIcon,
        iconName: "Pago de Servicios",
        label:
          "Pagá tus facturas desde las apps o adherite a débito automático.",
      },
      {
        icon: bancoOnlineIconMobile,
        iconName: "Banco online",
        label:
          "Creá tu usuario digital para acceder a tu cuenta desde donde estés a través de las apps y santander.com.uy",
      },
    ],
    pyme: [
      {
        icon: tarjetaDebitoIcon,
        iconName: "Tarjeta de Débito",
        label:
          "Disfrutá de 15% de descuento todos los días en comercios adheridos.",
      },
      {
        icon: cuentaAhorrosIcon,
        iconName: "Cuenta corriente",
        label: "Cuenta bimonetaria en pesos y dólares.",
      },
      {
        icon: resumenOnlineIcon,
        iconName: "Resumen Online",
        label:
          "Consultá gratis y a cualquier hora los movimientos de tu cuenta.",
      },
      {
        icon: pagoDeNominaIcon,
        iconName: "Pago de nómina y proveedores",
        label:
          "Accedé online y pagá tus nóminas y proveedores en un solo click.",
      },
      {
        icon: bancoOnlineIconMobile,
        iconName: "Banco online",
        label:
          "Creá tu usuario digital para acceder a tu cuenta desde donde estés a través de las apps y santander.com.uy",
      },
      {
        icon: creditoPymeIcon,
        iconName: "Crédito PYME",
        label: "Accedé a un préstamo para tu Pyme a través de tu cuenta.",
      },
    ],
  };

  let productTypeData;

  switch (productType) {
    case "OBP":
      productTypeData = "pyme";
      break;
    case "OBT":
    case "OBD":
    case "OBC":
    default:
      productTypeData = "default";
      break;
  }

  return (
    <Box sx={styles.container}>
      <Box className="row">
        <Box className="col">
          <Box className="sectionTitle">
            <span>Conocé qué tenemos para ofrecerte</span>
          </Box>
        </Box>
        {featureList[productTypeData].map((feature, index) => {
          return (
            <Box className="col santander-icon" key={index}>
              <Icon
                icon={feature.icon}
                iconName={feature.iconName}
                label={feature.label}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default LandingFeatures;

const styles = {
  container: {
    maxWidth: "1280px",
    fontSize: "fontSize",
    fontWeight: "fontWeightRegular",
    lineHeight: "21px",
    textSizeAdjust: "100%",
    margin: "0 auto",
    width: "90%",

    ".row": {
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "20px",
    },
    ".col": {
      width: "100%",
      marginLeft: "auto",
      left: "auto",
      right: "auto",
      float: "left",
      padding: "0 0.75rem",
      minHeight: "1px",
      textAlign: "center",
    },

    ".sectionTitle": {
      color: "red",
      fontFamily: "h1.fontFamily",
      fontSize: "h1.fontSize",
      fontWeight: "fontWeightBold",
      margin: "3rem 0",
      textAlign: "center",
      lineHeight: "1.5",
    },
    ".iconText": {
      marginTop: "18px",
      textAlign: "center",
    },
    ".iconText p": {
      color: "#444",
      fontFamily: "body2.fontFamily",
      fontSize: "body2.fontSize",
      fontWeight: "fontWeightRegular",
      margin: "0 auto 5px",
      width: "90%",
      textAlign: "center",
    },
    ".title": {
      color: "#444",
      fontFamily: "h6.fontFamily",
      fontSize: "1.25rem",
      fontWeight: "fontWeightBold",
      marginBottom: "20px",
      lineHeight: "30px",
    },
    ".iconColor": {
      filter: "invert(19%) sepia(75%) saturate(6700%) hue-rotate(356deg) brightness(85%) contrast(120%)",
      width: "60px",
      borderStyle: "none",
    },
    ".santander-icon": {
      padding: "2rem !important",
      textAlign: "center",
    }
  },
};
