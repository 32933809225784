import { createSlice } from "@reduxjs/toolkit";

export type GlobalLoadingState = boolean;

const initialState: GlobalLoadingState = false;

export const GlobalLoadingStateReducer = createSlice({
  name: "GlobalLoading",
  initialState,
  reducers: {
    setLoadingState: (state, action) => { 
      return action.payload
    }
  }
});

export const { setLoadingState } = GlobalLoadingStateReducer.actions;

export default GlobalLoadingStateReducer.reducer;
