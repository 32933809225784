import { createSlice } from "@reduxjs/toolkit";
import { SucursalProducto } from "./../types/types";

export type SucursalProductoState = SucursalProducto;

const initialState: SucursalProductoState = {
  codigo: "",
  sucursal: "",
};

export const SucursalProductoReducer = createSlice({
  name: "SucursalProducto",
  initialState,
  reducers: {
    setSucursalProductoRedux: (state, action) => {
      return action.payload
    }
  }
});

export const { setSucursalProductoRedux } = SucursalProductoReducer.actions;

export default SucursalProductoReducer.reducer;
