export const color = {
  error: {
    "01": "#cc0000",
    "02": "#990000",
    "03": "#fee5e5"
  },
  illustration: {
    "10": "#000000",
    "01": "#000000",
    "02": "#34466b",
    "03": "#d39e00",
    "04": "#ffe99f",
    "05": "#ffc4b8",
    "06": "#f4a48d",
    "07": "#a66555",
    "08": "#723846",
    "09": "#3b101c"
  },
  info: {
    "01": "#9bc3d3",
    "02": "#23779a",
    "03": "#f5f9fb"
  },
  neutral: {
    "10": "#575757",
    "01": "#f6f6f6",
    "02": "#ececec",
    "03": "#dadada",
    "04": "#c7c7c7",
    "05": "#b5b5b5",
    "06": "#a2a2a2",
    "07": "#8f8f8f",
    "08": "#7c7c7c",
    "09": "#6a6a6a"
  },
  primary: {
    "10": "#cedee7",
    "11": "#9bc3d3",
    "12": "#ffffff",
    "13": "#000000",
    "01": "#ec0000",
    "02": "#cc0000",
    "03": "#990000",
    "04": "#444444",
    "05": "#222222",
    "06": "#727272",
    "07": "#cccccc",
    "08": "#f0f0f0",
    "09": "#deedf2"
  },
  secondary: {
    "01": "#ffcc33",
    "02": "#63ba68",
    "03": "#1bb3bc",
    "04": "#3366ff",
    "05": "#9e3667",
    "06": "#f5f9fb"
  },
  secondaryAccessible: {
    "01": "#856300",
    "02": "#008035",
    "03": "#137e84",
    "04": "#0032e6",
    "05": "#732645",
    "06": "#23779a"
  },
  success: {
    "01": "#63ba68",
    "02": "#008035",
    "03": "#f0f8f0"
  },
  warning: {
    "01": "#ffcc33",
    "02": "#856300",
    "03": "#fffaeb"
  }
};