import { createSlice } from "@reduxjs/toolkit";
import {
  PackOffer,
  PackOfferFechaDeCierre,
  PackOfferSubsidiaryOrigin,
} from "../models/packOffer";
import { RootState } from "./rootReducer";

export interface PackOfferState {
  packOffer: PackOffer;
  packOffers: PackOffer[];
  selectedDeadline: PackOfferFechaDeCierre;
  subsidiaryOrigin: PackOfferSubsidiaryOrigin;
}

const initialState: PackOfferState = {
  packOffers: [],
  packOffer: null,
  selectedDeadline: null,
  subsidiaryOrigin: null,
};

export const PackOfferReducer = createSlice({
  name: "PackOffer",
  initialState,
  reducers: {
    setPackOffer: ( state, action ) => {
      return {
                ...state, 
                packOffer: action.payload.packOffer,
                selectedDeadline: action.payload.selectedDeadline
              }
    },
    setAllPackOffer: ( state, action ) => {
      return { ...state, packOffers: action.payload.packOffers }
    },
    setSubsidiaryOrigin: ( state, action) => {
      return { ...state, subsidiaryOrigin: action.payload.subsidiaryOrigin }; 
    }
  }
});

export const { setPackOffer , setAllPackOffer, setSubsidiaryOrigin } = PackOfferReducer.actions;

export const getSelectedPackOfferSelector = (state: RootState) => {
  return state.PackOfferState.packOffer;
};

export const getSelectedDeadlineSelector = (state: RootState) => {
  return state.PackOfferState.selectedDeadline;
};

export default PackOfferReducer.reducer;
