import { Box } from "@mui/material";
import React from "react";

type Props = {
  content?: string;
};

const ProgramaFidelidadInfo: React.FC<Props> = ({ content }) => (
  <Box sx={styles.root} onPointerMove={(e) => e.preventDefault()}>
    <p className="text">Beneficios</p>
    {/* TODO: SOLVE CONTENT FORMATING PROBLEM */}
    <div className="containerDiv">{parseTextWithLineBreaks(content)}</div>
  </Box>
);

export const parseTextWithLineBreaks = (text: string) => {
  if (!text) {
    return "";
  }
  return text
    .split("SALTO_DE_LINEA")
    .map((item, index) => <p key={index}>{item}</p>);
};

export default ProgramaFidelidadInfo;

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",

    ".containerDiv": {
      fontFamily: "body2.fontFamily",
      fontSize: "body2.fontSize",
      fontWeight: "body2.fontWeight",
      fontStyle: "normal",
      color: "#444444",
      paddingRight: "0.5rem",
      paddingLeft: "0.5rem",
      marginBottom: "0.5rem",
      marginTop: "0.7rem",
      alignSelf: "flex-start"
    },

    ".text": {
      fontFamily: "body1.fontFamily",
      fontSize: "body1.fontSize",
      fontWeight: "fontWeightBold",
      fontStyle: "normal",
      color: "#444444",
      paddingRight: "0.5rem",
      paddingLeft: "0.5rem",
      marginBottom: "0.5rem",
      marginTop: "0.7rem",
      alignSelf: "flex-start"
    }

  },
};
