import React from "react";
import { GO_TO } from "infrastructure/api";
import SantanderLogo from "../../../assets/images/logo/logo-white.svg";
import { Link } from "@mui/material";

const TitleWithLogo = () => (
  <div className="">
    <Link href={GO_TO.LANDING}>
      <img src={SantanderLogo} alt="Santander Logo" />
    </Link>
  </div>
);

export default TitleWithLogo;
