import { RootState } from "application/store/reducers/rootReducer";

export const buildPartnerDto = (data: RootState) => {
  const { CuartoPasoState: mainDataState, ActivityReducer: activityState } =
    data;
  const state = data.SeleccionarProductoState.isPymes
    ? activityState
    : mainDataState;

  return {
    name: state.partnerName,
    lastName: state.partnerLastName,
    documentType: "CI",
    documentNumber: state.partnerDocument.replace(/\D/g, ""),
  };
};
