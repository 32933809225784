import { Box, Grid } from "@mui/material";
import { ProductTypeQueryParam } from "application/store/models/seleccionarProductoPaso";
import { FC } from "react";
import { LandingProps } from "../MobileLanding";
import cedulaIcon from "ui/assets/icons/icon-pymes-ci.svg";
import bgPyme from "ui/assets/images/mob-pymes-documentation-bg.png";
import bpsDgiRutIcon from "ui/assets/icons/icon-pymes-bps-dgi-rut.svg";
import addressIcon from "ui/assets/icons/icon-pymes-address.svg";
import { useDispatch } from "react-redux";
import { Modals, OpenModal } from "application/store/reducers/ModalReducer";
import { RequiredDocumentationList } from "./pyme/RequiredDocumentationList";

const LandingInfo: FC<LandingProps> = ({ productType }) => {
  const dispatch = useDispatch();

  if (productType !== ProductTypeQueryParam.PymesParam) {
    // This area is for pymes only
    return null;
  }

  const openModal = () => {
    dispatch(
      OpenModal({
        modal: Modals.PYMES_COMO_LO_DESCARGO,
      })
    );
  };

  const pymesFeatures = [
    {
      title: "Cédula de identidad",
      content:
        "Cédula uruguaya actualizada física, puede ser con chip o sin chip.",
      icon: cedulaIcon,
    },
    {
      title: "Tarjeta de RUT",
      content: "Pueden ser impresos o en formato PDF.",
      linkText: "¿Cómo los descargo?",
      onClick: () => {
        openModal();
      },
      icon: bpsDgiRutIcon,
    },
    {
      title: "Constancias de domicilio",
      content:
        "Puede ser una factura de servicios de entes públicos (UTE, ANTEL, OSE, Movistar y Claro).",
      icon: addressIcon,
    },
  ];

  return (
    <Box sx={styles.root}>
      <Grid container direction="column">
        <Grid item >
          <h3 className="secondBoxText">Sólo necesitás tener a mano</h3>
        </Grid>
        <Grid item>
          <RequiredDocumentationList data={pymesFeatures} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingInfo;

const styles = {
  root: {
    background: `url(${bgPyme}) #F5F9FB no-repeat top center`,
    paddingTop: "40px",
    marginTop: "-10px",
    paddingBottom: 3,

    ".secondBoxText": {
      fontFamily: "h4.fontFamily",
      fontStyle: "normal",
      fontWeight: "fontWeightBold",
      fontSize: "1.5rem",
      lineHeight: "32px",
      textAlign: "center",
      color: "#444444"
    }
  }
};
