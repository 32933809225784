import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { ProductConfig, ProductConfigType } from "../landing-products-config";
import SantanderLogo from "../../../../assets/images/logo/logo-white.svg";
import SantanderLogoRed from "../../../../assets/images/logo/logo-red.svg";
import { CardImage } from "../../CardImage/CardImage";
import { LandingProps } from "../MobileLanding";

const LandingHeader: FC<LandingProps> = ({ productType, affinity }) => {
  const headerStyle = styles(ProductConfig[productType]);
  const renderCardImage = () => (
    <div className="col s12 hide-in-desktop">
      <CardImage productType={productType} affinity={affinity} />
    </div>
  );
  const config = ProductConfig[productType];

  return (
    <Box sx={headerStyle.root}>
      <Stack
        className="hero"
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <img
          className="materialboxed logoImage"
          src={config.inverseColor ? SantanderLogoRed : SantanderLogo}
          alt="Logo Santander"
        />

        <Typography
          className="title"
          dangerouslySetInnerHTML={{
            __html: config.title,
          }}
        />
        <Typography
          className="subtitle"
          fontWeight={700}
          dangerouslySetInnerHTML={{
            __html: config.subTitle,
          }}
        />
      </Stack>

      {config.hideCard === undefined && <div> {renderCardImage()}</div>}
    </Box>
  );
};

export default LandingHeader;

const styles = (productConfig: ProductConfigType) => ({
  root: {
    marginBottom: `${productConfig.isDefault ? "40px" : "24px"}`,
    boxSizing: "border-box",
    fontSize: "fontSize",
    fontWeight: "fontWeightRegular",
    lineHeight: "21px",
    textSizeAdjust: "100%",

    ".text-container": {
      paddingLeft: 2,
      maxWidth: "65%",
    },
    ".title": {
      color: productConfig.inverseColor ? "#EC0000" : "#FFFFFF",
      fontSize: productConfig.inverseColor ? "24px" : "26px",
      fontWeight: "fontWeightBold",
      lineHeight: "36px",
      marginBottom: "16px",
      mt: 1,
    },

    ".col-s12": {
      width: "100%",
      marginLeft: "auto",
      float: "left",
      boxSizing: "border-box",
      padding: "0 0.75rem",
      minHeight: "1px",
      left: "auto",
      right: "auto",
    },

    ".hide-in-desktop": {
      backgroundColor: "#ffffff",
    },

    ".subtitle": {
      color: productConfig.inverseColor ? "#727272" : "#FFFFFF",
      fontSize: 16,
      lineHeight: "24px",
    },

    ".hero": {
      boxSizing: "border-box",
      position: "relative",
      height: "284px",
      width: "100%",
      backgroundImage: `url(${productConfig.heroImage})`,
      backgroundPositionX: "right",
      backgroundPositionY: "center",
      backgroundRepeat: "no-repeat",
      backgroundColor: productConfig.inverseColor ? "#FFFFFF" : "#e10613",
      /*backgroundSize: "cover",*/
      pr: "35%",
      pl: 2,
      py: 2,
    },
    ".logoImage": {
      maxHeight: 70,
    },

    ".materialboxed": {
      cursor: "auto",
    },

    ".materialboxed &:hover &:not(.active)": {
      opacity: "1",
    },
  },
});
