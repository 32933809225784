// TODO: REFACTOR PRODUCT CONFIG

import { ProductTypeQueryParam } from "application/store/models/seleccionarProductoPaso";

import cardImgCredit from "../../../assets/images/hero/tarjeta-tdc.png";
import invisibleCardSlot from "../../../assets/images/hero/invisible-card-slot.png";

import HeroMan from "../../../assets/images/hero/mob-hero-man.png";
import CardMiddle from "../../../assets/images/hero/tarjeta-media.png";
import HeroWoman from "../../../assets/images/hero/mob-hero-woman.png";
import HeroPymes from "../../../assets/images/hero/mob-header-pymes.png";

export type ProductConfigType = {
  title: string;
  subTitle: string;
  image: string;
  heroImage: string;
  video: string;
  inverseColor?: true;
  isDefault?: boolean;
  hideCard?: boolean;
};

const getIsNoCredit = () => {
  const search = new URLSearchParams(window.location.search);
  const isNoCredit = search.get("noCredit");
  return isNoCredit === "true";
}
export const ProductConfig: Record<string, ProductConfigType> = {
  [ProductTypeQueryParam.AccountParam]: {
    title: "Abrí tu cuenta en simples pasos",
    subTitle: "¡ES GRATIS!<br />Solo necesitás tu cédula",
    image: null,
    heroImage: CardMiddle,
    video: "https://youtube.com/embed/88pO4kVtlqw",
  },
  [ProductTypeQueryParam.CreditParam]: {
    title: "Pedí tu tarjeta <br>de crédito en <br>simples pasos",
    subTitle: "¡ES GRATIS!<br />Solo necesitás tu cédula",
    image: cardImgCredit,
    heroImage: HeroWoman,
    video: "https://youtube.com/embed/88pO4kVtlqw",
  },
  [ProductTypeQueryParam.PymesParam]: {
    title: "Pedí hoy tu cuenta Pyme Unipersonal<br /> gratis por 1 año",
    subTitle: "Conocé la mejor oferta que tenemos para vos y tu negocio",
    image: invisibleCardSlot,
    heroImage: HeroPymes,
    video: "https://youtube.com/embed/-A75TrTImfo",
    inverseColor: true,
    hideCard: true,
  },
  [ProductTypeQueryParam.SalaryAccount]: {
    title: "Abrí tu cuenta sueldo en simples pasos",
    subTitle: "¡ES GRATIS!<br />Solo necesitás tu cédula y datos de tu empleador",
    image: cardImgCredit,
    heroImage: HeroMan,
    video: "https://youtube.com/embed/Kf8usBg6oDc",
    hideCard: true,
  },
  default: {
    title: getIsNoCredit() ? "Abrí tu cuenta en simples pasos" : "Pedí tu cuenta o tarjeta de crédito en simples pasos",
    subTitle: "¡ES GRATIS!<br />Solo necesitás tu cédula",
    image: invisibleCardSlot,
    heroImage: HeroWoman,
    video: "https://youtube.com/embed/88pO4kVtlqw",
    isDefault: true,
    hideCard: true,
  },
};
