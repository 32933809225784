export const goToSoySantander = () => {
  window.location.href = "https://www.soysantander.com.uy/home";
};

export const goToHomePage = () => {
  window.location.href = "https://www.santander.com.uy/";
};

export const goToPymes = () => {
  window.location.href = "https://www.santander.com.uy/pyme";
};

export const getAge = (day: string, month: string, year: string) => {
  const birthDate = new Date(Number(year), Number(month) - 1, Number(day));
  return Math.floor((new Date().getTime() - birthDate.getTime()) / 3.15576e10);
};
