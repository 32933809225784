
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";

export interface TagsState {
  tags: { tagName: string, tagValue: string }[];
}

const initialState: TagsState = {
  tags: []
};

export const TagsReducer = createSlice({
  name: "Tags",
  initialState,
  reducers: {
    setTagsAction: (state, action: PayloadAction<TagsState['tags']>) => {
      state.tags = action.payload;
    }
  }
});

export const { setTagsAction } = TagsReducer.actions;


export const getTagsSelector = (state: RootState) => state.TagsState.tags;

const tagsReducer = TagsReducer.reducer;

export default tagsReducer;

