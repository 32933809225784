import { createSlice } from "@reduxjs/toolkit";
import { DatosSelfie } from "application/store/types/types";

export type SextoPasoState = DatosSelfie;

const initialState: SextoPasoState = {
  imgSrc: "",
  imgCropSrc: "",
};

export const SextoPasoReducer = createSlice({
  name: "SextoPaso",
  initialState,
  reducers: {
    setSixthStep: (state, action) => {
      return action.payload;
    }
  }
});

export const { setSixthStep } = SextoPasoReducer.actions;

export default SextoPasoReducer.reducer;
