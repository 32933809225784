import { RootState } from "application/store/reducers/rootReducer";
import { SubmissionPayload } from "../types/SubmissionPayload";
import { buildCreditCardDto } from "./creditCardDto";
import { buildPartnerDto } from "./partnerDto";
import { buildProspectDto } from "./prospectDto";
import { buildPymesDto } from "./pymesDto";
import { buildSalaryAccountDto } from "./salaryAccountDto";

export enum ProductTypePayload {
  ACCOUNT = "ACCOUNT",
  CREDIT_CARD = "CREDIT_CARD",
  ACCOUNT_PYME = "ACCOUNT_PYME",
  SALARY_ACCOUNT = "ACCOUNT_INCOME_PF",
}

export const buildSubmissionDto = (data: RootState): SubmissionPayload => {
  const {
    SeleccionarProductoState: { isPymes, isAhorro, isCredito, isSalaryAccount },
  } = data;
  const additionalDto = isCredito
    ? buildCreditCardDto
    : isPymes
      ? buildPymesDto
      : isSalaryAccount
        ? buildSalaryAccountDto
        : (_: unknown) => { };
  const prospect = buildProspectDto(data);
  const spouse = buildPartnerDto(data);
  const productType = isAhorro
    ? ProductTypePayload.ACCOUNT
    : isPymes
      ? ProductTypePayload.ACCOUNT_PYME
      : isSalaryAccount
        ? ProductTypePayload.SALARY_ACCOUNT
        : ProductTypePayload.CREDIT_CARD;

  return {
    prospect,
    spouse,
    productType,
    ...additionalDto(data),
  };
};
