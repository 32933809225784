import { useEffect, } from "react";
import { getOnboardingId } from "..";
import { CheckpointService } from "./checkpoint.service";
import { useNavigate } from "react-router-dom";
import { handleCheckpoint } from "ui/components/molecules/CheckpointStatus/CheckpointHandlers";
import { Checkpoints } from "ui/components/molecules/CheckpointStatus/CheckpointEvents";

// 12 seconds
const INTERVAL_TO_CHECKPOINT = 12 * 1000;
// const SECONDS_TO_MANUAL_CHECKPOINT = 15;


export const CheckpointInjector = () => {

  const navigate = useNavigate();
  const timerFunction = async () => {

    if (window.localStorage.getItem('finished') === 'true') {
      return;
    }

    // If there is no onboardingId or lastLocalCheckpoint, we don't need to do anything
    const onboardingId = getOnboardingId();
    if (!onboardingId) {
      return;
    }

    // const lastEventReceived = window.localStorage.getItem("lastEventDate");
    // const secondsSinceLastEvent = (Date.now() - Number(lastEventReceived)) / 1000;
    // console.log("Seconds since last event", secondsSinceLastEvent);
    // if (secondsSinceLastEvent < SECONDS_TO_MANUAL_CHECKPOINT) {
    //   return;
    // } 


    const lastLocalCheckpoint = CheckpointService.getLastStoredCheckpoint();


    // Get the last checkpoint from the backend
    const { checkpoint: lastBackendCheckpoint } = await CheckpointService.getLastCheckpoint({
      onboardingId,
    });

    // If there is no lastBackendCheckpoint, we don't need to do anything
    if (!lastBackendCheckpoint) {
      return;
    }

    // If the lastBackendCheckpoint is different from the lastLocalCheckpoint, we need to update the lastLocalCheckpoint and handle the checkpoint
    if (lastBackendCheckpoint !== lastLocalCheckpoint) {
      CheckpointService.storeLastCheckpoint(lastBackendCheckpoint);
      handleCheckpoint(
        {
          checkpoint: lastBackendCheckpoint as Checkpoints,
          navigate,
        }
      );

      console.info("Checkpoint triggered from timer", lastBackendCheckpoint, lastLocalCheckpoint)
    } else {
      console.info("Checkpoint not triggered from timer", lastBackendCheckpoint, lastLocalCheckpoint)
    }
  }


  useEffect(() => {
    CheckpointService.resetLastStoredCheckpoint();
    const timer = setInterval(timerFunction, INTERVAL_TO_CHECKPOINT);

    return () => {
      clearInterval(timer);
    };
  }, [])

  return null;
}