import { RootState } from "application/store/reducers/rootReducer";

export const buildPersonalDataDto = (data: RootState) => {
  const {
    TercerPasoState: personalDataState,
    CuartoPasoState: mainDataState,
    SucursalProductoReducer: { codigo },
    SeleccionarProductoState: { isCredito, isPymes },
    PackOfferState: { subsidiaryOrigin },
    ActivityReducer: activityReducer,
  } = data;

  let fullFirstName = personalDataState.name;
  if (personalDataState.secondName) {
    fullFirstName += ` ${personalDataState.secondName}`;
  }

  const birthdate = `${personalDataState.birthdayDay}/${personalDataState.birthdayMonth}/${personalDataState.birthdayYear}`;
  const personalData = {
    prospectName: {
      givenName: fullFirstName,
      lastName: personalDataState.lastName,
      secondLastName: personalDataState.secondLastName,
      secondName: personalDataState.secondName,
    },
    genderCode: personalDataState.sex,
    birthdate,
    countryOfBirth: personalDataState.birthdayCountryCode,
    countryOfResidence: personalDataState.residencyCountryCode,
    emailAddress: personalDataState.email,
    mobileNumber: personalDataState.phone.replace(/\D/g, ""),
    subsidiaryOrigin: isCredito ? String(subsidiaryOrigin) : codigo,
    civilStatus: isPymes
      ? activityReducer.civilState
      : mainDataState.civilState,
    ...buildPersonalAddressDto(data),
  };

  return personalData;
};

export const buildPersonalAddressDto = (data: RootState) => {
  const { SeptimoPasoState: addressState } = data;
  const { QuintoPasoState: deliveryState } = data;

  let addressObject = addressState;
  let storedAddress = false;
  if (
    !addressState.ciudad &&
    deliveryState.ciudad &&
    deliveryState.guardarDomicilio
  ) {
    addressObject = deliveryState;
    storedAddress = true;
  }

  return {
    livesAtCertificateAddress: storedAddress || addressState.vivoEnDomicilio,
    personalAddress: {
      city: addressObject.ciudad,
      department: addressObject.departamento,
      streetName: addressObject.calle,
      streetBuildingIdentification: addressObject.numero,
      complementaryData: addressObject.datosComplementarios,
      apartmentNumber: addressObject.numeroDeApartamento,
    },
  };
};
