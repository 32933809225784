import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
type AccountTypes = "pyme" | "joven" | "plus" | undefined;
export interface AccountInformationReducerState {
  accountType: AccountTypes;
}

const initialState: AccountInformationReducerState = {
  accountType: undefined,
};

export const accountTypeSlice = createSlice({
  name: "accountInformation",
  initialState,
  reducers: {
    setAccountTypeAction: (state, action: PayloadAction<AccountTypes>) => {
      state.accountType = action.payload;
    },
  },
});

export const { setAccountTypeAction } = accountTypeSlice.actions;

export default accountTypeSlice.reducer;
