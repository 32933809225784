import { Box } from "@mui/material";
import { ProductTypeQueryParam } from "application/store/models/seleccionarProductoPaso";
import { FC } from "react";
import LandingFAQ from "./sections/LandingFAQ";
import LandingFeatures from "./sections/LandingFeatures";
import LandingFooter from "./sections/LandingFooter";
import LandingForm from "./sections/LandingForm";
import LandingHeader from "./sections/LandingHeader";
import LandingInfo from "./sections/LandingInfo";
import LandingVideo from "./sections/LandingVideo";
import bgImageFooterMovil from '../../../assets/images/footer/mobile-footer-wave.svg';

export type LandingProps = {
  productType: ProductTypeQueryParam;
  affinity?: string;
  accountType?: string;
};

const MobileLanding: FC<LandingProps> = (props) => {
  return (
    <Box display={"flex"} flexDirection={"column"} sx={styles.root}>
      <LandingHeader {...props} />
      <LandingForm {...props} />
      <LandingInfo {...props} />
      <LandingVideo {...props} />
      <LandingFeatures {...props} />
      <LandingFAQ {...props} />
      <LandingFooter {...props} />
    </Box>
  );
};

export default MobileLanding;

const styles = {
  root: {
    ".footerDivMobile": {
      backgroundRepeat: 'no-repeat',
      backgroundPositionY: 'top',
      backgroundPositionX: 'center',
      color: '#fff',
      minHeight: '200px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingTop: '48px',
      alignItems: 'center',

      backgroundImage: `url(${bgImageFooterMovil})`,

      "&.pymes": {
        backgroundColor: "#f5f9fb"
      },
    },

    ".footerDivMobile div a": {
      textDecoration: "none",
      width: "24px",
      cursor: "pointer",
    },

    ".logoSantF": {
      display: "flex",
      alignItems: "center",
    },

    ".socialIcons": {
      color: "#fff",
      textDecoration: "none",
      marginLeft: "1.3rem",
      marginRight: "1.3rem",
    },
  },
};
