import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styles } from "./styles";

export default function RadioButtonsGroup({ values, containerStyles, props }) {
  const customRadio = <Radio sx={styles.radioButtonWrapper} size="small" />;
  return (
    <RadioGroup sx={styles.radioGroupWrapper} {...props}>
      <div style={containerStyles} id="radio-internacional">
        {values.map((radioButton) => (
          <FormControlLabel
            id={radioButton?.id ? radioButton?.id : radioButton.name}
            key={radioButton.value}
            value={radioButton.value}
            control={customRadio}
            label={radioButton.name}
            sx={styles}
            disabled={props.disabled}
          />
        ))}
      </div>
    </RadioGroup>
  );
};

