import { useEffect } from "react";

interface ScrollToTopOnMoountProps {
  behavior?: "auto" | "smooth";
}

export default function ScrollToTopOnMount({
  behavior = "auto",
}: ScrollToTopOnMoountProps) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior,
    });
  }, []);

  return null;
}
