import { FC } from "react";

import { FooterMobile } from "../../footer/FooterMobile";
import { LandingProps } from "../MobileLanding";

const LandingFooter: FC<LandingProps> = ({ productType }) => {
  return <FooterMobile />;
};

export default LandingFooter;
