import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const persistConfig = {
  key: "root_v1",
  storage,
  blacklist: ["globalLoadingState", "ModalState"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
// TO-REVIEW: when REHYDRATE happens, store is reset.

const middleware = [];


const store = configureStore({
  reducer: persistedReducer,
  middleware: middleware,
});

const persistor = persistStore(store);

export default store;
export { persistor };
