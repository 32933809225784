export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function yearIsLessThan(yearUser: number, yearToCompare: number) {
  return yearUser < yearToCompare;
}

export function isMajorThan(
  age: number,
  year: number,
  month: number,
  day: number
) {
  if (!age || !year || !month || !day) {
    // throw new Error('Fecha invalida');
    console.error(age, year, month, day);
  }

  if (year < 1920) {
    return false;
  }

  return isMajorDate(age, year, month, day);
}

export function isMajorDate(
  yearsBack: number,
  year: number,
  month: number,
  day: number
) {
  const DOB = new Date(year, month - 1, day);
  const today = new Date();
  const yearRequired = today.getFullYear() - yearsBack;
  const max_dob = new Date(yearRequired, today.getMonth(), today.getDate());
  return max_dob.getTime() > DOB.getTime();
}

export function validatePhone(phone) {
  if (phone.startsWith("09")) {
    return phone.length === 11;
  } else if (phone.startsWith("9")) {
    return phone.length === 10;
  } else {
    return false;
  }
}

function validateBirthDate(userData) {
  return isValidDate(
    Number.parseInt(userData.birthdayDay),
    Number.parseInt(userData.birthdayMonth),
    Number.parseInt(userData.birthdayYear)
  );
}

export function formatDateZero(date: string, padValue: number): string {
  if (date === "") {
    return "";
  }

  if (date === "00") {
    return "";
  }

  if (date === "0") {
    return "0";
  }

  if (date === "000") {
    return "";
  }

  const dateLeadingZero = date.padStart(padValue, "0");
  return dateLeadingZero.length > padValue
    ? dateLeadingZero.slice(1, padValue + 1)
    : dateLeadingZero;
}

export function isValidDate(day: number, month: number, year: number) {
  var d = new Date(year, month - 1, day);
  const today = new Date();
  if (
    d.getFullYear() === year &&
    d.getMonth() === month - 1 &&
    d.getDate() === day &&
    d.getTime() <= today.getTime()
  ) {
    return true;
  }
  return false;
}

export function isGreaterThanToday(day: number, month: number, year: number) {
  const today = new Date();
  const date = new Date(year, month - 1, day);

  return date.getTime() > today.getTime();
}

export const isDocumentExpired = (year, month, day) => {
  const documentDate = new Date(year, month - 1, day);
  const today = new Date();
  return documentDate.getTime() < today.getTime();
};

export function isTextOnly(myString) {
  if (myString === "") {
    return true;
  }

  return /^[a-zA-Z ]+$/.test(myString);
}

export function isTextWithAccentOnly(myString) {
  const expression = /^[\p{L} ]+$/u;
  if (myString === "") {
    return true;
  }

  return expression.test(myString);
}

export function isTextWithAccentAndSpaces(myString) {
  const expression = /^[\p{L}\s]+$/u;
  if (myString === "") {
    return true;
  }

  return expression.test(myString);
}

export function validateUserData(userData) {
  let isValid = false;
  if (
    userData.name.trim().length !== 0 &&
    userData.name &&
    userData.lastName.trim().length !== 0 &&
    userData.lastName &&
    validateBirthDate(userData) &&
    userData.birthdayCountryCode &&
    userData.residencyCountryCode &&
    userData.sex &&
    userData.phone &&
    userData.email &&
    validateEmail(userData.email) &&
    validatePhone(userData.phone) &&
    isTextWithAccentOnly(userData.name) &&
    isTextWithAccentOnly(userData.secondName) &&
    isTextWithAccentAndSpaces(userData.lastName) &&
    isTextWithAccentAndSpaces(userData.secondLastName)
  ) {
    isValid = true;
  }
  return isValid;
}

export const maskPhone = (phone: string) => {
  if (!phone) {
    return "";
  }

  const phoneNumber = phone.replace(/[^0-9]/g, "");

  const phoneNumberLength = phoneNumber.length;
  const zeroSum = phoneNumberLength > 0 && phoneNumber[0] === "0" ? 1 : 0;

  if (phone.length > 10 + zeroSum) {
    return phone.slice(0, 10 + zeroSum);
  }

  // Mask phone number with format 999 999 999
  if (phoneNumberLength > 6 + zeroSum) {
    return `${phoneNumber.slice(0, 2 + zeroSum)} ${phoneNumber.slice(
      2 + zeroSum,
      5 + zeroSum
    )} ${phoneNumber.slice(5 + zeroSum, phoneNumberLength)}`;
  }
  if (phoneNumberLength > 2 + zeroSum) {
    return `${phoneNumber.slice(0, 2 + zeroSum)} ${phoneNumber.slice(
      2 + zeroSum,
      phoneNumberLength
    )}`;
  }
  return phoneNumber;
};

export const getPhoneErrors = (
  phone: string
): { error?: boolean; helperText?: string } => {
  if (!phone) {
    return {};
  }

  const invalidFirstChar =
    !phone.startsWith("9") && !phone.startsWith("09") && phone.length > 2;

  const expectedLength = phone.startsWith("0") ? 11 : 10;

  if (invalidFirstChar) {
    return {
      error: true,
      helperText: "El número debe comenzar en 09 o 9",
    };
  }

  if (phone.length < expectedLength) {
    return {
      error: true,
      helperText: "Ingresá un número valido",
    };
  }
};

export const countriesOrder = ({countries}) => {
  const [uruguay, ...restCountries] = countries;
  const listOrder = restCountries.sort((a:any, b:any) => {
                                                          if(a.name < b.name) { return -1; }
                                                          if(a.name > b.name) { return 1; }
                                                          return 0;
                                                      });
  return [uruguay, ...listOrder];    
};