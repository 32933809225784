import { RootState } from "application/store/reducers/rootReducer";
import { SubmissionPayload } from "../types/SubmissionPayload";

export const buildPymesDto = (
  data: RootState
): Record<"pyme", SubmissionPayload["pyme"]> => {
  const { ActivityReducer: activityState, ReferralsState: referraslState } =
    data;
    
  return {
    pyme: {
      economicActivity: activityState.activity,
      initialDateActivity: `${activityState.startDay}/${activityState.startMonth}/${activityState.startYear}`,
      rutNumber: activityState.rutNumber,
      references: referraslState.map((r, i) => ({
        fullName: r.name,
        address: r.address,
        mobileNumber:
          r.phone.replace(/\D/g, "")[0] === "0"
            ? r.phone.replace(/\D/g, "").substring(1)
            : r.phone.replace(/\D/g, ""),
        order: i + 1,
      })),
    },
  };
};
