export const styles = {
  root: {
    ".circleIconShadow": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "32px",
      height: "32px",
      filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.12))",
      "& :active": {
        opacity: "50%",
      },
    },

    ".circleIconContainer": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "32px",
      height: "32px",
      background: "transparent",
      borderRadius: "40px",
    },

    ".titleWrapper": {
      width: "auto",
      zIndex: "1",
    },

    ".titleText": {
      fontFamily: "body1.fontFamily",
      fontSize: "body1.fontSize",
      fontWeight: "fontWeightBold",
      fontStyle: "normal",
      color: "#257fa4",
      margin: "0.5em 0",
      lineHeight: "20px",
    },

    ".separator": {
      background: "#257fa4",
      width: "auto",
      border: "1px solid #257fa4",
      margin: "0",
    },

    ".paragrafText": {
      fontFamily: "body1.fontFamily",
      fontSize: "body1.fontSize",
      fontWeight: "fontWeightBold",
      fontStyle: "normal",
      color: "#444444",
      margin: "0.5em 0",
      lineHeight: "20px",
    },

    ".boxArrow": {
      position: "relative",
      width: "8px",
      height: "8px",
      right: "-97%",
      top: "8px",
      background: "#fff",
      borderRadius: "0px 1px",
      transform: "rotate(-45deg)",
      boxShadow: "0 3px 10px -8px rgb(0 0 0 / 0.2)",
    },

    ".bottonBoxArrow": {
      position: "relative",
      width: "8px",
      height: "8px",
      right: "-97%",
      background: "#fff",
      borderRadius: "0px 1px",
      transform: "rotate(-45deg)",
      top: "-8px",
      boxShadow: "2px -3px 10px -8px rgb(0 0 0 / 0.2)",
    },
  },
};