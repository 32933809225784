import { Box } from "@mui/material";

//Header Mobile santander
export const HeaderMobile = ({ title }) => (
  <header>
    <Box sx={styles.root}>
      <div className="containerHeader">
        {title()}
      </div>
    </Box>
  </header>
);

const styles = {
  root: {
    color: "white",
    fontSize: "calc(10px + 2vmin)",
    fontFamily: "Santander Micro Text",
    justifyContent: "left",
    height: "80px",
    background: "radial-gradient(90.4% 513.64% at 9.6% 54.55%, #ec0000 0%, #cc0000 100%)",
    display: "flex",
    alignItems: "center",

    ".containerHeader": {
      width: "90%",
      margin: "auto",
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },

    ".logoCI": {
      width: "10%",
    },

    ".titleCI div": {
      fontFamily: "body2.fontFamily",
      fontSize: "body2.fontSize",
      fontWeight: "fontWeightBold",
      fontStyle: "normal",
      color: "#ffffff",
    },
  }
};