import InstagramIcon from "@mui/icons-material/Instagram";
import { Box, SvgIcon } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import logo from "../../../assets/images/santanter-logo-white.png";
import { ProductTypeQueryParam } from "application/store/models/seleccionarProductoPaso";
import bgImageFooterMovil from '../../../assets/images/footer/mobile-footer-wave.svg';

export const FooterMobile = () => {
  const productType =
    new URLSearchParams(window.location.search).get("productType") ?? "default";
  const isPymes = productType === ProductTypeQueryParam.PymesParam;

  return (
    <Box sx={styles.root}>
      <footer className={`center footerDivMobile ${isPymes ? "pymes" : ""}`}>
        <div className="logoSantF">
          <img src={logo} alt="Santander Logo" />
        </div>
        <div style={{ marginTop: "20px" }}>
          <a href="https://www.instagram.com/santander_uy/">
            <InstagramIcon className="socialIcons" />
          </a>
          <a href="https://www.facebook.com/SantanderUruguay">
            <SvgIcon>
              <path
                fill="white"
                d="M17.3862 5.32L15.5037 5.32125C14.0275 5.32125 13.7413 6.0225 13.7413 7.05125V9.32125H17.2625L16.8038 12.8775H13.7413V22H10.07V12.8775H7V9.32125H10.07V6.7C10.07 3.65625 11.9288 2 14.6425 2C15.9425 2 17.06 2.09625 17.3862 2.14V5.32Z"
              />
            </SvgIcon>
          </a>
          <a href="https://twitter.com/santander_uy?lang=es">
            <TwitterIcon className="socialIcons" />
          </a>
        </div>
        <div className="footerText">
          Infórmese sobre la Garantía de Depósitos, en su institución de
          intermediación financiera o en el sitio web www.copab.org.uy, o en el
          correo electrónico infocopab@copab.org.uy
        </div>
      </footer>
    </Box>
  );
};

//footer red  mobile

const styles = {
    root: {
      ".footerDivMobile": {
        backgroundRepeat: 'no-repeat',
        backgroundPositionY: 'top',
        backgroundPositionX: 'center',
        color: '#fff',
        minHeight: '248px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        paddingTop: '48px',
        alignItems: 'center',
  
        backgroundImage: `url(${bgImageFooterMovil})`,
  
        "&.pymes": {
          backgroundColor: "#f5f9fb"
        },
      },
  
      ".footerDivMobile div a": {
        textDecoration: "none",
        width: "24px",
        cursor: "pointer",
      },
  
      ".logoSantF": {
        display: "flex",
        alignItems: "center",
      },
  
      ".socialIcons": {
        color: "#fff",
        textDecoration: "none",
        marginLeft: "1.3rem",
        marginRight: "1.3rem",
      },

      ".footerText": {
        fontFamily: "fontFamily",
        fontSize: "12px",
        color: 'white',
        textAlign: 'center',
        maxWidth: "400px",
        padding: "16px",
      },
    },
};
