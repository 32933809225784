import QRCode from "react-qr-code";
import { Grid, Box, Theme } from "@mui/material";

import { Footer } from "../footer/Footer";
import infoIcon from "../../../assets/images/icons/info-icon.png";
import { getSessionId } from "infrastructure/services";
import bgFooterImg from "ui/assets/images/footer/desktop-footer-wave.svg";
import logo from "../../../assets/images/footer-logo-red.png";
import { ProductTypeQueryParam } from "application/store/models/seleccionarProductoPaso";

const QRLanding = () => {
  const getQRURL = () => {
    const url = new URL(window.location.href);
    url.searchParams.append("sessionId", getSessionId());
    url.searchParams.append("fromQR", "true");
    return url.toString();
  };

  const productType =
    new URLSearchParams(window.location.search).get("productType") ?? "default";
  const isPymes = productType === ProductTypeQueryParam.PymesParam;

  return (
    <Box sx={styles.root}>
      <Grid className="contSecDes">
        <Grid className="container mAuto ">
          <div className="logoSantF">
            <img src={logo} alt="Santander Logo" />
          </div>
          <Grid container>
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7} className="topSpac">
              <Grid className="titleQR">
                Escaneá el QR para iniciar tu solicitud online desde tu celular
              </Grid>
              <Grid className="subTitleQR">
                <strong> Cómo escanear el código QR:</strong>
                <div>
                  <ul className="listTitleQR">
                    <li>1- Abrí la cámara de tu celular.</li>
                    <li>2- Apuntá hacia el código ubicandolo en el centro.</li>
                    <li>
                      3- Cuando la cámara lo detecte, te va a mostrar un link.
                    </li>
                    <li>
                      4- Hacé click <strong>y listo</strong>
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid className="containerAlertTitleQR">
                <div className="iconAlertTitleQR">
                  <img src={infoIcon} alt="info" />
                </div>
                <div className="alertTitleQR">
                  Si no podés escanear el código QR, entrá a
                  <a
                    href={
                      isPymes
                        ? "https://www.santander.com.uy/pyme"
                        : "https://www.santander.com.uy"
                    }
                  >
                    {" "}
                    {isPymes
                      ? "https://www.santander.com.uy/pyme"
                      : "https://www.santander.com.uy"}{" "}
                  </a>
                  desde tu celular.
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              className="containerImgPerson"
            >
              <Grid className="containerImgQR">
                {/* {process.env.NODE_ENV === "production" ? (
                  <img src={QR_PRODUCTION_LANDING} alt="qr" />
                ) : ( */}
                <QRCode id="QRCode" size={369} value={getQRURL()} />
                {/* )} */}
              </Grid>
              {/* <img src={imgPerson} alt="person" /> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
};

export default QRLanding;

const styles = {
  root: (theme: Theme) => ({
    width: "100%",
    display: { xs: "none", md: "block" },

    ".container": {
      margin: "0 auto",
      maxWidth: "1280px !important",
      width: "90%",
    },

    ".contSecDes": {
      backgroundColor: "#ffffff",
      borderRadius: "20px !important",
      minHeight: "87vh",
      display: "flex",
      alignItems: "center",
    },

    ".mAuto": {
      margin: "0 auto",
    },

    ".topSpac": {
      marginTop: "0px",
    },

    ".titleQR": {
      fontFamily: "Santander Headline, Verdana, sans-serif",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "48px",
      color: "#222222",
      margin: "0px 0 24px 0",
      lineHeight: "60px",
    },

    ".subTitleQR": {
      fontFamily: "Santander Micro Text, Verdana, sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      color: "#222222",
    },

    ".listTitleQR": {
      listStyle: "none",
      padding: 0,
      marginTop: "30px",
      marginBottom: "24px !important",
    },

    ".containerAlertTitleQR": {
      background: "#F5F9FB",
      height: "56px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #23779A",
      borderRadius: "4px",
      padding: "18px",
    },
    ".iconAlertTitleQR": {
      display: "flex",
      alignItems: "center",
    },

    ".alertTitleQR": {
      fontSize: "12px",
      fontWeight: "400",
      marginLeft: "10px",
    },

    ".containerImgQR": {
      width: "250px",
      height: "250px",
      "& img": {
        width: "100%",
      },
      [`${theme.breakpoints.up(1024)}`]: {
        width: "369px",
        height: "369px",

        "& svg": {
          width: "100%",
        },
      },
    },

    ".containerImgPerson": {
      display: "flex",
      justidyContent: "flex-end",
      alignItems: "center",
      width: "369px",
      height: "369px",

      [`${theme.breakpoints.up(960)}`]: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: "0px",
        flexBasis: "41.6667%",
        flexGrow: "0",
        maxWidth: "41.6667%",
      },

      "& img": {
        width: "100%",
      },
    },
    ".bgFooter": {
      backgroundImage: `url(${bgFooterImg})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "top",
      backgroundPositionX: "center",
    },

    ".footerDiv": {
      minHeight: 149,
      color: "#fff",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      maxWidth: 1440,
      margin: "0 auto",
      alignItems: "flex-start",
      paddingTop: "40px",
    },

    ".logoSantF": {
      display: "flex",
      alignItems: "center",
      marginBottom: "55px",
    },

    ".sectIconSocialDesk": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "16px",

      [`${theme.breakpoints.up(1920)}`]: {
        position: "relative",
        right: "15%",
      },
    },

    ".sectIconSocialDesk div a": {
      width: "24px",
      textDecoration: "none",
      cursor: "pointer",
    },

    ".socialIcons": {
      color: "#fff",
      textDecoration: "none",
      marginLeft: "1.347rem",
      marginRight: "1.347rem",
      width: "1.555rem",
    },

    [`${theme.breakpoints.up(576)}`]: {
      ".container": {
        maxWidth: "540px",
      },
    },

    [`${theme.breakpoints.up(768)}`]: {
      ".container": {
        maxWidth: "720px",
        width: "85%",
      },
    },

    [`${theme.breakpoints.up(992)}`]: {
      ".container": {
        maxWidth: "960px",
        width: "70%",
      },
    },

    [`${theme.breakpoints.up(1200)}`]: {
      ".container": {
        maxWidth: "1140px",
      },
    },

    [`${theme.breakpoints.up(1920)}`]: {
      ".container": {
        maxWidth: "1440px",
      },
    },
  }),
};
