import { QuintoPasoState } from "application/store/reducers/QuintoPasoReducer";
import { RootState } from "application/store/reducers/rootReducer";

export const buildDeliveryDto = (data: RootState) => {
  const { QuintoPasoState: deliveryState } = data;

  return {
    channel: getChannel(deliveryState),
    courier: deliveryState.envioDomicilio ? true : null,
    subsidiaryDelivery: deliveryState.sucursal,
    address: {
      city: deliveryState.ciudad,
      department: deliveryState.departamento,
      streetName: deliveryState.calle,
      streetBuildingIdentification: deliveryState.numero,
      complementaryData: deliveryState.datosComplementarios,
      apartmentNumber: deliveryState.numeroDeApartamento,
    },
    scheduleDelivery: deliveryState.horario,
  };
};

const getChannel = (
  data: QuintoPasoState
): "TOTEM" | "COURIER" | "SUBSIDIARY" => {
  if (data.totem) {
    return "TOTEM";
  }

  if (data.sucursal) {
    return "SUBSIDIARY";
  }

  return "COURIER";
};
