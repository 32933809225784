export const styles = {
  root: {

    ".lblContador": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    ".contLabelHM": {
      color: "#767676",
      fontSize: "12px",
      margin: "0 10px,",
      paddingLeft: "10px"
    },
    ".contNumHM": {
      color: "#257fa4",
      fontSize: "12px"
    },

    ".onboarding-input": {
      fontFamily: "body1.fontFamily",
      border: "0",
      cursor: "pointer",
      display: "inline-block",
      lineHeight: "1",
      height: "42px",
    },

    ".onboarding-input--primary": {
      color: "white"
    },

    ".onboarding-input--secondary": {
      color: "#333",
      backgroundColor: "transparent",
      boxShadow: "grey",
    },

    ".onboarding-input--small": {
      fontSize: "12px",
      padding: "10px 16px",
    },

    ".onboarding-input--medium": {
      fontSize: "body1.fontSize",
      padding: "0",
    },

    ".onboarding-input--large": {
      fontSize: "body1.fontSize",
      padding: "12px 24px",
    },
  },
};
