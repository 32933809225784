import { VFC } from "react";
import MUIAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import iconArrowDown from "ui/assets/icons/icon-arrow-down-selector.svg";

interface AccordionProps {
  summary: string;
  details: string | JSX.Element;
}

const ChevronIcon = () => <img alt="chevron" src={iconArrowDown} />;

const Accordion: VFC<AccordionProps> = ({ summary, details }) => {
  return (
    <MUIAccordion disableGutters={true} sx={styles.root} >
      <AccordionSummary expandIcon={<ChevronIcon />}>
        <Typography className="summary">{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2">{details}</Typography>
      </AccordionDetails>
    </MUIAccordion>
  );
};

export default Accordion;

const styles = {
  root: {
    backgroundColor: "transparent",
    boxShadow: "none",

    "& .MuiAccordionSummary-content": {
      margin: "12px 0 !important",
    },
    "&:before": {
      display: "none",
    },

    "&:not(:last-child)": {
      border: "none",
      borderBottom: "1px solid #CEDEE7",
      marginTop: "0 !important"
    },
    ".summary": {
      color: "#222222",
      fontFamily: "body2.fontFamily",
      fontSize: "body2.fontSize",
      fontWeight: "fontWeightBold",
      lineHeight: "1.5",
      padding: "15px 0",
      position: "relative",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
    },
  }
};
