import { Box } from "@mui/material";
import IconLanding from "./IconLanding";

export const DocumentationItem = ({
  title,
  content,
  icon,
  linkText = null,
  onClick = null,
}) => {
  return (
    <Box sx={styles.root} className="documentation-item-box-custom">
      <IconLanding
        icon={icon}
        iconName={title}
        label={content}
        linkText={linkText}
        onClick={onClick}
      />
    </Box>
  );
};

const styles = {
  root: {
    borderRadius: 4,
    background: "white",
    padding: "32px 24px",
    margin: 2,
    ".secondBoxText, .iconText strong": {
      color: "#222222",
      fontFamily: "body1.fontFamily",
    },
    ".linkModal": {
      color: "#257FA4",
      fontWeight: `bold`,
    },
    "img": {
      maxWidth: "32px",
    },
    ".iconText": {
      marginTop: "12px",
      fontFamily: "body1.fontFamily",
      "& p": {
        fontSize: `1rem`,
        margin: "10px 0 0 0",
        fontFamily: "body1.fontFamily",
      },
      "& strong": {
        fontFamily: "h6.fontFamily",
        fontSize: 'h6.fontSize',
        fontWeight: 'bold',
      },
    },
  }
};
