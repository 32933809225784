export enum Checkpoints {
  CEDULA_PROCESSING_DONE = "FILE_PROCESSING_DONE",
  INVOICE_PROCESSING_DONE = "FILE_PROCESSING_DONE_INVOICE",
  SUBMIT_ONBOARDING_APPIAN_ERROR = "SUBMIT_ONBOARDING_APPIAN_ERROR",
  INIT_ONBOARDING_APPIAN_ERROR = "INIT_ONBOARDING_APPIAN_ERROR",
  ONBOARDING_DUPLICATED = "ONBOARDING_DUPLICATED",

  INIT_ONBOARDING_DONE = "INIT_ONBOARDING_DONE",
  DNIC_VALIDATION_DONE = "DNIC_VALIDATION_DONE",
  DNIC_NOT_AVAILABLE = "DNIC_NOT_AVAILABLE",
  DNIC_IS_AVAILABLE = "DNIC_IS_AVAILABLE",
  DNIC_ERROR_701 = "DNIC_ERROR_701",
  DNIC_ERROR_702 = "DNIC_ERROR_702",
  DNIC_ERROR_703 = "DNIC_ERROR_703",
  DNIC_ERROR_704 = "DNIC_ERROR_704",
  DNIC_ERROR_705 = "DNIC_ERROR_705",

  DNIC_ERROR_1001 = "DNIC_ERROR_1001",
  DNIC_ERROR_1002 = "DNIC_ERROR_1002",
  DNIC_ERROR_1003 = "DNIC_ERROR_1003",

  UPLOAD_CI_FRONT_IMAGE_DONE = "UPLOAD_CI_FRONT_IMAGE_DONE",
  UPLOAD_CI_BACK_IMAGE_DONE = "UPLOAD_CI_BACK_IMAGE_DONE",
  UPLOAD_SELFIE_IMAGE_DONE = "UPLOAD_SELFIE_IMAGE_DONE",
  UPLOAD_ADDRESS_CERT_IMAGE_DONE = "UPLOAD_ADDRESS_CERT_IMAGE_DONE",
  UPLOAD_IMAGE_ERROR = "UPLOAD_IMAGE_ERROR",
  PROSPECT_CONTACT_INFO_UPDATED = "PROSPECT_CONTACT_INFO_UPDATED",
  INIT_ONBOARDING_IN_PROCESS = "INIT_ONBOARDING_IN_PROCESS",
  NPS_POOL_COMPLETED = "NPS_POOL_COMPLETED",
  SUBMIT_ONBOARDING_DONE = "SUBMIT_ONBOARDING_DONE",

  ERROR_OCURRED = "ERROR_OCURRED",
  RECORD_NOT_FOUND = "RECORD_NOT_FOUND",
  UNEXPECTED_APPIAN_CODE = "UNEXPECTED_APPIAN_CODE",

  FILE_PROCESSING_CI_ERROR = "FILE_PROCESSING_CI_ERROR",
  FILE_PROCESSING_INVOICE_ERROR = "FILE_PROCESSING_INVOICE_ERROR",

  FILE_PROCESSING_DONE = "FILE_PROCESSING_DONE",
  FILE_PROCESSING_DONE_INVOICE = "FILE_PROCESSING_DONE_INVOICE",

  FILE_PROCESSING_SELFIE_ERROR = "FILE_PROCESSING_SELFIE_ERROR",
  FILE_PROCESSING_DGI_DONE = "FILE_PROCESSING_DGI_DONE",
  FILE_PROCESSING_BPS_DONE = "FILE_PROCESSING_BPS_DONE",
  FILE_PROCESSING_RUT_DONE = "FILE_PROCESSING_RUT_DONE",
  FILE_PROCESSING_BPS_ERROR = "FILE_PROCESSING_BPS_ERROR",
  FILE_PROCESSING_RUT_ERROR = "FILE_PROCESSING_RUT_ERROR",
  FILE_PROCESSING_DGI_ERROR = "FILE_PROCESSING_DGI_ERROR",
}

export const ERRORS_TO_REDIRECT = [
  Checkpoints.UNEXPECTED_APPIAN_CODE,
  Checkpoints.RECORD_NOT_FOUND,
  Checkpoints.INIT_ONBOARDING_APPIAN_ERROR,
  Checkpoints.SUBMIT_ONBOARDING_APPIAN_ERROR,
  Checkpoints.FILE_PROCESSING_CI_ERROR,
  Checkpoints.FILE_PROCESSING_INVOICE_ERROR,
];
