import { SalaryAccountValue } from "application/store/models/SalaryAccount.model";
import { EmployerTypeValue } from "application/store/reducers/employerStepReducer";
import { RootState } from "application/store/reducers/rootReducer";
import { SubmissionPayload } from "../types/SubmissionPayload";

export const buildSalaryAccountDto = (
  data: RootState
): Record<"accountIncome", SubmissionPayload["accountIncome"]> => {
  const { SalaryAccountState, EmployerStepState } =
    data;
  if (EmployerStepState.employerType === EmployerTypeValue.UNKNOWN) {
    return {
      accountIncome: {
        accountIncomeType: (SalaryAccountState.salary === SalaryAccountValue.PLUS) ? SalaryAccountValue.PLUS : SalaryAccountValue.BASIC,
        companyDocumentNumber: '',
        companyDocumentType: EmployerStepState.employerType,
        companyFirstLastName: '',
        companyName: '',
        companySecondSurname: '',
        isPhysicalPerson: false,
        companyAddress: {
          companyCity: '',
          companyDepartment: '',
          companyStreetName: '',
          companyStreetBuildingIdentification: '',
          companyApartmentNumber: '',
        },
      }
    };
  }
  return {
    accountIncome: {
      accountIncomeType: (SalaryAccountState.salary === SalaryAccountValue.PLUS) ? SalaryAccountValue.PLUS : SalaryAccountValue.BASIC,
      companyDocumentNumber: (EmployerStepState.employerType === EmployerTypeValue.RUT) ? EmployerStepState.companyRut.replace(/\D/g, "") : EmployerStepState.personDocument.replace(/\D/g, ""),
      companyDocumentType: EmployerStepState.employerType,
      companyFirstLastName: EmployerStepState.personSecondName,
      companyName: (EmployerStepState.employerType === EmployerTypeValue.RUT) ? EmployerStepState.companyName : EmployerStepState.personName,
      companySecondSurname: EmployerStepState.personLastName,
      isPhysicalPerson: (EmployerStepState.employerType === EmployerTypeValue.RUT) ? false : true,
      companyAddress: {
        companyCity: EmployerStepState.city.toUpperCase(),
        companyDepartment: EmployerStepState.departament.toUpperCase(),
        companyStreetName: EmployerStepState.address,
        companyStreetBuildingIdentification: EmployerStepState.doorNumber,
        companyApartmentNumber: EmployerStepState.apartmentNumber,
      },
    }
  }
};