import { Box } from "@mui/material";

interface IconProps {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  style?: object;
  onClick?: any;
  /**
   * Icon contents
   */
  label: string;
  iconName: string;
  className?: string;
  icon: string;
}

/**
 * Primary UI component for user interaction
 */
export const Icon = ({
  primary = false,
  iconName,
  className = "iconColor",
  label,
  icon,
  style,
  onClick,
  ...props
}: IconProps) => {
  return (
    <Box sx={styles.root}>
      <img
        src={icon}
        alt="Icono"
        className={className}
        style={style}
        onClick={onClick}
      />
      <div className="iconText">
        <div className="title">{iconName}</div>
        <p>{label}</p>
      </div>
    </Box>
  );
};

const styles = {
  root: {

    ".iconColor": {
      filter: "invert(19%) sepia(75%) saturate(6700%) hue-rotate(356deg) brightness(85%) contrast(120%)",
      width: "60px"
    },
    
    ".iconGray": {
      filter: "invert(52%) sepia(0%) saturate(1198%) hue-rotate(224deg) brightness(88%) contrast(94%)",
      width: "60px"
    },

    ".iconText": {
      marginTop: "18px",
    },
     
    ".iconText .title": {
      fontFamily: "h6.fontFamily",
      fontSize: "1.25rem",
      color: "#444444",
      fontWeight: "fontWeightBold",
      marginBottom: "20px"
    },

    ".iconText p": {
      color: "#444",
      fontFamily: "body1.fontFamily",
      fontSize: "body1.fontSize",
      fontWeight: "body1.fontWeight",
      margin: "0 auto 5px",
      width: "90%",
      textAlign: "center",
    },
  },
};
