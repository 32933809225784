import { BASE_URL_ONBOARDING } from "..";
import { RestClient } from "../rest-client";
import { GetLastCheckpointRequest, GetLastCheckpointResponse } from "./checkpoint.types";

const LAST_CHECKPOINT_STORAGE_KEY = "lastCheckpoint";

export class CheckpointService {

  restService = RestClient;


  static getUrl(onboardingId: string) {
    return `${BASE_URL_ONBOARDING}/onboardings/${onboardingId}`;
  }

  static resetLastStoredCheckpoint() {
    localStorage.removeItem(LAST_CHECKPOINT_STORAGE_KEY);
  }

  static async getLastCheckpoint(req: GetLastCheckpointRequest) {
    const { data } = await RestClient.get<GetLastCheckpointResponse>(CheckpointService.getUrl(req.onboardingId));
    return data;
  }

  static storeLastCheckpoint(checkpointEvent: string) {
    localStorage.setItem(LAST_CHECKPOINT_STORAGE_KEY, checkpointEvent);
  }

  static getLastStoredCheckpoint() {
    return localStorage.getItem(LAST_CHECKPOINT_STORAGE_KEY);
  }
}

