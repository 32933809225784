import * as React from "react";
import infoIcon from "ui/assets/icons/icon-information-circle.svg";
import { styles } from "./styles";
import { Box } from "@mui/material";
import { Modals, OpenModal } from "application/store/reducers/ModalReducer";
import { useDispatch } from "react-redux";

function ToolModalButton() {

  const dispatch = useDispatch();

  const openModal = () =>
    dispatch(
      OpenModal({
        modal: Modals.BENEFITS,
      })
    );

  return (
      <Box sx={styles.root}>
      <div onClick={openModal}>
            <div className="circleIconShadow">
              <div className="circleIconContainer">
                <img src={infoIcon} alt="Icono Información" />
              </div>
            </div>
          </div>
      </Box>
  );
};

export default ToolModalButton