import { createSlice } from "@reduxjs/toolkit";
import { ReferralsModel } from "./../models/referrals";
import { RootState } from "./rootReducer";

const initialState: ReferralsModel[] = [0, 1, 2].map((_c) => ({ name: "", address: "", phone: "" }));

export const ReferralsReducer = createSlice({
  name: "Referrals",
  initialState,
  reducers: {
    setReferralsRedux: (state, action) => {
      return action.payload;
    },
  }
});

export const { setReferralsRedux } = ReferralsReducer.actions;

export const getReferralsRedux = (state: RootState) => { return state.ReferralsState};

export default ReferralsReducer.reducer;
