
import { SalaryAccountState } from "../models/SalaryAccount.model";
import { RootState } from "./rootReducer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: SalaryAccountState = {
  salary: "UNKNOWN",
};

export const SalaryAccountReducer = createSlice({
  name: "Salary",
  initialState,
  reducers: {
    setSalaryAccountRedux: (state, action: PayloadAction<SalaryAccountState>) => {
      state.salary = action.payload.salary;
    },
  },
});

export const { setSalaryAccountRedux } = SalaryAccountReducer.actions;

export const getSalaryAccountRedux = (state: RootState) => { return state.SalaryAccountState.salary };

export default SalaryAccountReducer.reducer;
