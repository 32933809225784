import { FC, useState, useMemo, useCallback, useEffect } from "react";
import H from "history";
import { LandingProps } from "../MobileLanding";
import { ButtonWithoutRedux } from "../../../atoms/button/Button";
import CustomRadioButton from "ui/components/atoms/CustomRadioButton";
import { Input } from "../../../atoms/input/Input";
import { validateCedula } from "../../PrimerPaso/helpers";
import { useNavigate, useLocation } from "react-router-dom";
import ReCaptcha from "react-google-recaptcha";
import { IS_TESTEABLE } from "application/utils/constants";
import { useDispatch } from "react-redux";
import {
  productTypeParamToPayload,
  ProductTypeQueryParam,
} from "application/store/models/seleccionarProductoPaso";
import { setSalaryAccountRedux } from "application/store/reducers/salaryAccountReducer";
import { postCreateOnboarding, startOnboarding } from "infrastructure/services";
import { TrackerService } from "infrastructure/services/tracker/tracker.service";
import { GO_TO } from "infrastructure/api";
import { GA_EVENTS } from "infrastructure/services/tracker/ga.types";
import CustomFlagCountry from "ui/components/atoms/CustomFlagCountry";
import { Box } from "@mui/material";
import { SalaryAccountValue } from "application/store/models/SalaryAccount.model";
import { setAffinity } from "application/store/reducers/AfinidadReducer";
import { setSelectProduct } from "application/store/reducers/SeleccionarProductoReducer";
import { setCountryCodeTelef, setDocumentCountry, setDocumentNumber, setDocumentType, setStartOnBoarding } from "application/store/reducers/OnboardingReducer";
import { styles } from "./landingForm/styles";

const businessPartnerParamName = "businessPartner";

export interface countryUser {
  number: string;
  name: string;
  dial_code: string;
  code: string;
  flag: string;
  document: [
    {
      code: string;
      type: string;
    }
  ];
}

const initialCountryUser: countryUser = {
  number: "858",
  name: "Uruguay",
  dial_code: "+598",
  code: "UY",
  flag: "🇺🇾",
  document: [{ code: "CI", type: "Número C.I" }],
};

const LandingForm: FC<LandingProps> = ({
  productType,
  affinity,
  accountType,
}) => {
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [cedula, setCedula] = useState("");
  const [documentTypeCountry, setdocumentTypeCountry] = useState<string>("");
  const [documentByCountry, setDocumentByCountry] = useState<any[]>([]);
  const [country, setCountry] = useState(initialCountryUser);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const { search }: H.Location<H.LocationState> = useLocation();
  const navigate = useNavigate();

  const isPymes = productType === ProductTypeQueryParam.PymesParam;
  const isSalaryAccount = productType === ProductTypeQueryParam.SalaryAccount;
  const isForeign = country.code !== "UY";
  const isAccountType =
    accountType === SalaryAccountValue.PLUS ||
    accountType === SalaryAccountValue.BASIC;

  const searchParams = new URLSearchParams(search);
  const businessPartner = searchParams.get(businessPartnerParamName)
    ? searchParams.get(businessPartnerParamName)
    : "1";
  const cedulaInCounter = cedula.replace(/\D/g, "");
  const hasError = cedulaInCounter.length > 7 && !validateCedula(cedula);
  
  useEffect(() => {
    setCedula(""); //cleaning input CI
    if (country.document?.length > 0) {
      setdocumentTypeCountry(country.document[0].code);
    }

    if (country.document?.length > 1) {
      const array = [];
      country.document.map((item, index) => {
        array.push({
          name: item.type,
          value: item.code,
          id: `landing-${item.code}-${index}`,
        });
      });
      setDocumentByCountry(array);
    }
  }, [country.code]);

  const captchaSiteKey = useMemo(() => {
    const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

    if (siteKey) {
      return siteKey;
    } else {
      return "6LcdL8weAAAAAERkcyw1EJrzqAH8Xh-sIfmlb37r";
    }
  }, []);

  const getIsFormValid = useCallback(() => {
    if (country.code === "UY") {
      return validateCedula(cedula) && (IS_TESTEABLE || captchaToken);
    } else if (cedula.length > 5 && (IS_TESTEABLE || captchaToken)) {
      // validate that pasaport number is greater than five
      return true;
    }
  }, [cedula, captchaToken]);

  const onChangeCaptchaToken = (token: string | null) => {
    setCaptchaToken(token);
  };

  const createOnboardingByParam = async ({ documentType }) => {

    TrackerService.setIsForeign(isForeign);
    if (affinity) {
      window.hasExternalAffinity = true;
      dispatch(setAffinity({ afinidad: Number(affinity), afinities: [] }));
    }
    if (isSalaryAccount && isAccountType) {
      window.hasExternalAccountType = true;
      dispatch(setSalaryAccountRedux({ salary: accountType }));
    } else if (isSalaryAccount) {
      dispatch(setSalaryAccountRedux({ salary: SalaryAccountValue.PLUS }));
    }

    dispatch(
      setSelectProduct({
        isAhorro: productType === ProductTypeQueryParam.AccountParam,
        isCredito: productType === ProductTypeQueryParam.CreditParam,
        isSalaryAccount,
        isPymes,
      })
    );

    const success = await postCreateOnboarding({
      doc: cedula,
      productType: productTypeParamToPayload(productType),
      documentType,
      documentCountry: country.number,
      captchaToken,
      businessPartner,
    });
    if (!success) {
      TrackerService.trackGA(
        GA_EVENTS.ERRORS.REQUEST_CREATE_ONBOARDING_LANDING
      );
      navigate({ pathname: GO_TO.SUCURSALES, search: `?finished=${true}` }, { replace: true });
    } else {
      setIsButtonDisabled(false);
      startOnboarding(captchaToken, businessPartner);
      dispatch(setStartOnBoarding({ startOnboarding: true }));
      
      if (isPymes) {
        navigate(GO_TO.PYMES_DOCUMENTATION);
      } //Cuenta Sueldo - solicitar desde la web. productType === "OBC" accountType === "PLUS" | "BASIC"
      else if (isSalaryAccount && isAccountType) {
        if (isForeign) {

          //para usuarios no uruguayos
          if (accountType === SalaryAccountValue.PLUS) {
            // en caso de ser plus
            navigate(GO_TO.TERCER_PASO);

          } else {
            // en caso de ser basic
            navigate(GO_TO.SELECT_SALARY_ACCOUNT);

          }
        } else if (!isForeign) {

          // para usuarios uruguayos
          if (accountType === SalaryAccountValue.PLUS) {
            // en caso de ser plus
            navigate(GO_TO.PRIMER_PASO);
            TrackerService.setProduct("cs", "PLUS");
          } else {
            // en caso de ser basic
            navigate(GO_TO.SELECT_SALARY_ACCOUNT);
          }
        }
      } //Cuenta Sueldo - solicitar desde la web. productType === "OBC"
      else if (isSalaryAccount) {
        if (isForeign) {
          //para usuarios no uruguayos
          navigate(GO_TO.SELECT_SALARY_ACCOUNT);
        } else {
          //para usuarios uruguayos
          TrackerService.setProduct("cs", "PLUS");
          navigate(GO_TO.PRIMER_PASO);
        }
      } else {

        navigate(GO_TO.PRIMER_PASO);
      }
    }
  };

  const createOnboardingToSalaryAccount = async ({
    productType,
    isSalaryAccount,
    documentType,
  }) => {
    dispatch(
      setSelectProduct({
        isAhorro: false,
        isCredito: false,
        isSalaryAccount,
        isPymes: false,
      })
    );

    const success = await postCreateOnboarding({
      doc: cedula,
      productType: productTypeParamToPayload(productType),
      documentType,
      documentCountry: country.number,
      captchaToken,
      businessPartner: window.localStorage.getItem(businessPartnerParamName),
    });

    if (!success) {
      TrackerService.trackGA(
        GA_EVENTS.ERRORS.REQUEST_CREATE_ONBOARDING_LANDING
      );
      navigate({ pathname: GO_TO.SUCURSALES, search: `?finished=${true}` }, { replace: true });
    } else {
      setIsButtonDisabled(false);
      startOnboarding(captchaToken, window.localStorage.getItem(businessPartnerParamName));
      dispatch(setStartOnBoarding({ startOnboarding: true }));
      navigate(GO_TO.SELECT_SALARY_ACCOUNT);
    }
  };

  const onContinue = async () => {    
    setIsButtonDisabled(true)
    TrackerService.setIsForeign(isForeign);
    dispatch(setDocumentNumber({ documentNumber: cedula }));
    dispatch(setDocumentCountry({ documentCountry: country.number }));
    dispatch(setDocumentType({ documentType: documentTypeCountry }));

    dispatch(setCountryCodeTelef({ countryCodeTelef: country.dial_code }));

    if (
      [
        ProductTypeQueryParam.CreditParam,
        ProductTypeQueryParam.AccountParam,
        ProductTypeQueryParam.PymesParam,
        ProductTypeQueryParam.SalaryAccount,
      ].includes(productType)
    ) {
      createOnboardingByParam({ documentType: documentTypeCountry });
    } else if (isForeign) {
      window.localStorage.setItem("businessPartner", "1");
      createOnboardingToSalaryAccount({
        productType: "OBC",
        isSalaryAccount: true,
        documentType: documentTypeCountry,
      });
    } else {
      setIsButtonDisabled(false);
      startOnboarding(captchaToken, businessPartner);
      dispatch(setStartOnBoarding({ startOnboarding: true }));
      navigate(GO_TO.SELECT_PRODUCT_PASO);
    }
  };

  const showViewDocumnetType = () => {
    return (
      <div style={{ marginLeft: "1.2rem", marginBottom: "1.5rem" }}>
        <CustomRadioButton
          values={documentByCountry}
          containerStyles={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "space-between",
            maxWidth: "375px",
          }}
          props={{
            onChange: (e) => {
              setdocumentTypeCountry(e.target.value);
            },
            value: documentTypeCountry,
          }}
        />
      </div>
    );
  };

  return (
    <Box sx={styles.container}>
      <Box>
        <Box className="heroContent">
          <Box className="titleForm">
            Ingresá un documento para comenzar
          </Box>
          {(String(productType) === ProductTypeQueryParam.default ||
            String(productType) === ProductTypeQueryParam.SalaryAccount) && (
              <Box className="secondText" style={{ marginBottom: 0 }}>
                <CustomFlagCountry country={country} setCountry={setCountry} />
              </Box>
            )}
          {country.code !== "UY" && (
            <>{country.document?.length > 1 && showViewDocumnetType()}</>
          )}

          <Box className="secondText">
            {country.code === "UY" ? (
              <Box sx={styles.root}>
                <span className="span-input-subtitle">Número de C.I</span>
                <Input
                  id="cedula-identidad-input"
                  value={cedula}
                  mask="9.999.999-9"
                  placeholder=""
                  maxlenght={11}
                  helpmessage="8 números sin puntos ni guión"
                  count={`${cedulaInCounter.length}/8`}
                  onChange={(e) => setCedula(e.target.value)}
                  style={hasError ? { borderBottom: "1px solid #FF0000" } : {}}
                  hasError={hasError}
                  errorComponent={
                    <span id="cedula-identidad-input-error" className="errorLabel">
                      La cédula ingresada no existe
                    </span>
                  }
                />
              </Box>
            ) : (
              <Box sx={styles.root}>
                <span className="span-input-subtitle">
                  {documentTypeCountry === "PASSPORT"
                    ? "Nro Pasaporte"
                    : `Número de ${documentTypeCountry}`}
                </span>
                <Input
                  id="pasaporte-identidad-input"
                  placeholder=""
                  value={cedula}
                  mask="***************"
                  maxlenght={15}
                  helpmessage="Ingresá tu documento sin puntos ni guiones"
                  count={`${cedula.length}/15`}
                  onChange={(e) => setCedula(e.target.value)}
                  hasError={false}
                  errorComponent={
                    <span id="pasaporte-identidad-input-error" className="errorLabel">
                      No existe el documento ingresado
                    </span>
                }
                />
              </Box>
            )}
          </Box>
          <div className="captcha-container">
            <ReCaptcha
              onChange={onChangeCaptchaToken}
              sitekey={captchaSiteKey}
            />
          </div>
          <div className="col s12 butCon text-center">
            <ButtonWithoutRedux
              id="landing-form-continue-button"
              disabled={!getIsFormValid() || isButtonDisabled}
              label="Continuar"
              callback={onContinue}
              isLoading={isButtonDisabled}
            />
          </div>
        </Box>
      </Box>
    </Box >
  );
};

export default LandingForm;