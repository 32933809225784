import { Theme } from "@mui/material";

export const styles = {
  container: {
    maxWidth: "1280px",
    fontSize: "fontSize",
    fontWeight: "fontWeightRegular",
    lineHeight: "21px",
    textSizeAdjust: "100%",
    margin: "0 auto 40px",
    width: "90%",

    ".captcha-container": {
      display: "flex",
      justifyContent: "center",
      marginTop: "-6px",
    },

    ".butCon": {
      margin: "20px 0",
      textAlign: "center",
    },

    ".onboarding-button:focus": {
      backgroundColor: "red"
    },

    ".onboarding-button": {
      fontFamily: "fontFamily",
      fontWeight: "fontWeightBold",
      border: "0",
      borderRadius: "3em",
      cursor: "pointer",
      display: "inline-block",
      lineHeight: "1",
      width: "100% !important",
    },

    ".onboarding-button--primary": {
      color: "white",
      backgroundColor: "red",
      border: "1px solid #ec0000",
      boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.12)",

      "&--inverted": {
        color: "red",
        backgroundColor: "white",
        border: "1px solid #ec0000",
        boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.12)",
        fontWeight: "normal",
      }
    },

    ".onboarding-button--secondary ": {
      fontSize: "16px",
      color: "#989898",
      boxShadow: "#989898 0px 0px 1px 0px inset",
      backgroundColor: "#fafafa",
      border: "1px solid #cccccc",
      cursor: "pointer",

      "&--inverted": {
        color: "#fafafa",
        backgroundColor: "#989898",
      }
    },

    ".onboarding-button--small": {
      fontSize: "12px",
      padding: "10px 16px",
    },

    ".onboarding-button--medium": {
      fontSize: "body1.fontSize",
      padding: "11px 20px",
    },

    ".onboarding-button--large": {
      fontSize: "body1.fontSize",
      padding: "12px 24px",
    },

    ".heroContent": (theme: Theme) => ({
      maxWidth: "375px",
      [theme.breakpoints.up("md")]: {
        position: "relative",
        margin: "60px auto",
        maxWidth: "360px",
      },
      ".hero-image-desktop": {
        "& img": {
          margin: "20px auto",
          width: "240px",
        }
      },
      ".onboarding-button": {
        width: "240px"
      },
    }),
    ".titleForm": {
      fontFamily: "h4.fontFamily",
      fontSize: "h4.fontSize",
      fontStyle: "h4.fontStyle",
      fontWeight: "fontWeightBold",
      marginBottom: "30px",
      padding: "0 14px",
      lineHeight: "32px",
      textAlign: "center",
      color: "rgb(68,68,68)"
    },
    ".secondText": {
      fontFamily: "body1.fontFamily",
      fontStyle: "normal",
      fontWeight: "body1.fontWeight",
      fontSize: "body1.fontSize",
      lineHeight: "24px",
      textAlign: "center",
      color: "#444444",
      margin: "14px",
      height: "70px",
    },

    "& input": {
      fontFamily: "body1.fontFamily",
      fontSize: "body1.fontSize",
      borderTop: "1px solid #deedf2 !important",
      borderLeft: "1px solid #deedf2 !important",
      borderRight: "1px solid #deedf2 !important",
      borderRadius: "4px 4px 0 0 !important",
      textAlign: "center",
      backgroundColor: "transparent",
      border: "none",
      borderBottom: "1px solid #9e9e9e !important",
      lineHeight: "1.15",
      outline: "none",
      height: "48px",
      width: "100%",
      margin: "0 0 8px 0",
      padding: "0",
      boxShadow: "none",
      boxSizing: "content-box",
      transition: "box-shadow .3s, border .3s, -webkit-box-shadow .3s"
    },
    "& input:focus": {
      borderBottom: "1px solid #2a2a2a !important",
      boxShadow: "0 1px 0 0 #2a2a2a !important"
    },
    "& input::placeholder": {
      textAlign: "center",
      color: "#767676"
    }
  },
  root: {
    position: "relative",

    ".span-input-subtitle": {
      position: "absolute",
      color: "rgba(0, 0, 0, 0.6)",
      fontFamily: `body1.fontFamily`,
      fontStyle: "normal",
      fontWeight: "fontWeightRegular",
      fontSize: "11px",
      lineHeight: "1.4375em",
      padding: "0",
      transformOrigin: "top left",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "calc(133% - 24px)",
      left: "14px",
      top: "0",
    },

    ".onboarding-input": {
      fontFamily: "fontFamily",
      border: "0",
      cursor: "pointer",
      display: "inline-block",
      lineHeight: "1",
      height: "42px",
    },

    ".onboarding-input--primary": {
      color: "white"
    },

    ".onboarding-input--secondary": {
      color: "#333",
      backgroundColor: "transparent",
      boxShadow: "grey",
    },

    ".onboarding-input--small": {
      fontSize: "12px",
      padding: "10px 16px",
    },

    ".onboarding-input--medium": {
      fontSize: "body1.fontSize",
      padding: "0",
    },

    ".onboarding-input--large": {
      fontSize: "body1.fontSize",
      padding: "12px 24px",
    },

    ".errorLabel": {
      color: "#ff0000",
      fontFamily: 'fontFamily',
      fontSize: 'fontSize',
    },
  },
};