import { combineReducers } from "redux";
import OnboardingReducer from "./OnboardingReducer";
import PrimerPasoReducer from "./PrimerPasoReducer";
import SegundoPasoReducer from "./SegundoPasoReducer";
import TercerPasoReducer from "./TercerPasoReducer";
import CuartoPasoReducer from "./CuartoPasoReducer";
import QuintoPasoReducer from "./QuintoPasoReducer";
import SextoPasoReducer from "./SextoPasoReducer";
import SeptimoPasoReducer from "./SeptimoPasoReducer";
import KycPasoReducer from "./KycPasoReducer";
import SucursalProductoReducer from "./SucursalProductoReducer";
import ActivityReducer from "./ActivityReducer";
import GlobalLoadingStateReducer from "./GlobalLoadingStateReducer";
import TokenStateReducer from "./TokenStateReducer";
import SeleccionarProductoReducer from "./SeleccionarProductoReducer";
import ModalReducer from "./ModalReducer";
import AfinidadReducer from "./AfinidadReducer";
import SalaryAccountReducer from "./salaryAccountReducer";
import PackOfferReducer from "./packOfferReducer";
import ShowErrorReducer from "./ShowErrorReducer";
import ReferralsReducer from "./ReferralsReducer";
import accountInformationReducer from "./accountInformationReducer";
import uploadDocumentsReducer from "./uploadDocumentsReducer";
import employerStepReducer from "./employerStepReducer";
import tagsReducer from "./tagsReducer";

const appReducer = combineReducers({
  // ToolKit New or Migrated Below
  onboardingState: OnboardingReducer,
  PrimerPasoState: PrimerPasoReducer,
  SegundoPasoState: SegundoPasoReducer,
  TercerPasoState: TercerPasoReducer,
  CuartoPasoState: CuartoPasoReducer,
  QuintoPasoState: QuintoPasoReducer,
  SextoPasoState: SextoPasoReducer,
  SeptimoPasoState: SeptimoPasoReducer,
  KycPasoState: KycPasoReducer,
  SucursalProductoReducer: SucursalProductoReducer,
  ActivityReducer: ActivityReducer,
  GlobalLoadingState: GlobalLoadingStateReducer,
  TokenState: TokenStateReducer,
  SeleccionarProductoState: SeleccionarProductoReducer,
  AfinidadState: AfinidadReducer,
  SalaryAccountState: SalaryAccountReducer,
  PackOfferState: PackOfferReducer,
  ModalState: ModalReducer,
  ShowErrorState: ShowErrorReducer,
  ReferralsState: ReferralsReducer,
  AccountInformationState: accountInformationReducer,
  UploadDocumentState: uploadDocumentsReducer,
  EmployerStepState: employerStepReducer,
  TagsState: tagsReducer,
});

export const CLEAN_STORE = "CLEAN_STORE";

const rootReducer = (state, action) => {
  if (action.type === CLEAN_STORE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;