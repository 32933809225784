import { Box } from "@mui/material";
import React, { ReactNode } from "react";
import { DocumentationItem } from "./DocumentationItem";

interface PymesFeatures {
  title: string;
  content: string;
  linkText?: string;
  onClick?: () => void;
  icon: ReactNode;
}

interface PymesFeaturesProps {
  data: Array<PymesFeatures>;
  className?: string;
}

export const RequiredDocumentationList: React.FC<PymesFeaturesProps> = ({
  data,
  className,
  ...props
}) => {
  return (
    <Box className={className ? className : null}>
      {data.map((item, index) => {
        const linkProps = item.linkText
          ? {
              linkText: item.linkText,
              onClick: item.onClick,
            }
          : {};
        return (
          <DocumentationItem
            key={index}
            title={item.title}
            content={item.content}
            icon={item.icon}
            {...linkProps}
            {...props}
          />
        );
      })}
    </Box>
  );
};
