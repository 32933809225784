import { Box, SxProps } from "@mui/material";
import { SantanderTheme } from "ui/theme/SantanderTheme";
import { TotemData } from "./TotemData";

const TotemsList = () => {
  return (
    <Box overflow="scroll" sx={styles.root}>
      {TotemData.map((totem) => (
        <Box className="wrapperTotem">
          <Box className="stateText">{totem.state}</Box>
          <Box>
            {totem.addreses.map((address) => (
              <Box my={2}>
                &#8226;&nbsp;
                <Box className="addressText">{address.address}</Box>
                &nbsp;
                <Box className="detailText">{address.details}</Box>
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default TotemsList;

const styles = {
  root: {
    padding: "0rem 1rem",

    ".stateText": {
      fontFamily:`fontFamily`,
      fontSize: `1.25rem`,
      fontWeight: `fontWeightBold`,
      margin: "1rem 0rem",
      textTransform: "uppercase",
    },

    ".wrapperTotem": {
      marginBottom: "2.5rem"
    },

    ".addressText": {
      display: "inline",
      fontFamily:`body1.fontFamily`,
      fontSize: `body1.fontSize`,
      fontWeight: `fontWeightBold`,
      my: 1,
    },

    ".detailText": {
      display: "inline",
      fontFamily:`body2.fontFamily`,
      fontSize: `body2.fontSize`,
    },
  }
};
