export const styles = {
  root: {
    width: "100%",

    ".gridContainer": {
      background: "#ffffff",
      borderRadius: "20px",
      marginTop: "-15px",
    },

    ".titleHI div": {
      fontFamily: "h3.fontFamily",
      fontSize: "h3.fontSize",
      fontWeight: "fontWeightBold",
      color: "#444444",
      marginBottom: "20px",
    },

    ".titleWrapper": {
      fontFamily: "h4.fontFamily",
      fontSize: "h4.fontSize",
      fontWeight: "fontWeightBold",
      fontStyle: "normal",
      color: "#444444",
    },

    ".subTitleText": {
      fontFamily: "body1.fontFamily",
      fontSize: "body1.fontSize",
      fontWeight: "body1.fontWeight",
      fontStyle: "normal",
      color: "#444444",
    },

    ".faqLink": {
      display: "block",
      color: "#0f74a7",
      margin: "8px 0",
    },

    ".readMoreContainer": {
      display: "flex",
      width: "100%",
      "& :active": {
        opacity: "50%",
      },
    },

    ".readMoreText": {
      fontFamily: "body1.fontFamily",
      fontSize: "body1.fontSize",
      fontWeight: "body1.fontWeight",
      fontStyle: "normal",
      color: "#ec0000",
      margin: "0px",
      marginLeft: "1rem",
    },

    ".deliveryContainer": {
      display: "flex",
      flexDirection: "column",
    },

    ".methodDeliveryText": {
      fontFamily: "h6.fontFamily",
      fontSize: "1.25rem",
      fontWeight: "fontWeightBold",
      fontStyle: "normal",
      display: "flex",
      color: "#000000",
    },

    ".knowMoreButton": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "12px 20px",
      width: "335px",
      height: "40px",
      background: "transparent",
      color: "#ec0000",
      border: "1px solid #ec0000",
      boxSizing: "border-box",
      boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.12)",
      borderRadius: "40px",
      "& :active": {
        opacity: "50%",
      },
    },

    ".knowMoreButtonText": {
      fontFamily: "body1.fontFamily",
      fontSize: "body1.fontSize",
      fontWeight: "body1.fontWeight",
      fontStyle: "normal",
      textAlign: "center",
      color: "#ec0000",
    },

    ".methodDeliveryWrapper": {
      display: "flex",
    },

    ".redLeftBar": {
      position: "static",
      width: "4px",
      background: "#ec0000",
      flex: "none",
      order: "0",
      alignSelf: "stretch",
      flexGrow: "0",
      margin: "0px 10px 0px 0px",
      minHeight: "44px",
    },

    ".methodDeliveryDetailsContainer": {
      display: "flex",
      flexDirection: "column",
    },

    ".methodDeliveryDetailsText": {
      fontFamily: "body2.fontFamily",
      fontSize: "body2.fontSize",
      fontWeight: "body2.fontWeight",
      fontStyle: "normal",
      color: "#000000",
      margin: "0px",
      wordBreak: "break-word",
    },

    ".methodDeliveryDetailsTextBold": {
      fontWeight: "fontWeightBold",
      marginBottom: "0.5rem",
    },
    "& .finalizarButton": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "12px 20px",
      height: 40,
      background: "#ec0000",
      border: "1px solid #ec0000",
      boxSizing: "border-box",
      boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.12)",
      borderRadius: 40,
      width: "100%",
      fontFamily: "Santander Micro Text, Verdana, sans-serif",
      fontWeight: "bold",
      fontSize: "1rem",
      textAlign: "center",
      color: "#ffffff",
      "&:active": {
        opacity: "50%",
      },
      "&:disabled": {
        background: "gray",
        border: "1px solid gray",
      },
    },
  },
}; 
