export function validation_digit(ci){
    var a = 0;
    var i = 0;
    if(ci.length <= 6){
      for(i = ci.length; i < 7; i++){
        ci = '0' + ci;
      }
    }
    for(i = 0; i < 7; i++){
      a += (parseInt("2987634"[i]) * parseInt(ci[i])) % 10;
    }
    if(a%10 === 0){
      return 0;
    }else{
      return 10 - a % 10;
    }
  }
  
 export function validate_ci(ci){
    ci = clean_ci(ci);
    var dig = ci[ci.length - 1];
    ci = ci.replace(/[0-9]$/, '');
    return (parseInt(dig) === validation_digit(ci));
  }  
  
 export function clean_ci(ci){
    return ci.replace(/\D/g, '');
  }

  export function ci_uy(ci){
    if(ci.length > 5){
      ci = clean_ci(ci);
      validation_digit(ci);
    }
  }
  