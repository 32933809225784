import { CSSProperties } from "react";
import { Box, CircularProgress } from "@mui/material";

interface ButtonWithoutReduxProps {
  id?: string;
  disabled?: boolean;
  isLoading?: boolean;
  primary?: boolean;
  secundary?: boolean;
  inverted?: boolean;
  backgroundColor?: string;
  marginTop?: string;
  otherStyle?: CSSProperties;
  size?: "small" | "medium" | "large";
  label: string;
  callback: () => void;
}

export const ButtonWithoutRedux = ({
  id,
  callback,
  disabled = false,
  isLoading = false,
  backgroundColor,
  marginTop,
  otherStyle,
  primary = false,
  secundary = false,
  inverted = false,
  size = "medium",
  label,
  ...props
}: ButtonWithoutReduxProps) => {
  let mode =
    disabled || secundary
      ? "onboarding-button--secondary"
      : "onboarding-button--primary";
  mode += inverted ? "--inverted" : "";

  return (
    <Box sx={styles.root}>
      <button
        id={id}
        disabled={disabled}
        onClick={() => callback()}
        type="button"
        className={["onboarding-button", `onboarding-button--${size}`, mode].join(
          " "
        )}
        style={{ backgroundColor, marginTop, ...otherStyle }}
        {...props}
      >
        {isLoading ? <CircularProgress size={15} /> : label}
      </button>
    </Box>
  );
};

const styles = {
  root: {

    ".onboarding-button:focus": {
      backgroundColor: "red"
    },

    ".onboarding-button": {
      fontFamily: "body1.fontFamily",
      fontWeight: "fontWeightBold",
      border: "0",
      borderRadius: "3em",
      cursor: "pointer",
      display: "inline-block",
      lineHeight: "1",
      width: "100% !important",
    },

    ".onboarding-button--primary": {
      color: "white",
      backgroundColor: "red",
      border: "1px solid #ec0000",
      boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.12)",

      "&--inverted": {
        color: "red",
        backgroundColor: "white",
        border: "1px solid #ec0000",
        boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.12)",
        fontWeight: "normal",
      }
    },

    ".onboarding-button--secondary ": {
      fontSize: "16px",
      color: "#989898",
      boxShadow: "#989898 0px 0px 1px 0px inset",
      backgroundColor: "#fafafa",
      border: "1px solid #cccccc",
      cursor: "pointer",

      "&--inverted": {
        color: "#fafafa",
        backgroundColor: "#989898",
      }
    },

    ".onboarding-button--small": {
      fontSize: "12px",
      padding: "10px 16px",
    },

    ".onboarding-button--medium": {
      fontSize: "body1.fontSize",
      padding: "11px 20px",
    },

    ".onboarding-button--large": {
      fontSize: "16px",
      padding: "12px 24px",
    },
  }
};