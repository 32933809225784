import store from "application/store/Store";
import { TagsState } from "application/store/reducers/tagsReducer";

export function getUtmTags() {
  try {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const utmTags: TagsState['tags'] = [];

    urlParams.forEach((value, key) => {
      if (key.startsWith('utm_')) {
        utmTags.push({
          tagName: key,
          tagValue: value
        });
      }
    });

    console.info('UTM TAGS', utmTags);

    return utmTags;
  } catch (e) {
    return [];
  }
}

export function tagsToString(tags: TagsState['tags']) {
  try {
    return tags.map(t => `${t.tagName}=${t.tagValue}`).join('&');
  } catch {
    return '';
  }
}