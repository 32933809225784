import { Box } from "@mui/material";
import { documentationList } from "./documentationData";
import classNames from "classnames";

const HowToDownloadPymeDocumentation = () => {
  return (
    <Box sx={styles.root} overflow="scroll">
      {documentationList.map((documentationItem, index) => (
        <Box className="documentationItemContainer" key={index}>
          <Box className="documentationItemTitle">
            {documentationItem.title}
          </Box>
          <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
            {documentationItem.content.map((contentItem, indexSteps) => (
              <li
                key={indexSteps}
                className={classNames("documentationItemListContent",  `documentationListItem`)}
                dangerouslySetInnerHTML={{ __html: contentItem }}
              ></li>
            ))}
          </ul>
        </Box>
      ))}
    </Box>
  );
};

export default HowToDownloadPymeDocumentation;


const styles = {
  root: {
    mx: "2rem",
    ".documentationItemTitle": {
      fontFamily: "Santander Micro Text, Verdana, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
    },

    ".documentationItemContainer": {
      marginBottom: "40px",
    },

    ".documentationItemListContent": {
      display: "list-item",
      fontFamily: "Santander Micro Text, Verdana, sans-serif",
      fontSize: "1rem",
    },
  },
};