import { RootState } from 'application/store/reducers/rootReducer';
import { GO_TO } from 'infrastructure/api';
import { lazy } from 'react'
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from "react-router-dom";
import LandingRouter from "./components/LandingRouter";
import ProtectedRouter from "./components/ProtectedRouter";
import NPS from "ui/components/molecules/NPS";

//Steps
const PrimerPaso = lazy(() => import("ui/pages/routes/components/FirstStepRouter"));
const SegundoPaso = lazy(() => import("ui/components/molecules/SegundoPaso"));
const TercerPaso = lazy(() => import("ui/components/molecules/TercerPaso/index"));
const CuartoPaso = lazy(() => import("ui/components/molecules/CuartoPaso/index"));
const KYC = lazy(() => import("ui/components/molecules/KYC/KYC"));
const QuintoPaso = lazy(() => import("ui/components/molecules/QuintoPaso"));
const SextoPaso = lazy(() => import("ui/components/molecules/SextoPaso"));
const SeptimoPaso = lazy(() => import("ui/components/molecules/SeptimoPaso"));
const PasoFinal = lazy(() => import("ui/components/molecules/PasoFinal"));
const PasoExitoso = lazy(() => import("ui/components/molecules/ProcesoExitoso"));
//Acount
const SeleccionarProducto = lazy(() => import("ui/components/molecules/SeleccionarProducto"));
const CuentaJoven = lazy(() => import("ui/components/molecules/CuentaJoven/CuentaJoven"));
const CuentaPlus = lazy(() => import("ui/components/molecules/CuentaPlus/CuentaPlus"));
//Credit Card
const ProgramaAfinidad = lazy(() => import("ui/components/molecules/ProgramaAfinidad"));
const SeleccionarSelloSegmento = lazy(() => import("ui/components/molecules/SeleccionarSelloSegmento"));
//Pymes
const PymeDocumentation = lazy(() => import("ui/components/molecules/PymeDocumentation"));
const ActivityType = lazy(() => import("ui/components/molecules/ActivityType/index"));
const CuentaPyme = lazy(() => import("ui/components/molecules/CuentaPyme/CuentaPyme"));
const Referrals = lazy(() => import("ui/components/molecules/Referrals"));
const UploadDocuments = lazy(() => import("ui/components/molecules/UploadDocuments"));
//Salary Account
const SelectSalaryAccount = lazy(() => import("ui/components/molecules/SeleccionarCuentaSueldo"));
const EmployerStep = lazy(() => import("ui/components/molecules/EmployerStep/EmployerStep"));
const CuentaSueldo = lazy(() => import("ui/components/molecules/CuentaSueldo/CuentaSueldo"));
//Common Error
const IrASucursales = lazy(
  () => import("ui/components/molecules/IrASucursales/IrASucursales")
);
const UnhandledErrorScreen = lazy(
  () => import("ui/components/molecules/IrASucursales/UnhandledErrorScreen")
);

const KYCPyme = lazy(() => import("ui/components/molecules/KYC/KYCPyme"));

function IndexRouter() {
  const { startOnboarding, hasToken, urlParams } = useSelector((state: RootState) => ({
    startOnboarding: state.onboardingState.startOnboarding,
    hasToken: state.TokenState.hasToken,
    urlParams: state.onboardingState.urlParams,
  }));

  const isAllowed = startOnboarding && hasToken;

  return (
    <Routes>
      <Route path={GO_TO.LANDING} element={<LandingRouter />} errorElement={<UnhandledErrorScreen />} />
      <Route path={GO_TO.STEPS} element={<ProtectedRouter isAllowed={isAllowed} url={GO_TO.LANDING} />} errorElement={<UnhandledErrorScreen />}>
        {/*Common Steps */}
        <Route path={GO_TO.PRIMER_PASO} element={<PrimerPaso />} />
        <Route path={GO_TO.SEGUNDO_PASO} element={<SegundoPaso />} />
        <Route path={GO_TO.TERCER_PASO} element={<TercerPaso />} />
        <Route path={GO_TO.CUARTO_PASO} element={<CuartoPaso />} />
        <Route path={GO_TO.KYC_PASO} element={<KYC />} />
        <Route path={GO_TO.KYC_PYME_PASO} element={<KYCPyme />} />
        <Route path={GO_TO.QUINTO_PASO} element={<QuintoPaso />} />
        <Route path={GO_TO.SEXTO_PASO} element={<SextoPaso />} />
        <Route path={GO_TO.SEPTIMO_PASO} element={<SeptimoPaso />} />
        {/*Account */}
        <Route path={GO_TO.SELECT_PRODUCT_PASO} element={<SeleccionarProducto />} />
        <Route path={GO_TO.CUENTA_JOVEN_PASO} element={<CuentaJoven />} />
        <Route path={GO_TO.CUENTA_PLUS_PASO} element={<CuentaPlus />} />
        {/*Credit Card */}
        <Route path={GO_TO.PROGRAMA_AFINIDAD} element={<ProgramaAfinidad />} />
        <Route path={GO_TO.SELECT_SELLO_SEGMENTO} element={<SeleccionarSelloSegmento />} />
        {/*Pymes */}
        <Route path={GO_TO.PYMES_DOCUMENTATION} element={<PymeDocumentation />} />
        <Route path={GO_TO.ACTIVITY_TYPE} element={<ActivityType />} />
        <Route path={GO_TO.CUENTA_PYME_PASO} element={<CuentaPyme />} />
        <Route path={GO_TO.REFERRALS} element={<Referrals />} />
        <Route path={GO_TO.MULTIPLE_DOCUMENTS_UPLOAD} element={<UploadDocuments />} />
        {/*Salary Account */}
        <Route path={GO_TO.SELECT_SALARY_ACCOUNT} element={<SelectSalaryAccount />} />
        <Route path={GO_TO.EMPLOYER_STEP} element={<EmployerStep />} />
        <Route path={GO_TO.CUENTA_SUELDO_PASO} element={<CuentaSueldo />} />
      </Route>
      {/*Steps  Finished */}
      <Route
        path={GO_TO.FINAL}
        element={<PasoFinal />}
        errorElement={<UnhandledErrorScreen />}
      />
      <Route
        path={GO_TO.NPS}
        element={<NPS />}
        errorElement={<UnhandledErrorScreen />}
      />
      <Route
        path={GO_TO.NPS_EXITOSO}
        element={<PasoExitoso />}
        errorElement={<UnhandledErrorScreen />}
      />
      {/*Common Error */}
      <Route path={GO_TO.SUCURSALES} element={<IrASucursales />} />
      <Route path={GO_TO.REDIRECT_ERROR} element={<UnhandledErrorScreen />} />
      <Route path={GO_TO.ERROR_PAGE} element={<Navigate to={(urlParams !== "") ? `${GO_TO.LANDING}${urlParams}` : GO_TO.LANDING} />} />
    </Routes>
  )
}

export default IndexRouter