export const styles = {
  root: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    background: "#ffffff",
    margin: "1rem",
    padding: "0px 0px 20px",
    maxHeight: "100vh",
    zIndex: "1",
    overflowY: "scroll",

    ".row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 15px",
      width: "100%",
    },

    ".title": {
      fontFamily: "body1.fontFamily",
      fontSize: "body1.fontSize",
      fontWeight: "fontWeightBold",
      fontStyle: "normal",
      lineHeight: "24px",
      color: "#257fa4"
    },

    ".titleBenefits": {
      fontFamily: "body1.fontFamily",
      fontSize: "18px",
      fontWeight: "fontWeightBold",
      fontStyle: "normal",
      lineHeight: "24px",
      color: "#444444"
    },

    ".button": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "95%",
      height: "40px",
      background: "#257fa4",
      border: "1px solid #257fa4",
      boxSizing: "border-box",
      boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.12)",
      borderRadius: "40px",
      margin: "1rem 0 5rem",

      "& :active": {
        opacity: "50%"
      }
    },

    ".buttonBenefits": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "90%",
      height: "44px",
      background: "#EC0000",
      border: "none",
      boxSizing: "border-box",
      boxShadow: "0px 5px 8px rgba(0, 0, 0, 0.12)",
      borderRadius: "40px",
      marginTop: "1rem",
      padding: "0px 12px",

      "& :active": {
        opacity: "50%"
      }
    },

    ".buttonText": {
      fontFamily: "body1.fontFamily",
      fontSize: "body1.fontSize",
      fontWeight: "fontWeightBold",
      fontStyle: "normal",
      textAlign: "center",
      color: "#fafafa"
    },

    ".closeIcon": {
      "& :active": {
        opacity: "50%"
      }
    },

    ".buttonContainer": {
      display: "flex",
      justifyContent: "center",
      width: "100%"
    },
  },
};