import { createSlice } from "@reduxjs/toolkit";
import { DatosCuartoPaso } from "./../models/cuartoPaso";

export type CuartoPasoState = DatosCuartoPaso;

const initialState: CuartoPasoState = {
  civilState: "",
  partnerDocument: "",
  partnerName: "",
  partnerLastName: "",
  student: "",
  studentCenter: "",
  studentCenterName: "",
  work: "",
  workMoney: "",
  profesion: "",
  diaIngreso: "",
  mesIngreso: "",
  anioIngreso: "",
};

export const CuartoPasoReducer = createSlice({
  name: "CuartoPaso",
  initialState,
  reducers: {
    setQuarterStep: (state, action) => {
      return action.payload;
    }
  }
});

export const { setQuarterStep } = CuartoPasoReducer.actions;

export default CuartoPasoReducer.reducer;
