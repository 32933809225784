export type GoogleAnalyticsEvents = "form_tdc" | "form_ca" | "form_py" | "form_cs" | "form_cs_basica" | "form_cs_plus" | "form";
export const GA_EVENTS = {
  FLOW: {
    OPEN_ACCOUNT: "Paso1_AbríTucuenta",
    LANDING: "Landing",
    LANDING_TDC: "Landing_TDC",
    LANDING_CA: "Landing_CA",
    LANDING_PY: "Landing_PY",
    LANDING_CS: "Landing_CS",
    LANDING_CS_PLUS: "Landing_CS_PLUS",
    LANDING_CS_BASIC: "Landing_CS_BASIC",
    PRODUCT_SELECT_TDC: "Paso2_ElecciónDeProducto_TDC",
    PRODUCT_SELECT_CA: "Paso2_ElecciónDeProducto_CA",
    PRODUCT_SELECT_CS_PLUS: "Paso2_ElecciónDeProducto_CS_PLUS",
    PRODUCT_SELECT_CS_BASIC: "Paso2_ElecciónDeProducto_CS_BASIC",
    IDENTIFICATION_SELECTION: "Paso3_ElecciónDeTipoDeCedula",
    GO_SUCURSAL: "Paso4.1_DirigirseASucursal",
    IDENTIFICATION_WITH_CHIP: "Paso5.1_CedulaConChip",
    IDENTIFICATION_WITHOUT_CHIP: "Paso5.2_CedulaSinChip",
    IDENTIFICATION_VERIFICATION: "Paso6.1.1_VerificaciónIdentidad",
    UPDATE_PROSPECT_WITH_CHIP: "Paso7.1_ConfirmaciónDatosPrincipalesCIChip",
    UPDATE_PROSPECT_WITHOUT_CHIP:
      "Paso7.2_ConfirmaciónDatosPrincipalesCISinChip",
    UPDATE_PROSPECT_MAIN_INFO: "Paso7.1.2_ConfirmaciónDatosPrincipales",
    UPDATE_PROSPECT_MAIN_INFO_CS_URUGUAY: "Paso7.1.2_ConfirmaciónDatosPrincipales_CS_URUGUAY",
    UPDATE_PROSPECT_MAIN_INFO_CS_EXTRANJERO: "Paso7.1.2_ConfirmaciónDatosPrincipales_CS_EXTRANJERO",
    ADDRESS_VERIFICATION: "Paso16_VerificaciónDeDirección",
    KYC_PYME: "Paso8_CuentaPyme",
    ACTIVITY_PYME: "Paso7.1.2_ConfirmaciónDatosActividadPYME",
    UPDATE_KYC_PERSONAL_INFO: "Paso8.3_ConfirmacionDatosPersonales",
    YOUNG_ACCOUNT_TYPE: "Paso8_CuentaJoven",
    PLUS_ACCOUNT_TYPE: "Paso10_CuentaPlus",
    AFFINITY_PROGRAM: "Paso11_ProgramadeAfinidad",
    SEGMENT_SELECTION: "Paso12_SelecciónDeSegmento",
    BRAND_SELECTION: "Paso12_SelecciónDeSello",
    LIFE_PROOF_SELFIE: "Paso15_SelfiePruebadeVida",
    FINAL_ONBOARDING: "Paso17_FinalOnboarding",
    VISA_PLATINUM_SELECTION: "Paso12.1_SelecciónVISAPlatinum",
    IDENTIFICATION_WITH_CHIP_DELIVERY: "Paso13_CI_ConChip_EnvioProducto",
    IDENTIFICATION_WITHOUT_CHIP_DELIVERY: "Paso13_CI_SinChip_EnvioADomicilio",
    CERTIFICATE_OF_DGI: "DGI_ImagenEnviada",
    CERTIFICATE_OF_BPS: "BPS_ImagenEnviada",
    CERTIFICATE_OF_RUT: "RUT_ImagenEnviada",
    EMPLOYER_DATA_SENT: "Datos_Empleador_Enviados",
  },
  ERRORS: {
    REQUEST_CREATE_ONBOARDING_LANDING:
      "Paso1_Landing_ErrorRequestCrearOnboarding",
    REQUEST_TOKEN: "Paso1_Landing_ErrorRequestToken",
    REQUEST_CREATE_ONBOARDING: "Paso2_ErrorRequestCrearOnboarding",
    REQUEST_PROSPECTS: "Paso2_ErrorRequestCrearProspects",
    REQUEST_IDENTIFICATION_VERIFICATION: "Paso6.1.2_ErrordeLectura",
    REQUEST_UPDATE_PROSPECT_WITHOUT_CHIP:
      "Paso7.1_ErrorRequestActualizarProspectoSinChip",
    REQUEST_UPDATE_PROSPECT_WITH_CHIP:
      "Paso7.1_ErrorRequestActualizarProspectoConChip",
    REQUEST_ADDRESS_VERIFICATION: "Paso8.1_ErrorConfirmaciónResidencia",
    WRONG_PERSONAL_INFO_COMBINATION: "Paso8.3.1_ErrorCombinaciónInvalida",
    ACCOUNT_FLOW_NOT_CONFIRMED: "Paso8.2_NoConfirmaFlujoDeCuenta",
    QUERY_RETURN_EVENT: "EventodeRegresodeConsulta",
    ACTIVITY_PYME_FORBIDDEN_RESTRICTED:
      "Paso7.1.2_ActividadPYME_ProhibidaRestringida",
    INVALID_SALARY_ACCOUNT: "Select.Salary.Account_Invalid_AccountType"
  },
};

const { ERRORS, FLOW } = GA_EVENTS;

export type GoogleAnalyticsCategories =
  // On Landing load
  | "Paso1_AbríTucuenta"
  | "Landing"
  | "Landing_TDC"
  | "Landing_CA"
  | "Landing_CS"
  | "Landing_CS_PLUS"
  | "Landing_CS_BASIC"
  // On product selected
  | "Paso2_ElecciónDeProducto_TDC"
  | "Paso2_ElecciónDeProducto_CA"
  | "Paso2_ElecciónDeProducto_CS_PLUS"
  | "Paso2_ElecciónDeProducto_CS_BASIC"
  | "Paso3_ElecciónDeTipoDeCedula"
  | "Paso3_ElecciónDeTipoDeCedula"
  | "Paso4.1_DirigirseASucursal"
  | "Paso4.1_DirigirseASucursal"
  | "Paso5.1_CedulaConChip"
  | "Paso5.1_CedulaConChip"
  | "Paso5.2_CedulaSinChip"
  | "Paso5.2_CedulaSinChip"
  | "Paso6.1.1_VerificaciónIdentidad"
  | "Paso6.1.1_VerificaciónIdentidad"
  | "Paso6.1.2_ErrordeLectura"
  | "Paso6.1.2_ErrordeLectura"
  | "Paso7.1_ConfirmaciónDatosPrincipalesCIChip"
  | "Paso7.1_ConfirmaciónDatosPrincipalesCIChip"
  | "Paso7.1.2_ConfirmaciónDatosPrincipales"
  | "Paso7.1.2_ConfirmaciónDatosPrincipales_CS_URUGUAY"
  | "Paso7.1.2_ConfirmaciónDatosPrincipales_CS_EXTRANJERO"
  | "Paso7.2_ConfirmaciónDatosPrincipalesCISinChip"
  | "Paso7.2_ConfirmaciónDatosPrincipalesCISinChip"
  | "Paso7.2.1_ConfirmaciónDatosPrincipales"
  | "Paso7.2.1_ConfirmaciónDatosPrincipales"
  | "Paso8.1_ErrorConfirmaciónResidencia"
  | "Paso8.1_ErrorConfirmaciónResidencia"
  | "Paso8.2_NoConfirmaFlujoDeCuenta"
  | "Paso8.2_NoConfirmaFlujoDeCuenta"
  | "Paso8.3_ConfirmacionDatosPersonales"
  | "Paso8.3.1_ErrorCombinaciónInvalida"
  | "Paso8_CuentaJoven"
  | "Paso10_CuentaPlus"
  | "Paso11_ProgramadeAfinidad"
  | "Paso12_SelecciónDeSegmento"
  | "Paso12_SelecciónDeSello"
  | "Paso12.1_SelecciónVISAPlatinum"
  | "Paso13_CI_ConChip_EnvioProducto"
  | "Paso13_CI_SinChip_EnvioADomicilio"
  | "Paso15_SelfiePruebadeVida"
  | "Paso15_SelfiePruebadeVida"
  | "Paso16_VerificaciónDeDirección"
  | "Paso16_VerificaciónDeDirección"
  | "Paso17_FinalOnboarding"
  | "EventodeRegresodeConsulta"
  | typeof FLOW.KYC_PYME
  | typeof FLOW.ACTIVITY_PYME
  | typeof ERRORS.REQUEST_TOKEN
  | typeof ERRORS.REQUEST_CREATE_ONBOARDING_LANDING
  | typeof ERRORS.REQUEST_CREATE_ONBOARDING
  | typeof ERRORS.REQUEST_IDENTIFICATION_VERIFICATION
  | typeof ERRORS.REQUEST_ADDRESS_VERIFICATION
  | typeof ERRORS.ACCOUNT_FLOW_NOT_CONFIRMED
  | typeof ERRORS.WRONG_PERSONAL_INFO_COMBINATION
  | typeof ERRORS.ACTIVITY_PYME_FORBIDDEN_RESTRICTED
  | typeof ERRORS.QUERY_RETURN_EVENT;

export type GoogleAnalyticsLabels = "Error_IrASucursal" | string;
