import React, { ChangeEvent, useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import VaccinesOutlinedIcon from "@mui/icons-material/VaccinesOutlined";
import isBase64 from "is-base64";

type Props = {
  onFinish: (image: string) => void;
};

const InjectBase64Tool: React.FC<Props> = ({ onFinish }) => {
  const [base64, setBase64] = useState<string>();

  const injectIDS = () => {
    onFinish(base64);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBase64(e.target.value);
  };

  const isValidBase64 = isBase64(base64, { mimeRequired: true });

  const MimeTypeIsRequired = () => (
    <span>
      {!!base64 && !isValidBase64 ? (
        <span>
          Please add MIMETYPE: {"  "}
          <code>data:image/png;base64,</code> {"  "}
          And/Or a valid Base64
        </span>
      ) : (
        ""
      )}
    </span>
  );

  return (
    <Box sx={styles.root}>
      <TextField
        label="Copy/Paste Base64"
        multiline
        maxRows={4}
        onChange={onChange}
        fullWidth
        error={!!base64 && !isValidBase64}
        helperText={<MimeTypeIsRequired />}
      />
      <Button
        onClick={injectIDS}
        variant="outlined"
        endIcon={<VaccinesOutlinedIcon />}
        fullWidth
        disabled={!base64 || !isBase64(base64, { mimeRequired: true })}
      >
        Upload
      </Button>
      <span></span>
    </Box>
  );
};

export default InjectBase64Tool;

const styles = {
  root: {
    m: 1,
  },
};
