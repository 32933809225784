import { useEffect, useState } from "react";
import { RootState } from "application/store/reducers/rootReducer";
import { GO_TO } from "infrastructure/api";
import {
  BASE_URL_FOR_CHECKPOINT_ONLY,
  getOnboardingId,
  getStatusCheckpoint,
  hasOnboardingStarted,
} from "infrastructure/services";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RestClient } from "infrastructure/services/rest-client";
import ReconnectingEventSource from "reconnecting-eventsource";
import { Checkpoints, ERRORS_TO_REDIRECT } from "./CheckpointEvents";
import { handleCheckpoint } from "./CheckpointHandlers";
import { CheckpointService } from "infrastructure/services/checkpoint/checkpoint.service";

export interface CheckpointResponse {
  onboardingId: string;
  checkpoint: Checkpoints;
}

const KEEP_ALIVE_EVENT = "KEEP_ALIVE_EVENT";
const CHECKPOINT_CHANGED_EVENT = "CHECKPOINT_CHANGED_EVENT";
const KEEP_ALIVE_RESPONSE_TIME = 30000;

function CheckpointStatusR() {
  const now = Date.now();
  const [errorCount, setErrorCount] = useState(0);
  const [timer, resetTimer] = useState(now);
  const [connection, resetConnection] = useState(0);
  const navigate = useNavigate();
  const { paso7 } = useSelector((state: RootState) => ({
    paso7: state.SeptimoPasoState,
  }));

  useEffect(() => {
    const timerInterval = setInterval(() => {
      const timeHasPassedInMS = Date.now() - timer;
      const keepAliveDidNotResponded =
        timeHasPassedInMS > KEEP_ALIVE_RESPONSE_TIME;

      if (keepAliveDidNotResponded) {
        clearInterval(timerInterval);
        resetConnection((connection) => connection + 1);
        if (process.env.NODE_ENV !== "production") {
          console.info("CHECKPOINT WAS RESET MANUALLY");
        }
      }
    }, 5000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [timer]);

  useEffect(() => {
    const sse = new ReconnectingEventSource(
      `${BASE_URL_FOR_CHECKPOINT_ONLY}/onboardings/checkpoints`,
      {
        withCredentials: false,
      }
    );

    const getRealtimeData = async (checkpointResponse: CheckpointResponse) => {
      // Store last checkpoint epoch
      const lastEventDate = Date.now();
      window.localStorage.setItem("lastEventDate", lastEventDate.toString());
      // ˆ^
      if (checkpointResponse.onboardingId === getOnboardingId()) {
        CheckpointService.storeLastCheckpoint(checkpointResponse.checkpoint);
        if (ERRORS_TO_REDIRECT.includes(checkpointResponse.checkpoint)) {
          RestClient.redirect();
          return;
        }

        if (
          checkpointResponse.checkpoint === Checkpoints.ONBOARDING_DUPLICATED
        ) {
          const { NODE_ENV } = process.env;
          if (NODE_ENV !== "production") {
            console.error(checkpointResponse.checkpoint);
          }
          navigate({ pathname: GO_TO.SUCURSALES, search: `?finished=${true}` }, { replace: true });
        }

        await handleCheckpoint({
          navigate,
          checkpoint: checkpointResponse.checkpoint,
        });
      }
    };

    // Handler for events of type KEEP_ALIVE_EVENT only
    const keppAliveEventHandler = () => resetTimer(Date.now());
    sse.addEventListener<any>(KEEP_ALIVE_EVENT, keppAliveEventHandler);

    // Handler for events of type CHECKPOINT_CHANGED_EVENT only
    const checkpointChangedEventHandler = (e: MessageEvent<any>) =>
      getRealtimeData(JSON.parse(e.data));
    sse.addEventListener<any>(
      CHECKPOINT_CHANGED_EVENT,
      checkpointChangedEventHandler
    );

    sse.onopen = (e) => {
      if (hasOnboardingStarted()) {
        (async () => {
          const success = await getStatusCheckpoint();
          if (success) {
            getRealtimeData(JSON.parse(success.data));
          }
        })();
      }
    };

    sse.onerror = (e) => {
      if (errorCount > 5) {
        console.error("Error checkpoint: ", e);
      } else {
        setErrorCount(errorCount + 1);
      }
    };

    return () => {
      sse.close();
      sse.removeEventListener<any>(KEEP_ALIVE_EVENT, keppAliveEventHandler);
      sse.removeEventListener<any>(
        CHECKPOINT_CHANGED_EVENT,
        checkpointChangedEventHandler
      );
    };
  }, [paso7, connection]);

  return null;
}

export default CheckpointStatusR;