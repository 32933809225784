import { Grid } from '@mui/material'
import Landing from 'ui/components/molecules/Landing/Landing'
import QRLanding from 'ui/components/molecules/Landing/QRLanding'

function LandingRouter() {
  return (
    <>
         <Grid sx={{ display: { md: "none" } }}>
            <Landing />
          </Grid>
          <Grid sx={{ display: { md: "block", xs: "none" } }}>
            <QRLanding />
          </Grid>
    </>
  )
}

export default LandingRouter