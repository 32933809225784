import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { CloseModal, Modals } from "application/store/reducers/ModalReducer";
import { RootState } from "application/store/reducers/rootReducer";

import InfoModal from "ui/components/atoms/infoModal";
import TotemsList from "../Totem/TotemsList";
import HowToDownloadPymeDocumentation from "ui/components/molecules/Landing/sections/pyme/HowToDownloadPymeDocumentation";
import ProgramaFidelidadInfo from "./programaFidelidad";
import TerminosYCondiciones from "./terminosYCondiciones";
import TuProducto from "./tuProductoModal";
import BeneficSalary from "./beneficSalary";
import InjectBase64Tool from "./injectBase64Tool";

type Props = {};

const GlobalModalContainer: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { datos, modal } = useSelector((state: RootState) => ({
    datos: state.ModalState.datos,
    modal: state.ModalState.modal,
  }));
  switch (modal) {
    case Modals.TU_PRODUCTO:
      return (
        <InfoModal
          open
          onClose={() => dispatch(CloseModal())}
          title="Tu producto"
          buttonText="Entendido"
        >
          <Box sx={styles.root}>
            <TuProducto
              isPymes={datos.isPymes}
              isCuentaJoven={datos.isCuentaJoven}
              isSalary={datos.isSalary}
              isPlus={datos.isPlus}
              isAhorro={datos.isAhorro}
              productTitle={datos.productTitle}
              // TODO: REFACTOR ProductInfoObject={AccountsInfo.getBy(AccountType)}
            />
          </Box>
        </InfoModal>
      );
    case Modals.TERMINOS_Y_CONDICIONES:
      return (
        <InfoModal
          open
          onClose={() => dispatch(CloseModal())}
          title="Términos y condiciones"
          buttonText="Entendido"
        >
          <TerminosYCondiciones />
        </InfoModal>
      );
    case Modals.INFORMACION_DE_PROGRAMA_AFINIDAD:
      return (
        <InfoModal
          open
          onClose={() => dispatch(CloseModal())}
          title={datos?.data?.name}
          buttonText="Entendido"
        >
          <ProgramaFidelidadInfo content={datos?.data?.description} />
        </InfoModal>
      );
    case Modals.TOTEMS_LIST:
      return (
        <InfoModal
          open
          onClose={() => dispatch(CloseModal())}
          title="Listado de Totems"
          buttonText="Entendido"
        >
          <TotemsList />
        </InfoModal>
      );
    case Modals.PYMES_COMO_LO_DESCARGO:
      return (
        <InfoModal
          open
          onClose={() => dispatch(CloseModal())}
          title="¿Cómo descargo los certificados?"
          buttonText={null}
        >
          <HowToDownloadPymeDocumentation />
        </InfoModal>
      );
    case Modals.BENEFITS:
      return (
        <InfoModal
          open
          onClose={() => dispatch(CloseModal())}
          title="Beneficios"
          buttonText="Cerrar"
          isBenefits={true}
        >
          <BeneficSalary />
        </InfoModal>
      );
    case Modals.INJECT_DOCUMENTOS_IDENTIDAD:
      return (
        <InfoModal
          open
          onClose={() => dispatch(CloseModal())}
          title="Inject Base64 Image Tool"
          buttonText="Cerrar"
        >
          <InjectBase64Tool onFinish={datos.onFinish} />
        </InfoModal>
      );
    default:
      return null;
  }
};

export default GlobalModalContainer;

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "1rem",
    padding: "0 1rem",
    background: "#FAFAFA",
  },
};
