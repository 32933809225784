import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import H from "history";
import {
  AuthProxyResponse,
  connectToAuthProxy,
  finishOnboarding,

  getSessionId,
  removeOnboardingId,
  setSessionId,
  setToken,
} from "infrastructure/services";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import { CLEAN_STORE } from "application/store/reducers/rootReducer";
import {
  ProductTypeQueryParam,
  productDefault,
  affinityParamName,
  productTypeParamName,
  accountParamName,
} from "application/store/models/seleccionarProductoPaso";
import { TrackerService } from "infrastructure/services/tracker/tracker.service";
import { GA_EVENTS } from "infrastructure/services/tracker/ga.types";
import MobileLanding from "./MobileLanding";
import { persistor } from "application/store/Store";
import { MixpanelService } from "infrastructure/services/tracker/mixpanel.service";
import { isMobileDevice } from "application/utils/helpers";
import { SalaryAccountValue } from "application/store/models/SalaryAccount.model";
import { setAllToken } from "application/store/reducers/TokenStateReducer";
import {
  setInitialUrl,
  setUrlParams,
  setNoCredit,
} from "application/store/reducers/OnboardingReducer";
import { APP_VERSION } from "application/utils/constants";
import { Box } from "@mui/material";

const Landing = () => {
  const dispatch = useDispatch();
  const { search }: H.Location<H.LocationState> = useLocation();
  const searchParams = new URLSearchParams(search);
  const affinity = searchParams.get(affinityParamName);
  const accountType = searchParams.get(accountParamName);
  const productTypeKey = searchParams.get(productTypeParamName);
  const businessPartner = searchParams.get("businessPartner");
  const isCredito = productTypeKey === ProductTypeQueryParam.CreditParam;
  const isAhorro = productTypeKey === ProductTypeQueryParam.AccountParam;
  const isPymes = productTypeKey === ProductTypeQueryParam.PymesParam;
  const isSalaryAccount =
    productTypeKey === ProductTypeQueryParam.SalaryAccount;
  const productType =
    isAhorro || isCredito || isPymes || isSalaryAccount
      ? productTypeKey
      : productDefault;
  const urlSessionID = searchParams.get("sessionId");
  const fromQR = searchParams.get("fromQR");
  const isDesktop = !isMobileDevice();
  const noCredit: boolean = Boolean(searchParams.has("noCredit"));

  useEffect(() => {
    removeOnboardingId();
    window.localStorage.removeItem('finished');
  }, []);

  const resetRedux = () => {
    persistor.purge();
  };

  const buildUrlParams = () => {
    if (productType === ProductTypeQueryParam.default) {
      return "";
    }
    if (productType !== null && accountType !== null) {
      if (
        accountType === SalaryAccountValue.BASIC ||
        accountType === SalaryAccountValue.PLUS
      )
        return `?productType=${productType}&accountType=${accountType}`;
    }
    if (productType !== null && affinity !== null) {
      if (parseInt(affinity) >= 1 && parseInt(affinity) <= 6)
        return `?productType=${productType}&affinity=${affinity}`;
    }
    if (productType !== null) {
      return `?productType=${productType}`;
    }
    return "";
  };

  const init = () => {
    resetRedux();
    // Clean state
    dispatch({ type: CLEAN_STORE });
    finishOnboarding();
    // save url params
    dispatch(
      setInitialUrl({
        initialUrl: `${window.location.href.split("?")[0]}${buildUrlParams()}`,
      })
    );
    dispatch(setUrlParams({ urlParams: buildUrlParams() }));
    // Store initial URL and create session ID
    window.initialUrl = window.location.href;
    setSessionId(urlSessionID);
    TrackerService.setUserId(getSessionId());

    // Initialize Authentication Proxy
    initAuthProxy();

    // Initialize Analytics
    initAnalytics();

    dispatch(setNoCredit({ noCredit }));
  };

  const initAnalytics = () => {
    setTimeout(() => {
      if (isAhorro || isCredito || isPymes || isSalaryAccount) {
        TrackerService.setProduct(
          isAhorro ? "ca" : isPymes ? "py" : isSalaryAccount ? "cs" : "tdc",
          accountType as "PLUS" | "STANDARD" | undefined
        );


        TrackerService.trackGA(
          isAhorro
            ? GA_EVENTS.FLOW.LANDING_CA
            : isPymes
              ? GA_EVENTS.FLOW.LANDING_PY
              : isCredito
                ? GA_EVENTS.FLOW.LANDING_TDC
                : !isSalaryAccount && !accountType
                  ? GA_EVENTS.FLOW.LANDING_CS
                  : isSalaryAccount && accountType === SalaryAccountValue.BASIC
                    ? GA_EVENTS.FLOW.LANDING_CS_BASIC
                    : isSalaryAccount
                      ? GA_EVENTS.FLOW.LANDING_CS_PLUS
                      : GA_EVENTS.FLOW.LANDING,
          (affinity ? affinity : undefined) as any,
          isDesktop ? "Desktop" : fromQR ? "QR" : "Mobile"
        );
      } else {
        TrackerService.trackGA(GA_EVENTS.FLOW.LANDING);
      }
      MixpanelService.identify(getSessionId(), {
        "App Version": APP_VERSION,
      });
      MixpanelService.track(
        "User Landed",
        {
          "Product Type": productType ? productType : "Generic",
          "Business Partner": businessPartner ? businessPartner : "Generic",
          "Session ID": getSessionId(),
        },
        true
      );
      TrackerService.trackLandingDevice({
        device: isDesktop ? "desktop" : "mobile",
        fromQR: fromQR ? true : false,
        affinity: (affinity ? affinity : undefined) as any,
      });
    }, 1500);
  };

  const initAuthProxy = async () => {
    const response: AuthProxyResponse = await connectToAuthProxy();
    if (response.hasToken) {
      setToken(response.accessToken);
      dispatch(setAllToken({ hasToken: true, enableCheckpoint: response }));
    }
  };

  useEffect(() => {
    init();
  }, []);
  return (
    <Box>
      <ScrollToTopOnMount />
      <MobileLanding
        productType={productType as any}
        affinity={affinity}
        accountType={
          accountType === SalaryAccountValue.BASIC ||
            accountType === SalaryAccountValue.PLUS
            ? accountType
            : ""
        }
      />
    </Box>
  );
};

export default Landing;