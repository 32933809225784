import { Box } from "@mui/material";
import { FC } from "react";
import { ProductConfig } from "../landing-products-config";
import { LandingProps } from "../MobileLanding";

const LandingVideo: FC<LandingProps> = ({ productType }) => {
  return (
    <Box sx={styles.root}>
      <Box className="row">
        <Box className="col-s12">
          <Box className="embed-responsive">
            <iframe
              title="embed-video-title"
              key="embed-video-item"
              className="embed-responsive-item"
              src={ProductConfig[productType].video}
            ></iframe>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingVideo;

const styles = {
  root: {
    margin: "0 auto",
    maxWidth: "1280px",
    width: "90%",
    ".row": {
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "20px",
    },
    "& .row:after": {
      content: `""`,
      display: "table",
      clear: "both",
    },
    ".col-s12": {
      width: "100%",
      marginLeft: "auto",
      float: "left",
      boxSizing: "border-box",
      padding: "0 0.75rem",
      minHeight: "1px",
      left: "auto",
      right: "auto",
    },
    ".embed-responsive": {
      position: "relative",
      display: "block",
      width: "100%",
      padding: "0",
      overflow: "hidden",
      height: "185.625px",

      "& ::before": {
        display: "block",
        content: `""`,
        paddingTop: "56.25%"
      }
    },
    ".embed-responsive-item": {
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      width: "100%",
      height: "100%",
      border: "0",
      borderRadius: "10px"
    }
  }
};
